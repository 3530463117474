import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Container,
  Title,
  PeriodContainer,
  Calendar,
  Selection,
  Button,
} from "../styles/components/SideFiltroStyled";
import { obtenerOficinas } from "../services/obtener-oficinas.service";
import { obtenerDespachos } from "../services/obtener-despachos.service";
import { obtenerVendedores } from "../services/obtener-vendedores.service";
import { useContext } from "react";
import IndicadoresContext from "../../indicadores/context/indicadores.context";
import AdminVentasFiltrosContext from "../context/adminVentas.filtros.context";

export const SideFiltro = ({ filtrar, filtroAplicado, resetFiltros }) => {
  const { dispatch } = useContext(IndicadoresContext);
  const { dispatch: setFiltrosContext } = useContext(AdminVentasFiltrosContext);

  const [periodoStart, setPeriodoStart] = useState("");
  const [periodoEnd, setPeriodoEnd] = useState("");
  const [oficina, setOficina] = useState("default");
  const [despacho, setDespacho] = useState("default");
  const [vendedor, setVendedor] = useState("default");

  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledDespacho, setDisabledDespacho] = useState(true);
  const [disabledVendedor, setDisabledVendedor] = useState(true);

  const [oficinas, setOficinas] = useState([]);
  const [despachos, setDespachos] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  const handlePeriodChange = (val, type) => {
    const dateFormat = "YYYY-MM-DD";
    if (type === "start") {
      setPeriodoStart(val, dateFormat);
    } else if (type === "end") {
      if (moment(val, dateFormat).isAfter(moment(periodoStart, dateFormat))) {
        setPeriodoEnd(val, dateFormat);
        return;
      }
      setPeriodoEnd(
        moment(periodoStart, dateFormat).add(1, "day").format(dateFormat)
      );
    }
  };

  const getOficinas = async () => {
    const oficinas = await obtenerOficinas(0);
    setOficinas(oficinas);
  };

  const getDespachos = async (id) => {
    const despachos = await obtenerDespachos(id);
    setDespachos(despachos);
  };

  const getVendedores = async (id) => {
    const vendedores = await obtenerVendedores(id);
    setVendedores(vendedores);
  };

  useEffect(() => {
    getOficinas();
  }, []);

  useEffect(() => {
    if (oficina !== "default") {
      getDespachos(Number(oficina));
      setDisabledDespacho(false);
    } else {
      setDisabledDespacho(true);
      setDespacho("default");
    }
  }, [oficina]);

  useEffect(() => {
    if (despacho !== "default") {
      getVendedores(Number(despacho));
      setDisabledVendedor(false);
    } else {
      setDisabledVendedor(true);
      setVendedor("default");
    }
  }, [despacho]);

  useEffect(() => {
    if (periodoStart && periodoEnd && oficina !== "default") {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [oficina, periodoStart, periodoEnd]);

  const handleFiltrar = () => {
    dispatch({type: 'SIDE-ADMIN'})
    filtrar({
      fechaDesde: periodoStart === 'default' ? undefined : periodoStart,
      fechaHasta: periodoEnd === 'default' ? undefined : periodoEnd,
      idOficina: oficina === 'default' ? undefined : oficina,
      idDespacho: despacho === 'default' ? undefined : despacho,
      idVendedor: vendedor === 'default' ? undefined : vendedor
    })
  }

  const handleResetAll = () => {
    setPeriodoStart("");
    setPeriodoEnd("");
    setOficina("default");
    dispatch({ type: "SIDE-ADMIN-FALSE" });
    resetFiltros();
  };

  useEffect(() => {
    const oficinaSeleccionada = oficinas.find(
      (ofi) => ofi.IDOfna === Number(oficina)
    );
    if (oficinaSeleccionada) {
      setFiltrosContext({
        type: "SET_OFICINA",
        payload: oficinaSeleccionada.NombreCompleto,
      });
      setFiltrosContext({
        type: "SET_OFICINA_ID",
        payload: oficinaSeleccionada.IDOfna,
      });
    } else {
      setFiltrosContext({ type: "SET_OFICINA", payload: "" });
      setFiltrosContext({ type: "SET_OFICINA_ID", payload: undefined });
    }
  }, [oficina]);

  useEffect(() => {
    const despachoSeleccionado = despachos.find(
      (des) => des.id === Number(despacho)
    );
    if (despachoSeleccionado) {
      setFiltrosContext({
        type: "SET_DESPACHO",
        payload: despachoSeleccionado.nombre,
      });
      setFiltrosContext({
        type: "SET_DESPACHO_ID",
        payload: despachoSeleccionado.id,
      });
    } else {
      setFiltrosContext({ type: "SET_DESPACHO", payload: "" });
      setFiltrosContext({ type: "SET_DESPACHO_ID", payload: undefined });
    }
  }, [despacho]);

  useEffect(() => {
    const vendedorSeleccionado = vendedores.find(
      (ven) => ven.id === Number(vendedor)
    );
    if (vendedorSeleccionado) {
      setFiltrosContext({
        type: "SET_VENDEDOR",
        payload: vendedorSeleccionado.NombreCompleto,
      });
      setFiltrosContext({
        type: "SET_VENDEDOR_ID",
        payload: vendedorSeleccionado.id,
      });
    } else {
      setFiltrosContext({ type: "SET_VENDEDOR", payload: "" });
      setFiltrosContext({ type: "SET_VENDEDOR_ID", payload: undefined });
    }
  }, [vendedor]);

  useEffect(() => {
    setFiltrosContext({ type: "SET_PERIOD_START", payload: periodoStart });
  }, [periodoStart]);

  useEffect(() => {
    setFiltrosContext({ type: "SET_PERIOD_END", payload: periodoEnd });
  }, [periodoEnd]);

  return (
    <div>
      <Container>
        <div className="indicadores-filtro__encabezado">
          <Title>Periodo</Title>{" "}
          {filtroAplicado && (
            <span onClick={() => handleResetAll()} className="reset-all">
              RESET ALL
            </span>
          )}
        </div>
        <PeriodContainer>
          <Calendar
            type="date"
            value={periodoStart}
            onChange={(e) => handlePeriodChange(e.target.value, "start")}
            name="trip-start"
          />
          <Calendar
            type="date"
            value={periodoEnd}
            onChange={(e) => handlePeriodChange(e.target.value, "end")}
            name="trip-end"
          />
        </PeriodContainer>

        <Container>
          <Title>Oficina</Title>
          <Selection
            value={oficina}
            name="oficina"
            onChange={(e) => {
              setOficina(e.target.value)
            }
          }
              
          >
            <option value="default">Seleccionar oficina</option>
            {oficinas.map((ofi) => (
              <option value={ofi.IDOfna}>{ofi.NombreCompleto}</option>
            ))}
          </Selection>
        </Container>

        <Container>
          <Title>Despacho</Title>
          <Selection
            disabled={disabledDespacho}
            value={despacho}
            name="despacho"
            onChange={(e) => setDespacho(e.target.value)}
          >
            <option value="default">Seleccionar Despacho</option>
            {despachos.map((des) => (
              <option value={des.id}>{des.nombre}</option>
            ))}
          </Selection>
        </Container>

        <Container>
          <Title>Vendedor</Title>
          <Selection
            disabled={disabledVendedor}
            value={vendedor}
            name="vendedor"
            onChange={(e) => setVendedor(e.target.value)}
          >
            <option value="default">Seleccionar vendedor</option>
            {vendedores.map((ven) => (
              <option value={ven.IDVend}>{ven.NombreCompleto}</option>
            ))}
          </Selection>
        </Container>

        <Button
          className={`${
            disabledButton ? "indicadores-filtro__btn--disabled" : ""
          }`}
          disabled={disabledButton}
          onClick={() => handleFiltrar()}
        >
          Consultar
        </Button>
      </Container>
    </div>
  );
};
