export const obtenerVendedores = async (id) => {
 //console.log(id);
  const apiUrl = "https://backendsicas.gpmass.mx/api/getVendedores";
  const body = JSON.stringify({
    idDespacho: id,
  });
  let detalles = [];
  await fetch(apiUrl, {
    method: "post",
    body: body,
    headers: {
      "Content-type": "Application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      detalles = data.data;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  return detalles;
};