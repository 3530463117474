export const login = async (datos) => {
    let dash = [];
    const apiUrl = "https://backendsicas.gpmass.mx/api/login";
    await fetch(apiUrl, {
      method: "post",
      body: JSON.stringify(datos),
      headers: {
        "Content-type": "Application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dash = data;
      })
      .catch((err) => console.error("ERROR: ", err.message));
    return dash;
  };
  
