import React from 'react'
import { Link } from 'react-router-dom'
import {NavBarContainer, Pestana1, Pestana2, Texto1, Text2} from '../styles/NavBar.styles'
const NavBar = () => {
  return (
    <NavBarContainer>
        <Pestana1>
          <Texto1>
            <Link style={{textDecoration: 'none', color:'#838383'}} to='/leads'>Leads</Link>
          </Texto1>
        </Pestana1>
        <Pestana2>
          <Text2>
            <Link style={{textDecoration: 'none', color:'#121212'}} to='/indicadores'>Indicadores</Link>
          </Text2>
        </Pestana2>
        <Pestana1>
          <Texto1>
            <Link style={{textDecoration: 'none', color:'#838383'}} to='/admin-ventas'>Administración de ventas</Link>
          </Texto1>
        </Pestana1>
    </NavBarContainer>
  )
}

export default NavBar