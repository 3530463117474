import React, { useContext, useState, useEffect } from 'react'
//assets
import flecha from '../../../assets/flechaAbajo.svg'
import autorizadoIcon from '../../../assets/ModalOrdenar/autorizado.svg'
import pendienteIcon from '../../../assets/ModalOrdenar/pendiente.svg'

//styles
import {MainContainer, Img, Texto} from '../../../styles/components/OrdenarporStyled'
import ModalOrdenar from './Estatus'

import AdminVentasFiltrosContext from '../../../context/adminVentas.filtros.context'


const Ordenarpor = () => {
  const [showModalOrdenar, setShowModalOrdenar] = useState(false)

  const { state:filtrosContext } = useContext(AdminVentasFiltrosContext)

  const { autorizado, pendiente } = filtrosContext

  const handleShowModalOrdenar= () => {
    setShowModalOrdenar(!showModalOrdenar)
  }

  const handleHideModalOrdenar= () => {
    setShowModalOrdenar(false)

  } 

  useEffect(()=>{
    
    document.addEventListener('click', (e) => {

      let el = e.target

      for(let i = 0; i<100;i++){
        if(el.parentElement.classList.contains('modal-estatus')){
          i = 100+1
        }

        console.log(i)
        if(el.id === 'root'){
          i = 100+1
          handleHideModalOrdenar()
        }

        el = el.parentElement
        
      }

    })
    
  },[])

  return (
    <div className='modal-estatus'>
      <MainContainer style={{width: '100%', cursor:'pointer'}} onClick={handleShowModalOrdenar}>
          <Texto>
            {
            autorizado &&
              <>
                <span style={{marginRight:'15px', fontFamily: `'Montserrat', sans-serif`, fontWeight:'600', color:'#4E4E4E'}}>Autorizado</span>
                <img src={autorizadoIcon}/>
              </>
            }
            {
            pendiente &&
              <>
                <span style={{marginRight:'15px', fontFamily: `'Montserrat', sans-serif`, fontWeight:'600', color:'#4E4E4E'}}>Pendiente</span>
                <img src={pendienteIcon}/>
              </>
            }
          </Texto>
          <Img src={flecha}/>
      </MainContainer>
      <div style={{position: 'relative', height:'10px'}}>
          {showModalOrdenar && <ModalOrdenar  hideModal={handleHideModalOrdenar}/>} 
      </div>
    </div>
  )
}

export default Ordenarpor