import React from 'react'
//styles 
import {MainContainer, Container,Container2,Container3, Title} from '../../indicadores/styles/componets/BarraCotizacionesStyled'  
const BarraCotizaciones = ({data}) => {
  return (
    <MainContainer style={{gap:'.5rem'}}>
        <Container>
            <Title>{data.cotizaciones || 0}</Title>
            <Title>Cotizaciones</Title>
        </Container>
        <Container2>
            <Title>{data.polizasEmitidas}</Title>
            <Title>Pólizas emitidas</Title>
        </Container2>
        <Container2>
            <Title>{data.polizasPagadas}</Title>
            <Title>Pólizas cobradas</Title>
        </Container2>
        <Container2>
            <Title>{data.primaEmitida.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</Title>
            <Title>Prima Emitida</Title>
        </Container2>
        <Container3>
            <Title>{data.primaPagada.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</Title>
            <Title>Prima Cobrada</Title>
        </Container3>
    </MainContainer>
  )
}

export default BarraCotizaciones