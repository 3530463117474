export const obtenerDespachos = async (id=0) => {
  let agencias = [];
  const apiUrl = `https://backendsicas.gpmass.mx/api/get-despachos/${id}`;
  await fetch(apiUrl, {
    method: "get",
  })
    .then((response) => response.json())
    .then((data) => {
      agencias = data.data;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  return agencias;
};