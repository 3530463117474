const despachosToXML = (body) => {
    let headerXML = `<?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">
    <x:ExcelWorkbook>
     <x:ActiveSheet>0</x:ActiveSheet>
    </x:ExcelWorkbook>
    <Styles>
     <Style ss:ID="Default" ss:Name="Normal">
      <ss:Alignment ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Arial" ss:Size="8" />
     </Style>
     <Style ss:ID="S21">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:Interior ss:Color="#A5A5A5" ss:Pattern="Solid" />
     </Style>
     <Style ss:ID="S22">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:Interior ss:Color="#A5A5A5" ss:Pattern="Solid" />
      <ss:NumberFormat ss:Format="0.00" />
     </Style>
     <Style ss:ID="S23">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:Interior ss:Color="#A5A5A5" ss:Pattern="Solid" />
     </Style>
     <Style ss:ID="S24">
      <ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />
      <ss:Font ss:Bold="1" ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:Interior ss:Color="#A5A5A5" ss:Pattern="Solid" />
     </Style>
     <Style ss:ID="S25">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
     </Style>
     <Style ss:ID="S26">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="@" />
     </Style>
     <Style ss:ID="S27">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="D/M/YYYY" />
     </Style>
     <Style ss:ID="S28">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="D/M/YYYY" />
     </Style>
     <Style ss:ID="S29">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="0.00" />
     </Style>
     <Style ss:ID="S30">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="#,##0.00" />
     </Style>
     <Style ss:ID="S31">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="#,##0" />
     </Style>
     <Style ss:ID="S32">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="dd/mm/yyyy" />
     </Style>
     <Style ss:ID="S33">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#FFFFFF" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="dd/mm/yyyy" />
     </Style>
     <Style ss:ID="S34">
      <ss:Alignment ss:Horizontal="Left" ss:Vertical="Bottom" />
      <ss:Font ss:Color="#000000" ss:FontName="Century Gothic" ss:Size="10" />
      <ss:NumberFormat ss:Format="&quot;$&quot;#,##0.00" />
     </Style>
    </Styles>`;
    
    
    let tableheaderXML = `<Worksheet ss:Name="Pólizas">
    <ss:Names />
    <ss:Table ss:DefaultRowHeight="15" ss:DefaultColumnWidth="75.75" ss:ExpandedRowCount="1000" ss:ExpandedColumnCount="56">
     <Row ss:Index="1" ss:Height="13.5">
      <Cell ss:StyleID="S21"><Data ss:Type="String">Oficina</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Despacho</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Grupo</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Vendedor</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Tipo de Documento</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Documento</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Inciso</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Agente</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Forma de Pago</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Moneda</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Sub Ramo</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Ejecutivo</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Renovacion</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Fecha de Antigüedad</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Desde</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Hasta</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Estatus</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Motivo de Estatus</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Fecha de Estatus</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Prima Neta</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Descuento</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">%Descuento</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Extra Prima</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">%Extra Prima</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Recargos</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">%Recargos</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Derechos</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Sub Total</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">IVA</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">% de IVA</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Prima Total</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Comision Neta</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">%Comision Neta</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Comision Promotor</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">%Comision Promotor</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Concepto</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Plan</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Marca</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Tipo</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Transmision</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Puertas</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Modelo</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Placas</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Serie</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Motor</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Estado Circula</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Color</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Servicio</Data></Cell>
      <Cell ss:StyleID="S21"><Data ss:Type="String">Uso del Vehiculo</Data></Cell>
     </Row>`;
    
     let tableRows = '';
    
     body.forEach((e, i) => {
        tableRows += `\n<Row ss:Index="${i+2}" ss:Height="13.5">`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Oficina !== null ? e.Oficina : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Despacho !== null ? e.Despacho : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Grupo !== null ? e.Grupo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Vendedor !== null ? e.Vendedor : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.TipoDocumento !== null ? e.TipoDocumento : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Documento !== null ? e.Documento : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Inciso !== null ? e.Inciso : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Agente !== null ? e.Agente : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.forma_pago !== null ? e.forma_pago : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Moneda !== null ? e.Moneda : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.sub_ramo !== null ? e.sub_ramo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Ejecutivo !== null ? e.Ejecutivo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Renovacion !== null ? e.Renovacion : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.fecha_antiguedad !== null ? e.fecha_antiguedad : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Desde !== null ? e.Desde : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Hasta !== null ? e.Hasta : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.StatusRecibo !== null ? e.StatusRecibo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.motivo_estatus !== null ? e.motivo_estatus : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.FechaEstatus !== null ? e.FechaEstatus : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.prima_neta !== null ? e.prima_neta : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Descuento !== null ? e.Descuento : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_descuento !== null ? e.por_descuento : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.extra_prima !== null ? e.extra_prima : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_extra_prima !== null ? e.por_extra_prima : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Recargos !== null ? e.Recargos : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_recargos !== null ? e.por_recargos : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Derechos !== null ? e.Derechos : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.sub_total !== null ? e.sub_total : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.IVA !== null ? e.IVA : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_IVA !== null ? e.por_IVA : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.prima_total !== null ? e.prima_total : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.comision_neta !== null ? e.comision_neta : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_comision_neta !== null ? e.por_comision_neta : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.comision_promotor !== null ? e.comision_promotor : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.por_comision_promotor !== null ? e.por_comision_promotor : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Concepto !== null ? e.Concepto : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Plan !== null ? e.Plan : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Marca !== null ? e.Marca : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Tipo !== null ? e.Tipo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Transmision !== null ? e.Transmision : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Puertas !== null ? e.Puertas : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Modelo !== null ? e.Modelo : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Placas !== null ? e.Placas : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Serie !== null ? e.Serie : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Motor !== null ? e.Motor : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.estado_circula !== null ? e.estado_circula : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Color !== null ? e.Color : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.Servicio !== null ? e.Servicio : ''}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S25"><Data ss:Type="String">${e.uso_vehiculo !== null ? e.uso_vehiculo : ''}</Data></Cell>`;
        tableRows += `\n</Row>`;
    });
    
     let footerXML = ` </ss:Table>
     <WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">
      <PageSetup>
       <Header x:Margin="0" />
       <Footer x:Margin="0" />
      </PageSetup>
      <Print>
       <ValidPrinterInfo />
       <PaperSizeIndex>9</PaperSizeIndex>
       <HorizontalResolution>600</HorizontalResolution>
       <VerticalResolution>600</VerticalResolution>
      </Print>
     </WorksheetOptions>
    </Worksheet>
    </Workbook>`;

    let xmlFinal = `${headerXML}\n${tableheaderXML}\n${tableRows}\n${footerXML}`
    return xmlFinal;
}

module.exports = despachosToXML