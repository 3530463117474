import React, { useEffect } from 'react'

import { useState } from 'react'
//assets
import flecha from '../assets/flechaAbajo.svg'
//styles
import {MainContainer, Img, Texto} from '../styles/components/OrdenarporStyled'
import ModalOrdenar from './ModalOrdenar'
const Ordenarpor = ({texto}) => {
  const [showModalOrdenar, setShowModalOrdenar] = useState(false)

  const handleShowModalOrdenar= () => {
    setShowModalOrdenar(!showModalOrdenar)
  }

  const handleHideModalOrdenar= () => {
    setShowModalOrdenar(false)
  } 

  useEffect(()=>{
    
    document.addEventListener('click', (e) => {

      let el = e.target

      for(let i = 0; i<100;i++){
        if(el.parentElement.classList.contains('modal-ordenar')){
          i = 100+1
        }

        if(el.id === 'root'){
          i = 100+1
          handleHideModalOrdenar()
        }

        el = el.parentElement
        
      }

    })
    
  },[])


  return (
    <div className='modal-ordenar'>
      <MainContainer style={{width: '100%', cursor:'pointer'}} onClick={handleShowModalOrdenar}>
          <Texto>{texto}</Texto>
          <Img src={flecha}/>
      </MainContainer>
      <div style={{position: 'relative', height:'10px'}}>
          {showModalOrdenar && <ModalOrdenar  hideModal={handleHideModalOrdenar}/>} 
      </div>
    </div>
  )
}

export default Ordenarpor