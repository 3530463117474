import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    
`;

export const Container = styled.div `
    width:100%;
    display:flex;
    flex-direction: column;
    gap:1rem;
    margin: 1rem 0;
    .indicadores-show {
        background-color: #FFFFFF;
        width: 100%;
        border-radius: 0.5rem;
        p {
            padding: 1rem;
            border: ${props => props.selected ? "1px solid #0582D8" : null};
            border-radius: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: 'Montserrat', sans-serif;
            font-size: 0.85rem;
            svg {
                width: 0.7rem;
            }
        }
    }
`;

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height:18px;
    font-weight: 700;
    padding-left: 0.5rem;
    color:#4E4E4E;
`;

export const PeriodContainer = styled.div`
    display:flex;
    gap:2rem;
    justify-content: space-between;
`;

export const Calendar = styled.input`
    font-family: 'Montserrat', sans-serif;
    background-color: #FFFFFF;
    font-size: #4E4E4E;
    border-radius: 8px;
    border:0;
    padding:1rem
`;

export const Selection = styled.select`
width: 100%;
    font-family: 'Montserrat', sans-serif;
    background-color: #FFFFFF;
    font-size: #4E4E4E;
    border-radius: 8px;
    border:0;
    padding:1rem
`;

export const Button = styled.button`
    width:100%;
    font-family: 'Montserrat', sans-serif;
    padding: 1rem;
    font-size:13px;
    font-weight: 700;
    line-height:40px;
    text-align: center;
    color:#121212;
    background-color: #EDC729;
    border-radius: 16px;
    border:0;
    cursor: pointer;
`;