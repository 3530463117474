import React from 'react'
import {crearComision} from '../../services/crear-comision.service';
//styles
import {MainContainer, Subtitle, Title, Button, ModalContainer} from '../../styles/components/modals/AceptaAutorizacionStyled'

const AceptaAutorizacion = ({handlePress, data, ShowModal}) => {
  const autorizar = async () => {
    let datos = {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor,
      idPoliza: data.idPoliza,
      idRecibo: data.idRecibo,
      fechaHasta: data.fechaHasta,
      fechaDesde: data.fechaDesde,
    };
    const code = await crearComision(datos);
    if(code === 200){
      if (data.idRecibo != null) {
        handlePress(data.idRecibo, "recibo");
      } else if (data.idPoliza != null) {
        handlePress(data.idRecibo, "poliza");
      } else if (data.idVendedor != null) {
        handlePress(data.idRecibo, "vendedor");
      } else {
        handlePress(data.idRecibo, "all");
      }
    } else {
      handlePress(null);
    }
    return handlePress;
  };

  return (
    <ModalContainer>
    <MainContainer open>
        <Title>¿Estás seguro de hacer esta autorización?</Title>
        <Subtitle>Revisa que tu selección sea la correcta.</Subtitle>
        <Button onClick={()=>autorizar()}>AUTORIZAR</Button>
        <p to="#" style={{textDecoration: 'underline', color:'#121212', fontFamily:'Montserrat, sans-serif', fontWeight:'700', cursor:'pointer'}} onClick={()=>ShowModal(false)}>REGRESAR</p>
    </MainContainer>
    </ModalContainer>
  )
}

export default AceptaAutorizacion