export const handleClick = (options, setOptions, data, mas, menos) => {
    if (mas) {
      setOptions({
        ...options,
        scales: {
          ...options.scales,
          x: {
            min: options.scales.x.max < data.labels.length - 1 ? options.scales.x.min + 1 : options.scales.x.min,
            max: options.scales.x.max < data.labels.length - 1 ? options.scales.x.max + 1 : options.scales.x.max
          }
        }
      })
    } 
    if (menos) {
      setOptions({
        ...options,
        scales: {
          ...options.scales,
          x: {
            min: options.scales.x.min === 0 ? 0 : options.scales.x.min - 1,
            max: options.scales.x.min === 0 ? options.scales.x.max : options.scales.x.max - 1
          }
        }
      })
    } 

}