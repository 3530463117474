import styled from "styled-components";

export const ButtonName = styled.div`
    background-color:#E8F6FB;
    border-radius: 1rem;
    width:15rem;
    height:3.6rem; 
    color:#0582D8;
    display: flex;
    justify-content:center;
    align-items:center;
    text-align:center;
    border-width:0;
    border-color: #0582D8;
    font-family: 'Montserrat', sans-serif; 
    font-size: .8rem;
    line-height:2.6rem;
    font-weight: 700;
    text-decoration: none ;
    text-decoration-line: none;
    &:hover {
        background-color:#0582D8;
        color:#FFFFFF;
    box-shadow: 0 5px 15px rgba(145, 92, 182, 0.4);}
    
`;



