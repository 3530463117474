import './style.scss'
import DetalleEstadoPagada from './DetalleEstadoPagada';
import DetalleEstadoCotizacion from './DetalleEstadoCotizacion';
import DetalleEstadoEmitida from './DetalleEstadoEmitida';
const index = ({contenidoDeLaTabla}) => {
  return (
    // <>
    //   <div className='table-details'>
    //     {
    //       contenidoDeLaTabla.map((item) => {
    //         return(
    //           <div key={item.id}>
    //             <div className='table-details__header'>
    //               <div className='row'>
    //                 <div className='row__column row__column--header-left row__column--bold'>
    //                   { item.nombre }
    //                 </div>
    //                 <div className='row__column row__column--header-right row__column--bold'>
    //                   #{ item.folio }
    //                 </div>
    //               </div>
    //             </div>

    //             {
    //               /********** Detalles personales ***********/
    //               item.detallesPersonales.map((subItem, index) => {
    //                 return(
    //                   <div key={index}>
    //                     <div className='table-details__title'>
    //                       Detalles personales
    //                     </div>
    //                     <div className='table-details__content'>
    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Nombre
    //                         </div>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Telefono
    //                         </div>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Fecha de nacimiento
    //                         </div>
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column'>
    //                           { subItem.nombre }
    //                         </div>
    //                         <div className='row__column row__column--three-column'>
    //                           { subItem.telefono }
    //                         </div>
    //                         <div className='row__column row__column--three-column'>
    //                           { subItem.fechaDeNacimiento }
    //                         </div>
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           CP
    //                         </div>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Genero
    //                         </div>
    //                         <div className='row__column row__column--three-column' />
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column'>
    //                           { subItem.cp }
    //                         </div>
    //                         <div className='row__column row__column--three-column'>
    //                           { subItem.genero }
    //                         </div>
    //                         <div className='row__column row__column--three-column' />
    //                       </div>
    //                     </div>
    //                   </div>
    //                 )
    //               })
    //             }

    //             {
    //               /********** Detalles del auto ***********/
    //               item.detallesDelAuto.map((subItem, index) => {
    //                 return(
    //                   <div key={index}>
    //                   <div className='table-details__title'>
    //                     Detalles del auto
    //                   </div>
    //                   <div className='table-details__content'>
    //                     <div className='row'>
    //                       <div className='row__column row__column--four-column row__column--bold'>
    //                         Tipo
    //                       </div>
    //                       <div className='row__column row__column--four-column row__column--bold'>
    //                         Marca
    //                       </div>
    //                       <div className='row__column row__column--four-column row__column--bold'>
    //                         Sub Marca
    //                       </div>
    //                       <div className='row__column row__column--four-column row__column--bold'>
    //                         Modelo
    //                       </div>
    //                     </div>

    //                     <div className='row'>
    //                       <div className='row__column row__column--four-column'>
    //                         {subItem.tipo}
    //                       </div>
    //                       <div className='row__column row__column--four-column'>
    //                         {subItem.marca}
    //                       </div>
    //                       <div className='row__column row__column--four-column'>
    //                         {subItem.subMarca}
    //                       </div>
    //                       <div className='row__column row__column--four-column'>
    //                         {subItem.modelo}
    //                       </div>
    //                     </div>

    //                     <div className='row'>
    //                       <div className='row__column row__column--one-column row__column--bold'>
    //                         Descripción
    //                       </div>
    //                     </div>
    //                     <div className='row'>
    //                       <div className='row__column row__column--one-column'>
    //                         {subItem.descripcion}
    //                       </div>
    //                     </div>

    //                   </div>
    //                   </div>
    //                 )
    //               })
    //             }

    //             {
    //               /********** Detalles de cotizacion ***********/
    //               item.detallesDeCotizacion.map((subItem, index) => {
    //                 return (
    //                   <div key={index}>
    //                     <div className='table-details__title'>
    //                       Detalles de cotizacion
    //                     </div>
    //                     {item.estado ==! 'Cotizado' &&  (
    //                       <div className='table-details__content'>
    //                       <div className='row'>
    //                         <div className='row__column row__column--four-column row__column--bold'>
    //                           Prima Neta
    //                         </div>
    //                         <div className='row__column row__column--four-column row__column--bold'>
    //                           Recargo
    //                         </div>
    //                         <div className='row__column row__column--four-column row__column--bold'>
    //                           Expedición
    //                         </div>
    //                         <div className='row__column row__column--four-column row__column--bold'>
    //                           IVA
    //                         </div>
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.primaNeta}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.recargo}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.expedicion}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.iva}
    //                         </div>
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--two-column row__column--bold'>
    //                           Aseguradora
    //                         </div>
    //                         <div className='row__column row__column--two-column row__column--bold'>
    //                           Fecha de emisión
    //                         </div>
    //                       </div>
    //                       <div className='row'>
    //                         <div className='row__column row__column--two-column'>
    //                           {subItem.aseguradora}
    //                         </div>
    //                         <div className='row__column row__column--two-column'>
    //                           {subItem.fechaDeEmision}
    //                         </div>
    //                       </div>
    //                     </div>
    //                     )
                        
                        
    //                     }
                        
    //                   </div>
    //                 )
    //               })
    //             }

    //             {
    //               /********** Recibo ***********/
    //               item.recibo.map((subItem, index) => {
    //                 return(
    //                   <div key={index}>
    //                     <div className='table-details__title'>
    //                       Recibo
    //                     </div>
    //                     <div className='table-details__content'>
    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Periodo de Pago
    //                         </div>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Monto de Pago
    //                         </div>
    //                         <div className='row__column row__column--three-column row__column--bold'>
    //                           Fecha de pago
    //                         </div>
    //                       </div>

    //                       <div className='row'>
    //                         <div className='row__column row__column--three-column'>
    //                           {subItem.periodoDePago}
    //                         </div>
    //                         <div className='row__column row__column--three-column'>
    //                           {subItem.montoDePago}
    //                         </div>
    //                         <div className='row__column row__column--three-column'>
    //                           {subItem.fechaDePago}
    //                         </div>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 )
    //               })
    //             }

    //             {
    //               /********** Desglose de recibos ***********/
    //               item.desgloseDeRecibos.lenght !== 0 && 
    //               <>
    //                 <div className='table-details__title'>
    //                   Desglose de recibos
    //                 </div>
    //                 <div className='table-details__content'>
    //                 <div className='row'>
    //                   <div className='row__column row__column--four-column row__column--bold'>
    //                     Fecha
    //                   </div>
    //                   <div className='row__column row__column--four-column row__column--bold'>
    //                     Pago
    //                   </div>
    //                   <div className='row__column row__column--four-column row__column--bold'>
    //                     Monto
    //                   </div>
    //                   <div className='row__column row__column--four-column row__column--bold'>
    //                     Status
    //                   </div>
    //                 </div>
    //                 {
    //                   item.desgloseDeRecibos.map((subItem, index) => {
    //                     return (
    //                       <div key={index} className='row'>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.fechaDePago}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.pago}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.montoDePago}
    //                         </div>
    //                         <div className='row__column row__column--four-column'>
    //                           {subItem.status}
    //                         </div>
    //                       </div>
    //                     )
    //                   })
    //                 }
    //                 </div>
    //               </>
    //             }

    //             {
    //               /********** Estado ***********/
    //               <div className='table-details__status'>
    //                 Estado:  { item.estado }
    //               </div>
    //             }
    //           </div>
    //         )
    //       })
    //     }

    //   </div>
    // </>
    <>
        {
            contenidoDeLaTabla.map((item) => {
            switch (item.estado) {
              case "Cotizado":
                  return <DetalleEstadoCotizacion response = {item} />   
              case "Poliza pagada":
                  return <DetalleEstadoPagada response={item}/>    
              case "Póliza emitida":
                return <DetalleEstadoEmitida response={item}/>
              default:
                break;
            }
          })
        }
    </>
            
  );
};

export default index;