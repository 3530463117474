export const obtenerURLPoliza = async (id) => {
  const apiUrl = "https://backendsicas.gpmass.mx/api/poliza-sicas";
  const body = JSON.stringify({
    "IDDocto": id
  });
  let results = await fetch(apiUrl, {
    method: "post",
    body: body,
    headers: {
      "Content-type": "Application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.data;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  console.log(results);
  return results;
}