//hooks
import React, {useEffect, useState} from 'react'
import UseFetch from '../components/UseFetch'
//components
import ModalBuscar from '../components/ModalBuscar'
import NavBar from '../components/NavBar'
import ListaOficinas from './components/ListaOficinas'
import Tabla2 from '../components/Tabla2'
import Titulo from '../components/Titulo'
import DetallesDelAgente from './components/DetallesDelAgente'

//style
import { MainContainer, TableContainer, LinkStyle } from '../styles/EstadoNuevo.styles'

// Services
import { obtenerOficinas } from './services/obtener-oficinas.service'
import { obtenerDetalles  } from './services/obtener-detalles-oficina.service'
import { Link, useNavigate } from 'react-router-dom'
import BackButton from '../components/BackButton'
const EstadoNuevo = () => {
  // Obtener agencias
  const [ mostrarDetallesAgente, setMostrarDetallesAgente ] = useState(false)
  const [ detallesOficina, setDetallesOficina ] = useState([])
  const [oficinas, setOficinas] = useState([])
  const [idOficinaSeleccionada, setIdOficinaSeleccionada] = useState(0)
  const [isFiltered, setIsFiltered] = useState(false)

  const obtenerListaDeOficinas = async () => {
    const nuevaListaDeOficinas = await obtenerOficinas()
    setOficinas(nuevaListaDeOficinas)
  }
  const resetDetallesOficina = async () => {
    setDetallesOficina([])
  }
  useEffect(() => {
    obtenerListaDeOficinas()
  },[])

  useEffect(() => {
    seleccionarOficina(idOficinaSeleccionada)
  },[idOficinaSeleccionada])


  const resetFiltros = () => {
    //setIsFiltered(false)
    resetDetallesOficina()
    obtenerListaDeOficinas()

  }



  // Realizar busqueda resultados
  const filtrarResultados = async (oficinaBuscada, despachoBuscado) => {
    
    setIsFiltered(true)

    const despachos = await obtenerOficinas(oficinaBuscada)
    setDetallesOficina([])

    if (despachoBuscado) {
      const detalles = await obtenerDetalles(despachoBuscado)
      setOficinas(despachos)
      await setDetallesOficina(detalles)
      setIdOficinaSeleccionada(Number(despachoBuscado))
      await  setMostrarDetallesAgente(true)
    }else{
      setOficinas(despachos)
    }
  }
  const obtenerLeadsDelAgente = async (idOficinaSeleccionada) => {
      const nuevosDetallesDeOficina = await obtenerDetalles(idOficinaSeleccionada)
      await setDetallesOficina(nuevosDetallesDeOficina)
      await seleccionarOficina(idOficinaSeleccionada)
      await  setMostrarDetallesAgente(true)
  }
  const seleccionarOficina = (idOficinaSeleccionada) => {
    const nuevaFila = oficinas.map(obj => {
      if (obj.id === idOficinaSeleccionada) {
        return {
          ...obj,
          selected: true
        }
      } else {
        return {
          ...obj,
          selected: false
        }
      }
      return obj
    })
    setOficinas([...nuevaFila])
  }

  const [numeroAsociados, setNumeroAsociados] = useState(0)
  const [change, setChange] = useState(false)
  
  const getTotalAsociados = async () =>{
    let contador = 0
    for(const ofi of oficinas){
      
      const detalles = await obtenerDetalles(ofi.id)
      contador += detalles.length
    }
    return contador
  }

  const isOfficeSelected = () => !!(oficinas.find(ofi => ofi.selected))

  const funcSetNumeroAsociados = async () =>{
    let num = 0
    if (isOfficeSelected()) {
      num = detallesOficina.length
    }else {
      num = await getTotalAsociados()
    }
    //console.log(num)
    setNumeroAsociados(num)
  }

  useEffect(()=>{
    funcSetNumeroAsociados()
  },[change])

  useEffect(()=>{
    let ofiDesp = JSON.parse(localStorage.getItem('ofi-desp'))
    //console.log('ofiDesp',ofiDesp)
    if (!isOfficeSelected()) {
      return;
    }
    const despacho = oficinas.find(ofi => ofi.selected)
    if (ofiDesp) {
      ofiDesp = {
        ...ofiDesp,
        despacho: despacho.nombre
      }
    }else{
      ofiDesp = {
        oficina: null,
        despacho: despacho.nombre
      }
    }
    localStorage.setItem('ofi-desp', JSON.stringify(ofiDesp))
    //console.log(ofiDesp)
  },[oficinas])


  useEffect(()=> {
    if (!isFiltered && oficinas.length !== 0) {
      resetFiltros()
    }
  }, [isFiltered])

  return (

    <MainContainer>
        <div style={{marginTop:'2rem'}}>
        <NavBar/>
        </div>
        <div style={{marginTop:'2rem'}}>
        <Titulo
       texto1='Asociados'
       texto2= {'Total de asociados: ' + numeroAsociados}////
       />
      
        </div>

        <TableContainer>
            
            <ListaOficinas
              response={oficinas}
              filtrarResultados = { filtrarResultados }
              isFiltered={isFiltered}
              setIsFiltered={ setIsFiltered }
              obtenerLeadsDelAgente={ obtenerLeadsDelAgente }
              reset={resetDetallesOficina}
              
              changeResponse={()=>setChange(!change)}
            />

            
            {
            mostrarDetallesAgente &&
              <DetallesDelAgente
                response={ detallesOficina }
              />
            }
        </TableContainer>
      
     
    </MainContainer>
  )
}

export default EstadoNuevo