
import { TableName, ContainerImgTable, TableHead, Tr, Table, TdImpar, TdPar,SearchContainer  } from '../../../styles/Tabla1.styles'
import React, { useEffect, useState } from 'react'
import flecha from '../../../assets/flechaDerecha.svg'
import lupa from '../../../assets/lupa.svg'
import filtro from '../../../assets/filtro.svg'
import ModalBuscar from '../BuscadorModal'
import Buscador from '../Buscador'

const Tabla1 = ({response, obtenerLeadsDelAgente, filtrarResultados, isFiltered ,setIsFiltered ,reset, changeResponse}) => {
  
  
  const [change, setChange] = useState([])
  const [filtered, setFiltered] = useState([])

  useEffect(()=>{
    let container = [] 
    for (let i=0; i<response.length; i++){
      container.push(false)
    }
    setChange(container)
    setFiltered(response)
    changeResponse()
  },[response] )
  
  const handleClic = (param)=>{
    
  }

  const [hide, setHide] = useState(false)

  const mostrarModal = ()=>{
    setHide(!hide)
  }
  const changeData = (newData)=>{
    reset()
    setFiltered(newData)
    
  }
  
  return (
    <>
    {/* style={{width: "400px;"}} */}
      <Table style={{minWidth: "400px !important"}}>
        <thead>
            <TableHead>
                <TableName>Despachos </TableName>
                <ContainerImgTable>
                <td><Buscador data={response} setIsFiltered={setIsFiltered} setFiltered={changeData} by={'nombre'}/></td>
                <td style={{cursor:'pointer'}}onClick={mostrarModal}><img src={filtro} alt='filtro'/></td>
                </ContainerImgTable>
            </TableHead>
        </thead>
        <div style={{display:'flex', justifyContent:'end', marginBottom:'5px'}}>
          {isFiltered && (
            <span onClick={() => setIsFiltered(false)} style={{color: '#0582D8', cursor: 'pointer', fontWeight: 600}}>
              RESET ALL
            </span>
          )}
        </div>
        <tbody>

        {filtered.map((el, idx) => (
                <Tr key={idx} onClick={()=>obtenerLeadsDelAgente(el.id)}>
                   {
                   idx % 2 === 0 ? 
                  <TdPar  
                    style={{backgroundColor: el.selected ?'#EDC729' : '#CCCCCC', color: el.selected ? '#4E4E4E' : '#838383' }}
                  >
                      {el.nombre} 
                    <img src={flecha} alt='flecha'/>
                  </TdPar>  
                  :
                  <TdImpar 
                  style={{backgroundColor: el.selected ?'#EDC729' : '#F8F8F8', color: el.selected ? '#4E4E4E' : '#838383'  }}
                  >
                      {el.nombre}
                      <img src={flecha} alt='flecha'/>
                  </TdImpar>} 
                </Tr>
            ))}
        </tbody>
       {hide ?  <ModalBuscar filtrarResultados = { filtrarResultados } hideModal={mostrarModal}/> : null} 
      </Table>
         
      </>
  )
}

export default Tabla1