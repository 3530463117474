// export const informacionDelAgente = (idAgente) => {

//   const informacionDelAgente = [
//   {
//     id: 1,
//     titulo: 'Leads',
//     nombre: 'Claudia',
//     primerApellido: 'Torres',
//     nombreCompleto: 'Claudia Natalia Zambrano Torres',
//     telefono: '55 5555 5555',
//     fechaDeNacimiento: '10/10/10'
//   },
//   {
//     id: 2,
//     titulo: 'Leads',
//     nombre: 'Juan',
//     primerApellido: 'Torres',
//     nombreCompleto: 'Juan Torres',
//     telefono: '55 5555 5555',
//     fechaDeNacimiento: '10/10/10'
//   },
//   {
//     id: 3,
//     titulo: 'Leads',
//     nombre: 'Pedro',
//     primerApellido: 'Torres',
//     nombreCompleto: 'Pedro Torres',
//     telefono: '55 5555 5555',
//     fechaDeNacimiento: '10/10/10'
//   }
// ]
//   const resultado = informacionDelAgente.filter((item) => {
//     return item.id == idAgente
//   })
//   return resultado
    
// }

export const informacionDelAgente = async (id=0) => {
  const apiUrl = `https://backendsicas.gpmass.mx/api/getVendedor/${id}`;
  let resultado = await fetch(apiUrl, {
    method: "get",
  })
    .then((response) => response.json())
    .then((data) => {
      let telefonoSeleccionado = "Sin telefono";
      for(let i = 0; i < 5; i++){

        let telefono = "";
        if(i === 0){
          telefono = "telefono";
        }
        else{
          telefono = "Telefono"+i;
        }

        if(data.data[0][telefono] !== undefined && data.data[0][telefono] !=null){
            telefonoSeleccionado = data.data[0][telefono];
            break;
        }
      }

      let infoVendedor = {
        id: data.data[0].IDVend,
        titulo: "Leads",
        nombre: data.data[0].Nombre,
        primerApellido: data.data[0].ApellidoP,
        nombreCompleto: data.data[0].NombreCompleto,
        telefono: telefonoSeleccionado,
        fechaDeNacimiento: data.data[0].FechaNac
      }

      return infoVendedor;
    })
    .catch((err) => console.error("ERROR: ", err.message));

    // console.log("RESULTADO", resultado);
  return [resultado];
};