import React from 'react'

import { Container, Text1, Text2 } from '../styles/Titulo.styles'
const Titulo = ({texto1, texto2}) => {
  return (
    <Container>
        <Text1>{texto1}</Text1>
        <Text2>{texto2}</Text2>
      
    </Container>
  )
}

export default Titulo