import {obtenerPoliza} from './mock-obtener-lista-de-cotizaciones.service';


// export const obtenerDetallesDeUnaCotizacionPorId = idCotizacion => {

//   const cotizaciones = [
//     {
//       id: 1,
//       estado: 'Poliza pagada',
//       nombre: 'Enrique Gómez',
//       folio: 123456,
//       detallesPersonales: [
//         {
//           nombre: 'Enrique Gómez Pérez',
//           telefono: '55 55 5555 5555',
//           fechaDeNacimiento: '10/10/1990',
//           cp:'12345',
//           genero: 'Hombre'
//         }
//       ],
//       detallesDelAuto: [
//         {
//           tipo: 'Enrique Gómez Pérez',
//           marca: 'Sentra',
//           subMarca: 'Nissan',
//           modelo:'2015',
//           descripcion: 'Sentra Sence, Automatico, ABS, AA.'
//         }
//       ],
//       detallesDeCotizacion: [ 
//         {
//           primaNeta: 'Prima Neta',
//           recargo: 'Recargo',
//           expedicion: 'expedicion',
//           iva: 'iva',
//           aseguradora: 'Aseguradora',
//           fechaDeEmision: 'Fecha de emisión'
//         }
//       ],
//       recibo: [ 
//         {
//           periodoDePago: 'Contado',
//           montoDePago: '$1,000,000.00',
//           fechaDePago: '11/11/2022'
//         }
//       ],
//       desgloseDeRecibos: [
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         },
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         }
//       ]
//     },
//     {
//       id: 2,
//       estado: 'Póliza emitida',
//       nombre: 'Juan Perez',
//       fecha: '11/11/2022',
//       folio: 123456,
//       detallesPersonales: [
//         {
//           nombre: 'Juan Perez',
//           telefono: '55 55 5555 5555',
//           fechaDeNacimiento: '10/10/1990',
//           cp:'12345',
//           genero: 'Hombre'
//         }
//       ],
//       detallesDelAuto: [
//         {
//           tipo: 'Enrique Gómez Pérez',
//           marca: 'Sentra',
//           subMarca: 'Nissan',
//           modelo:'2015',
//           descripcion: 'Sentra Sence, Automatico, ABS, AA.'
//         }
//       ],
//       detallesDeCotizacion: [ 
//         {
//           primaNeta: 'Prima Neta',
//           recargo: 'Recargo',
//           expedicion: 'expedicion',
//           iva: 'iva',
//           aseguradora: 'Aseguradora',
//           fechaDeEmision: 'Fecha de emisión'
//         }
//       ],
//       recibo: [ 
//         {
//           periodoDePago: 'Contado',
//           montoDePago: '$1,000,000.00',
//           fechaDePago: '11/11/2022'
//         }
//       ],
//       desgloseDeRecibos: [
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         },
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         }
//       ]
//     },
//     {
//       id: 3,
//       estado: 'Cotizado',
//       nombre: 'Carlos Perez',
//       folio: 123456,
//       fecha: '11/11/2022',
//       detallesPersonales: [
//         {
//           nombre: 'Juan Perez',
//           telefono: '55 55 5555 5555',
//           fechaDeNacimiento: '10/10/1990',
//           cp:'12345',
//           genero: 'Hombre'
//         }
//       ],
//       detallesDelAuto: [
//         {
//           tipo: 'Enrique Gómez Pérez',
//           marca: 'Sentra',
//           subMarca: 'Nissan',
//           modelo:'2015',
//           descripcion: 'Sentra Sence, Automatico, ABS, AA.'
//         }
//       ],
//       detallesDeCotizacion: [ 
//         {
//           primaNeta: 'Prima Neta',
//           recargo: 'Recargo',
//           expedicion: 'expedicion',
//           iva: 'iva',
//           aseguradora: 'Aseguradora',
//           fechaDeEmision: 'Fecha de emisión'
//         }
//       ],
//       recibo: [ 
//         {
//           periodoDePago: 'Contado',
//           montoDePago: '$1,000,000.00',
//           fechaDePago: '11/11/2022'
//         }
//       ],
//       desgloseDeRecibos: [
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         },
//         {
//           id: 1,
//           fechaDePago: '11/11/2022',
//           pago: '1/2',
//           montoDePago: '$1,000,000.00',
//           status: '11/11/2022'
//         }
//       ]
//     }
//   ]
//   const resultado = cotizaciones.filter((item) => {
//     return item.id === idCotizacion
//   }
//   ) 
//   return resultado
// }

export const obtenerDetallesDeUnaCotizacionPorId = async (idCotizacion) => {
  let cotizaciones = await obtenerCotizaciones(idCotizacion);
  // console.log("COTIZACIONES: ", cotizaciones);
  for (let i = 0; i < cotizaciones.length; i++) {
    console.log("COTIZACION RECIBIDA", cotizaciones);
    if (cotizaciones.length > 0 && cotizaciones[i].idPoliza !== null && cotizaciones[i].idPoliza !== '0') {
      var respPoliza = await obtenerPoliza(cotizaciones[i].idPoliza);
      // console.log("Obtener recibos de poliza: ", cotizaciones[i].idPoliza);

      let estado = '';
      estado = cotizaciones[i].estado;

      // switch (respPoliza.StatusPago) {
      //   case 'Pagado':
      //     estado = 'Poliza pagada'
      //     break;
      //   case 'Emitida':
      //     estado = 'Póliza emitida'
      //     break;
      //   default: estado = 'Cotizado'
      // }

      // console.log("ESTADO RECIBIDO", cotizaciones[i].estado);

      // switch (estado) {
      //   case 'pagado'||'Poliza pagada':
      //     estado = 'Poliza pagada'
      //     break;
      //   case 'emitido'||'Póliza emitida':
      //     estado = 'Póliza emitida'
      //     break;
      //   default: estado = 'Cotizado'
      // }

      cotizaciones[i].estado = estado;
      // console.log(respPoliza.StatusPago, cotizaciones[i].estado);

      let respRecibos = await obtenerRecibos(cotizaciones[i].idPoliza);
      cotizaciones[i].desgloseDeRecibos = respRecibos.length > 0 ? respRecibos : [];
      if (cotizaciones[i].desgloseDeRecibos.length > 0) {
        var reciboInicial = cotizaciones[i].desgloseDeRecibos[0];
        // var reciboFinal = cotizaciones[i].desgloseDeRecibos[cotizaciones[i].desgloseDeRecibos[cotizaciones[i].desgloseDeRecibos.length - 1]];
        cotizaciones[i].recibo = [
          {
            periodoDePago: reciboInicial.periodoPago,
            montoDePago: reciboInicial.montoDePago,
            fechaDePago: reciboInicial.fechaDePago
          }
        ]
      }

      
    }
    else{

      console.log("ESTADO RECIBIDO SEGUNDO 1", cotizaciones[i].estado);

      // switch (estado) {
      //   case 'pagado'||'Poliza pagada':
      //     estado = 'Poliza pagada'
      //     break;
      //   case 'emitido'||'Póliza emitida':
      //     estado = 'Póliza emitida'
      //     break;
      //   default: estado = 'Cotizado'
      // }


      console.log("ESTADO RECIBIDO SEGUNDO 2", cotizaciones[i].estado);

    }
  }
  // console.log("Cotizaciones", cotizaciones.length);
  console.log("FINAL", cotizaciones);
  return cotizaciones;
};

const obtenerCotizaciones = async (idCotizacion) => {
  const apiUrl = `https://backendsicas.gpmass.mx/api/get-cotizacion/${idCotizacion}`;
  let resultado = await fetch(apiUrl, {
    method: "get",
    headers: { "Content-type": "Application/json" }
  })
    .then((response) => response.json())
    .then(({ data }) => {
      const cotizaciones = data.cotizaciones;
      let results = [];

      console.log("SHOW COTIZACIONES", cotizaciones);

      for (let i = 0; i < cotizaciones.length; i++) {

        // let estado = cotizaciones[i].estado;
        // if (cotizaciones[i].idPoliza === null) {
        //   estado = 'Cotizado';
        // }

        // let idPoliza = cotizaciones[i].idPoliza;
        // switch (true) {
        //   case idPoliza === null:
        //     estado = 'Cotizado'
        //     break;
        //   case idPoliza !== undefined:
        //     estado = 'Poliza pagada'
        //     break;
        //   default: estado = 'Cotizado'
        // }

        console.log("ESTADO RECIBIDO 3", cotizaciones[i].estado);

        let estado = cotizaciones[i].estado;

        switch (estado) {
          case 'pagado':
            estado = 'Poliza pagada'
            break;
          case 'emitido':
            estado = 'Póliza emitida'
            break;
          default: estado = 'Cotizado'
        }

        results.push(
          {
            id: cotizaciones[i].id,
            estado: estado,
            nombre: cotizaciones[i].nombre,
            folio: cotizaciones[i].folio,
            fecha: cotizaciones[i].fecha_cotizacion,
            idPoliza: cotizaciones[i].idPoliza,
            detallesPersonales: [
              {
                nombre: cotizaciones[i].detallesPersonales[0].nombre,
                telefono: '',
                fechaDeNacimiento: cotizaciones[i].detallesPersonales[0].fechaDeNacimiento,
                cp: cotizaciones[i].detallesPersonales[0].cp,
                genero: cotizaciones[i].detallesPersonales[0].genero,
              }
            ],
            detallesDelAuto: [
              {
                tipo: cotizaciones[i].detallesDelAuto[0].tipo,
                marca: cotizaciones[i].detallesDelAuto[0].marca,
                subMarca: cotizaciones[i].detallesDelAuto[0].subMarca,
                modelo: cotizaciones[i].detallesDelAuto[0].modelo,
                descripcion: cotizaciones[i].detallesDelAuto[0].descripcion,
              }
            ],
            detallesDeCotizacion: [
              {
                primaNeta: cotizaciones[i].detallesDeCotizacion[0].primaNeta,
                recargo: cotizaciones[i].detallesDeCotizacion[0].recargo,
                expedicion: cotizaciones[i].detallesDeCotizacion[0].expedicion,
                iva: cotizaciones[i].detallesDeCotizacion[0].iva,
                aseguradora: cotizaciones[i].detallesDeCotizacion[0].aseguradora,
                fechaDeEmision: cotizaciones[i].detallesDeCotizacion[0].fechaDeEmision,
                url_cotizacion: cotizaciones[i].detallesDeCotizacion[0].url_cotizacion
              }
            ],
            recibo: [],
            desgloseDeRecibos: [
              // {
              //   id: 1,
              //   fechaDePago: '11/11/2022',
              //   pago: '1/2',
              //   montoDePago: '$1,000,000.00',
              //   status: '11/11/2022'
              // },
              // {
              //   id: 1,
              //   fechaDePago: '11/11/2022',
              //   pago: '1/2',
              //   montoDePago: '$1,000,000.00',
              //   status: '11/11/2022'
              // }
            ]
          }
        );

      }
      return results;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  return resultado;
}

const obtenerRecibos = async (idPoliza) => {
  const apiUrl = 'https://backendsicas.gpmass.mx/api/get-recibos';
  let resultado = await fetch(apiUrl, {
    method: "post",
    body: JSON.stringify({
      "idPoliza": idPoliza
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response.json())
    .then(({ data }) => {
      let recibos = [];
      for (let i = 0; i < data.length; i++) {
        recibos.push(
          {
            id: data[i].IDRecibo,
            fechaDePago: data[i].FLimPago,
            pago: data[i].Serie,
            periodoPago: data[i].Periodo,
            montoDePago: data[i].PrimaTotal,
            status: data[i].StatusRecibo
          }
        )
      }
      return recibos;
    })
    .catch((err) => console.error("Ha ocurrido un error: ", err.message));
  console.log("IDPOLIZA: ", idPoliza);
  return resultado;
}