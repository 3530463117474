import React, { useEffect, useState } from 'react'
import { obtenerOficinas as obtenerDespachos } from '../../services/obtener-oficinas.service'
import { obtenerOficinas } from '../../services/obtener-oficinasFiltro.service'
import './styles.scss'
const ModalBuscar = ({filtrarResultados, hideModal}) => {
  /* 
    Para mejorar este código construir una funcion que por medio de un ciclo
    actualice los valores de cada input
  */
  const [oficina, setOficina ] = useState('')
  const [despacho, setDespacho ] = useState('')
  const [disabledDespacho, setDisabledDespacho] = useState(true)
  const [disabledButton, setDisabledButton] = useState(true)
 
  const [oficinas, setOficinas] = useState([])
  const [despachos, setDespachos] = useState([])

  const getOficinas = async () => {
    const oficinas = await obtenerOficinas();
    setOficinas(oficinas)
  }

  const getDespachos = async (id) => {
    const despachos = await obtenerDespachos(id);
    setDespachos(despachos)
  }

  useEffect(()=>{
    getOficinas()
    
  },[])

  const actualizarValorOficina = (e) => {
    setOficina(e.target.value)
  }
  const actualizarValorDespacho = (e) => {
    setDespacho(e.target.value)
  }
 
  /*useEffect(() => {
    const data = {oficina: oficina, despacho: despacho}
    localStorage.setItem('ofi-desp', JSON.stringify(data))
  }, [oficina, despacho])*/

  useEffect(()=>{
    if (oficina) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
    }
  },[oficina])

  useEffect(()=>{
    if (oficina) {
      getDespachos(Number(oficina))
      setDisabledDespacho(false)
    } else {
      setDisabledDespacho(true)
      setDespacho('');
    }
  },[oficina])

 

  return (
    <div className='modal'>
      <div>
        <div className='modal__input-container'>
          <div className='input-label'>
            Oficina:
          </div>
          <div className='select'>
            <select value={oficina} onChange={(e) => actualizarValorOficina(e)} >
              <option disabled></option>
              {oficinas.map(ofi => (
                  <option value={ofi.IDOfna}>{ofi.NombreCompleto}</option>
                )
              )}
              
            </select>
          </div>
        </div>

        <div className='modal__input-container'>
          <div className='input-label'>
            Despacho:
          </div>
          <div className='select'>
            <select value={despacho} disabled={disabledDespacho} onChange={(e) => actualizarValorDespacho(e)} >
              <option></option>
              {despachos.map(des => (
                  <option value={des.id}>{des.nombre}</option>
                )
              )}
            </select>
          </div>
        </div>

        
      </div>

      <button 
        className={`modal__button ${disabledButton ? 'modal__button--disabled' : ''}`}
        onClick={() => {
          const data = {oficina: oficina, despacho: despacho}
          localStorage.setItem('ofi-desp', JSON.stringify(data))
          filtrarResultados(oficina, despacho)
          hideModal()
        }}
        disabled={disabledButton}
      >
        Aplicar
      </button>
    </div>
  )
}

export default ModalBuscar