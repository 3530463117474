import styled from "styled-components";

export const MainContainer  = styled.div`
    width:100%;
    
    display: flex;
    align-items:center;
    
    gap:1rem
`;

export const Img =  styled.img`
    width:0.5rem;
    cursor: pointer;

`;

export const Enlace =  styled.link`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 1.12rem;
    line-height:1.5rem;
    color:#4E4E4E;
    
`;
