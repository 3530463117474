import React from 'react'
import '../styles/styles.scss'
import logo from '../../general/assets/logo.svg'

const Header = () => {
  return (
    <>
      <div className='login-header'>
        <img className='logo' src={logo} alt="logo" />
      </div>
    </>
  )
}

export default Header