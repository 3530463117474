import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    height:5rem;
    background-color:#FFAD01;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    
    
`;

export const PanelContainer = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    padding:1rem;
    gap:1rem
`;

export const Logo = styled.img`
    padding:1rem;
    width:6rem;
`;

export const ImgPanel = styled.img`
    width:1rem;
    height: 1rem;
`;