import React from 'react'
//assets
import flecha from '../assets/flechaDerecha.svg'
//styles
import {MainContainer, Texto, Img} from '../styles/components/FechaPeriodosStyled'
const FechaPeriodo = ({ periodoStart, periodoEnd, oficina }) => {
  return (
            <MainContainer>
                <Texto>Fecha Inicial {periodoStart}</Texto>
                <Texto>-</Texto>
                <Texto>Fecha final {periodoEnd}</Texto>
                <Img src={flecha}/>
                <Texto>{oficina}</Texto>
            </MainContainer>
  )
}

export default FechaPeriodo