import styled from "styled-components";

export const BotonStyle = styled.div`
  position: absolute;
  background-color: #ffff;
  border: 1px solid lightblue;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  right: ${prop => prop.right ? '1rem' : null};
  left: ${prop => prop.left ? '1rem' : null};
  svg {
    rotate: ${prop => prop.right ? '-90deg' : '90deg'}
  }
`