export const obtenerDash = async (filtros) => {
  console.log(filtros)
  let dash = [];
  const apiUrl = "https://backendsicas.gpmass.mx/api/get-dash-data";
  await fetch(apiUrl, {
    method: "post",
    body: JSON.stringify(filtros),
    headers: {
      "Content-type": "Application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      dash = data.data;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  return dash;
};
