import { SubTitle } from "chart.js";
import styled from "styled-components";

export const MainContainer = styled.div`
    //width:15%;
    //padding:1rem;
    position: absolute;
    background-color: white;
    top: 105%;
    left: -50%;
    width: 120%;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1rem;
`;
export const Text = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size:0.87rem;
    font-weight: 700;
    line-height:18px;
    color:#4E4E4E
`;

export const Linea = styled.div`
    width:100%;
    height:1px;
    background-color:#838383
`;

export const Aplicar = styled.div`
    width:100;
    padding:0 1rem;
    justify-content:center;
    align-items:center;
    background-color:#EDC729;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
`;

export const AplicarTxt = styled.div`
font-family: 'Montserrat', sans-serif;
font-size: 0.75rem;
color:#FFFFFF;
line-height:40px;
`;