import { useState, useEffect } from "react";

const useChartContent = (dataset, labels, decimals=true) => {
  const [options, setOption] = useState({
    responsive: true,
    scales: {
      y: {
        ticks: {
          precision: Number(decimals),
          callback: function (value, index, values) {
            return `${value}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "",
      },
    },
  });

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    if (labels?.length > 6) {
      setOption({
        ...options,
        scales: {
          ...options.scales,
          x: {
            min: 0,
            max: 5
          }
        },
        plugins: {
          ...options.plugins,
          title: {
            ...options.plugins.title,
            // text: title
          }
        }
      })
    } else {
      setOption({
        ...options,
        plugins: {
          ...options.plugins,
          title: {
            ...options.plugins.title,
            // text: title
          }
        }
      })
    }
    setData({
      labels,
      datasets: dataset
    })
  }, [dataset, labels])

  return {options, data}
};
export default useChartContent;