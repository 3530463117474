import styled from "styled-components";

export const NavBarContainer  = styled.div`
    width:41rem;
    height:3.5rem;
    display: flex;
    
    justify-content: center;
    border-radius: 1rem;
`;

 
export const Pestana1 = styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    align-items: center;
   
    background-color:#EDC729;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
`;

export const Pestana2 = styled.div`
    width:50%;
    display:flex;
    justify-content:center;
    align-items: center;
    
    background-color: #F8F8F8;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    
`;

export const Texto1 = styled.p`
    font-family: 'Montserrat', sans-serif;

    text-align:center;
    color:#121212;
    font-size: 1.1rem,;
    line-height:1.5rem;
    font-weight: 700;
`; 

export const Text2 = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align:center;
    color:#838383;
    line-height:1.5rem;
    font-weight: 700;
`;





