import styled from "styled-components";

export const MainContainer = styled.div`
    width:80%;
    min-height:calc(100vh - 5rem);
    margin: 0 auto;
    background-color: #F8F8F8;
   
`;

export const ContainerFecha = styled.div`
    display:flex;
    justify-content:flex-end
`;

export const ContainerNav = styled.div`
    padding: 2rem 0;
    background-color: #FFFFFF;
`;

export const ContainerIndicadores = styled.div `
    background-color:#F8F8F8 ;
    padding:1rem;
    display: flex;
    .chart-layout {
        width: 30%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;

export const Container = styled.div `
    display: flex;
    gap:2rem;
    width: 100%;
`;

export const Container2do = styled.div`
background-color:#FFFFFF ;
margin: 1rem 1rem 1rem 0;
/* margin-right: 1rem; */
border-radius:8px;
width: 70%;

`;