import styled from "styled-components";

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto;
    
    margin-top: 1rem;
`;

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#4E4E4E;
    height:100%;
    padding:1rem;
    background-color:#EDC729;
    
`;

export const Text = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#838383;
    padding:1rem;
    background-color: ${props => props.par ? null : 'white'};
    border-bottom: 2px solid rgba(131, 131, 131, 0.2);
    :hover{
        font-weight: 700;
        font-size: 1rem;
    line-height:24px;
    }
    
`;

export const Total = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#121212;
    padding:1rem;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    
`

export const Paginationp = styled.p`
    cursor: pointer;
    font-weight: ${props => props.curr ? 'bold' : 'normal'};
`