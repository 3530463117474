import styled from "styled-components";

export const MainContainer = styled.div`
    //width:70%;
    display:flex;
    flex-direction:row;
    
`;

export const Table = styled.table`
    width:80%;
    .Infodiv{
        background-color: #FFFFFF;
        min-width:90%;
        display: ${props => props.isShow ? 'flex' : 'none'};
        margin-bottom:15px;
    }
    
`;

export const TableHead = styled.table`
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    
    margin: 2rem ;
    
`;
export const TableName = styled.td`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
`;

export const Tbody = styled.tbody`
    align-items:space-between;
`;


export const ContainerImgTable = styled.div`
    display:flex;
    flex-direction:row;
    gap:.5rem;
    justify-content:space-between;
`;

export const Tr = styled.tr`
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom:5px;
`;

export const TdPar = styled.td`
    background-color:  #CCCCCC;
    padding:1rem;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: .9;
    
`;

export const TdImpar = styled.td`
    background-color: #F8F8F8;
    padding:1rem;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    font-size: .9;
`;

export const Tdclic = styled.td`
    background-color: #EBCA00;
    padding:1rem;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Flecha = styled.img`
  widht:15px;
  height:15px;
`;

export const TdNombre= styled.td`
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    line-height: 1.2rem;
    color:#838383;
    font-size:.9rem

`;

export const TdDetalles = styled.td`
     font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.1rem;
    color:#838383;
    font-size:.9rem
`;

