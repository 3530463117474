import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/styles.scss'

import exit from '../assets/Exit.svg'

const DropdownMenu = ({ show, hide }) => {
  const navigate = useNavigate()

  const logout = () => {
    //hide();
    localStorage.removeItem('USER')
    navigate('/login')

  }

  return (
    <>
      <div className={ `dropdown-menu-container ${show && 'dropdown-menu-container-show'}` }>
        <div className='dropdown-menu'>
            <button className='option-menu' onClick={logout}>
              <img src={exit} alt="exit" /> Cerrar sesión
            </button>

        </div>
      </div>
    </>
  )
}

export default DropdownMenu