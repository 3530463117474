import React, { createContext, useReducer } from "react";

const AdminVentasFiltrosContext = createContext();

const initialState = {
    periodoStart: '',
    periodoEnd: '',
    oficina: '',
    idOficina: undefined,
    despacho: '',
    idDespacho: undefined,
    vendedor: '',
    idVendedor: undefined,
    consecutivo:false,
    estatus: false,
    ascendente: true,
    descendente: false,
    autorizado: true,
    pendiente: false,
  };

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_OFICINA':
            return{
                ...state,
                oficina: action.payload
            };
        case 'SET_OFICINA_ID':
            return{
                ...state,
                idOficina: action.payload
            };
        case 'SET_DESPACHO':
            return{
                ...state,
                despacho: action.payload
            };
        case 'SET_DESPACHO_ID':
            return{
                ...state,
                idDespacho: action.payload
            };
        case 'SET_VENDEDOR':
            return{
                ...state,
                vendedor: action.payload
            };
        case 'SET_VENDEDOR_ID':
            return{
                ...state,
                idVendedor: action.payload
            };
        case 'SET_PERIOD_START':
            return{
                ...state,
                periodoStart: action.payload
            };
        case 'SET_PERIOD_END':
            return{
                ...state,
                periodoEnd: action.payload
            };
        case 'SET_CONSECUTIVO':
            return{
                ...state,
                consecutivo: action.payload
            };
        case 'SET_ESTATUS':
            return{
                ...state,
                estatus: action.payload
            };
        case 'SET_ASCENDENTE':
            return{
                ...state,
                ascendente: action.payload
            };
        case 'SET_DESCENDENTE':
            return{
                ...state,
                descendente: action.payload
            };
        case 'SET_AUTORIZADO':
            return{
                ...state,
                autorizado: action.payload
            };
        case 'SET_PENDIENTE':
            return{
                ...state,
                pendiente: action.payload
            };
        case 'RESET':
            return{
                ...initialState
            };
    
        default:
            return state;
    }
}

const AdminVentasFiltrosContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const data = { state, dispatch };

    return(
        <AdminVentasFiltrosContext.Provider value={data}>
            {children}
        </AdminVentasFiltrosContext.Provider>
    )
}

export default AdminVentasFiltrosContext

export {AdminVentasFiltrosContextProvider}