import styled from "styled-components";

export const MainContainer = styled.dialog`
    width:90%;
    height:18%;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    box-shadow: 1px 1px 1px 1px black;
    border:0;
    position:absolute;
    top:3rem;
    left:-2rem;
    //right:10rem;
    //padding:1rem
`;

export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #CCCCCC;
    padding:1rem;
    

`;

export const Buttom = styled.div`
    width:100%;
    height:20%;
    background-color:#0582D8;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    text-align:center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color:#FFFFFF;
    justify-self: center;
    align-self: center;
`;

export const Input = styled.input`
    border:0;
    width:60%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align:center;
    font-weight: 600;
    color:#121212
`;

export const Texto = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    text-align:center;
    font-weight: 600;
    color:#121212
`;



