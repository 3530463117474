import { BrowserRouter, Route, Routes } from "react-router-dom";
import LeadsHome from "../src/general/layouts/LeadsHome";
import Leads from "../src/leads/layouts/leads";
import Bienvenido from "../src/general/Views/Bienvenido";

// import EstadoNuevo from "./leads/Views/EstadoNuevo"
import EstadoNuevoVista from "./leads/estado-nuevo/EstadoNuevoVista";

// import EstadosCotizadosDetalles from "./leads/Views/EstadosCotizadosDetalles";
import EstadoCotizadoVista from "./leads/estado-cotizado/EstadoCotizadoVista";
// import Dashboard from "./indicadores/Views/Dashboard";
import Main from "./indicadores/Views/Main";
import { IndicadoresContextProvider } from "./indicadores/context/indicadores.context";
import { AdminVentasFiltrosContextProvider } from "./adminVentas/context/adminVentas.filtros.context";
import MainAdminVentas from "./adminVentas/views/MainAdminVentas";
import ConsultaResultado from "./adminVentas/views/ConsultaResultado";
import { LoadingContextProvider } from "./general/context/loading";
import AuthLayout from "./auth/layouts/AuthLayout";
import Login from "./auth/Views/Login";
import { UsernameContextProvider } from "./general/context/username";

function App() {
  return (
    <UsernameContextProvider>
      <LoadingContextProvider>
        <IndicadoresContextProvider>
          <AdminVentasFiltrosContextProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<AuthLayout />}>
                  <Route index element={<Login />} />
                </Route>
                {/* Rutas a leadshome */}
                <Route path="/" element={<LeadsHome />}>
                  <Route index element={<Bienvenido />} />
                </Route>
                {/* Rutas a leads */}
                <Route path="/leads" element={<Leads />}>
                  <Route index element={<EstadoNuevoVista />} />
                  <Route
                    path="/leads/cotizaciones/:id"
                    element={<EstadoCotizadoVista />}
                  />
                </Route>
                {/* Rutas de indicadores */}
                <Route path="/indicadores" element={<Leads />}>
                  <Route index element={<Main />} />
                </Route>
                {/* Routas de ventas */}
                <Route path="/admin-ventas" element={<Leads />}>
                  <Route index element={<MainAdminVentas />} />
                  <Route
                    path="/admin-ventas/consulta-resultado"
                    element={<ConsultaResultado />}
                  />
                </Route>
              </Routes>
            </BrowserRouter>
          </AdminVentasFiltrosContextProvider>
        </IndicadoresContextProvider>
      </LoadingContextProvider>
    </UsernameContextProvider>
  );
}

export default App;
