import React, { useContext, useState } from 'react'
//assets
import logo from '../assets/logo.svg'
import separador from '../assets/separador.svg'
import user from '../assets/user.svg'
import flecha from '../assets/flechaAbajo.svg'
import { MainContainer, PanelContainer, Logo, ImgPanel } from '../styles/headerstyle'
import ModalLoading from './Modals/modalLoading'
import LoadingContext from '../context/loading'
import DropdownMenu from './DropdownMenu'
//style
// import {MainContainer, PanelContainer} from '../components/headerstyle'

export const Header = () => {
  const { state } = useContext(LoadingContext)
  const [ showDropdownMenu,setShowDropdownMenu ] = useState(false)

  const hideDropdownMenu = () => {
    setShowDropdownMenu(false);
  }
  return (
    <>
    <MainContainer>
        <Logo src={logo} alt='logo'/>
        <PanelContainer>
        <ImgPanel src={separador} alt='separador'/>
        <div>
          <div style={{cursor:'pointer'}} onClick={()=>setShowDropdownMenu(!showDropdownMenu)}>
            <ImgPanel src={user} alt='user' style={{marginRight:'1rem'}}/>
            <ImgPanel src={flecha} alt='flecha'/>
          </div>
          <DropdownMenu show={showDropdownMenu} hide={hideDropdownMenu} /> 
        </div>
        </PanelContainer>
    </MainContainer>
      
    {state.loading ? (
      <ModalLoading />
    ) : null}
    </>
  )
}
