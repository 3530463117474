import styled from "styled-components";

export const MainContainer = styled.div`
    //width:30%
`;

export const Table = styled.table`
    min-width: 20%;
    margin-left: 1rem;
    position:relative
    
`;

export const TableHead = styled.table`
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    margin: 2rem 1rem;
`;
export const TableName = styled.td`
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size:1.1;
    line-height:1.5;
    color:#4E4E4E
`;

export const ContainerImgTable = styled.div`
    display:flex;
    flex-direction:row;
    gap:.5rem;
    
`;

export const Tr = styled.div`
`;

export const TdPar = styled.div`
    padding:1rem;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    display:flex;
    flex-direction: row;
    font-weight:600;
    line-height:.9rem;
    font-size: .9rem;
    justify-content: space-between;
    
    
`;

export const TdImpar = styled.div`
    padding:1rem;
    width:100%;
    font-family: 'Montserrat', sans-serif;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight:600;
    line-height:.9rem;
    
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    
`
export const Paginationp = styled.p`
    cursor: pointer;
    font-weight: ${props => props.curr ? 'bold' : 'normal'};
`


