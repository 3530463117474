import React from 'react'
import './styles.scss'



const EncabezadoGraficas = ({oficina, despacho, vendedor}) => {
  return (
   <header id='encabezado-graficas'>
    <table>
            <tr className='header-filtros'>
              <th>Oficina</th>
              <th>Despacho</th>
              <th>Vendedor</th>
            </tr>
            <tr className='campos-filtros'>
              <td>{oficina ? oficina : '-'}</td>
              <td>{despacho ? despacho : '-'}</td>
              <td>{vendedor ? vendedor : '-'}</td>
            </tr>
          </table>
   </header>
  )
}

export default EncabezadoGraficas