import React from 'react'
//styles 
import {MainContainer, Container,Container2,Container3, Title} from '../styles/components/BarraCotizacionesStyled'  
const BarraCotizaciones = ({data}) => {
  if(data[0].value === null){data[0].value = 0};
  if(data[1].value === null){data[1].value = 0};
  if(data[2].value === null){data[2].value = 0};
  return (
    <MainContainer style={{gap:'.5rem'}}>
        <Container>
            <Title>{data[0].value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</Title>
            <Title>{data[0].title}</Title>
        </Container>
        <Container2>
            <Title>{data[1].value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</Title>
            <Title>{data[1].title}</Title>
        </Container2>
     
        <Container3>
            <Title>{data[2].value.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</Title>
            <Title>{data[2].title}</Title>
        </Container3>
    </MainContainer>
  )
}

export default BarraCotizaciones