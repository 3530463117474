// Hooks
import React, { useEffect, useState } from 'react'
// Assets
import lupa from '../../../assets/lupa.svg'
import flecha from '../../../assets/flechaArriba.svg'
import flechaAbajo from '../../../assets/flechaAbajo.svg'
import './style.scss'

// Styles
import { TableName, ContainerImgTable, TableHead, Tr, Table, TdImpar, TdPar, Tbody, Flecha, TdNombre, TdDetalles  } from '../../../styles/Tabla2.styles'
import { Link } from 'react-router-dom'
import Buscador from '../Buscador'
const Tabla2 = ({response}) => {
    const [show,setShow] = useState([])
    const [filtered, setFiltered] = useState([])


    useEffect(()=>{
        let arr = [];
        for (let i =0; i<response.length; i++){
            arr.push(false);
        }
        setShow(arr);
        setFiltered(response)
        console.log('response',response);
    },[response])

    const handleShow = (idx) => {
        const nArr = [...show];
        nArr[idx] = !nArr[idx];
        setShow(nArr);
    }
    

    return (
       <Table>
        <thead>
            <TableHead>
                <TableName>Asociados</TableName>
                <td style={{marginRight:'3rem'}}><Buscador data={response} setFiltered={setFiltered} by={'NombreCompleto'}/></td> 
            </TableHead>
        </thead>
        <Tbody>
        {filtered.map((el, idx) => (
                <>
                <Tr key={idx} onClick={()=> handleShow(idx)} >
                    {idx % 2 === 0 ? 
                        <>
                            <TdPar style={{backgroundColor: show[idx] ? '#EDC729' : '#CCCCCC', color: show[idx] ?'#4E4E4E' :'#838383' }}>{el.NombreCompleto} </TdPar>  
                            <TdPar style={{backgroundColor: show[idx] ? '#EDC729' : '#CCCCCC', color: show[idx] ?'#4E4E4E' :'#838383', display: 'flex', justifyContent: 'center' }}>Leads:&nbsp;<span style={{fontWeight: 'normal'}}>{el.cotizaciones}</span></TdPar>
                            <TdPar style={{justifyContent:'flex-end', backgroundColor:show[idx] ? '#EDC729' : '#CCCCCC'}}><Flecha src= {show[idx] ? flecha :flechaAbajo} alt='flecha'/></TdPar>
                        </>
                    :
                    <>
                        <TdImpar style={{backgroundColor: show[idx] ? '#EDC729' : '#F8F8F8', color: show[idx] ?'#4E4E4E' :'#838383' }}>{el.NombreCompleto}</TdImpar>
                        <TdImpar style={{backgroundColor: show[idx] ? '#EDC729' : '#F8F8F8', color: show[idx] ?'#4E4E4E' :'#838383', display: 'flex', justifyContent: 'center' }}>Leads:&nbsp;<span style={{fontWeight: 'normal'}}>{el.cotizaciones}</span></TdImpar>
                        <TdImpar style={{justifyContent:'flex-end', backgroundColor:show[idx] ? '#EDC729' :'#F8F8F8'}}><Flecha src={show[idx] ? flecha : flechaAbajo} alt='flecha'/></TdImpar>
                    </>
                    } 
                   
                </Tr>
                <div className='Infodiv' style={{display: `${show[idx] ? 'block' : 'none'}`}}>
                    <tr style={{display:'flex', justifyContent:'space-between', padding:'1rem'}}>
                        <TdNombre style={{width: '33.3%'}}>Nombre</TdNombre>
                        <TdNombre style={{width: '33.3%'}}>Teléfono</TdNombre>
                        <TdNombre style={{width: '33.3%'}}>Fecha de nacimiento</TdNombre>
                        
                    </tr>

                    <tr style={{display:'flex', justifyContent:'space-between', padding:'1rem'}}>
                        <TdDetalles  style={{width: '33.3%'}}>{el.NombreCompleto}</TdDetalles>
                        <TdNombre  style={{width: '33.3%'}}>{el.telefono}</TdNombre>
                        <TdDetalles  style={{width: '33.3%'}}>{el.FechaNac}</TdDetalles>
                        
                    </tr>
                    <div className='button-container'>
                      <Link className='button' to={'/leads/cotizaciones/' + el.IDVend}>
                        Ver cotizaciones
                      </Link>
                    </div>
                </div>
                </>

            ))}
           
        </Tbody>
    </Table>
  )
}

export default Tabla2