import React from 'react'

const IconDocument = ({text}) => {
  return (
    <svg width="25" height="31" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 6.375V0H1.125C0.501562 0 0 0.501562 0 1.125V22.875C0 23.4984 0.501562 24 1.125 24H16.875C17.4984 24 18 23.4984 18 22.875V7.5H11.625C11.0063 7.5 10.5 6.99375 10.5 6.375ZM17.6719 4.92188L13.0828 0.328125C12.8719 0.117188 12.5859 0 12.2859 0H12V6H18V5.71406C18 5.41875 17.8828 5.13281 17.6719 4.92188Z" fill="#0582D8"/>
        <text x="3.2855253" y="15.244637" style={{fill:'#ffffff', fontSize:'6px'}}>{text}</text>
    </svg>
  )
}

export default IconDocument