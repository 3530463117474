import React, { useEffect, useState } from 'react';
import { obtenerOficinas } from '../../../estado-nuevo/services/obtener-oficinasFiltro.service';
import { obtenerOficinas as obtenerDespachos } from '../../../estado-nuevo/services/obtener-oficinas.service';
import moment from 'moment';
import './styles.scss';

const ModalBuscar = ({filtrarResultados, hideModal, resetFiltros,setIsFiltered,isFiltered}) => {
  /* 
    Para mejorar este código construir una funcion que por medio de un ciclo
    actualice los valores de cada input
  */

  const {oficina: ofiN, despacho: despN} = JSON.parse(localStorage.getItem('ofi-desp'))
  const [status, setStatus ] = useState('')
  const [fecha, setFecha ] = useState('')
  const [periodoStart, setPeriodoStart ] = useState('')
  const [periodoEnd, setPeriodoEnd ] = useState('')
  const [oficina, setOficina ] = useState(ofiN||'')
  const [despacho, setDespacho ] = useState(despN||'')
  const [disabledDespacho, setDisabledDespacho] = useState(true)
  const [disabledButton, setDisabledButton] = useState(true)

  const [oficinas, setOficinas] = useState([])
  const [despachos, setDespachos] = useState([])
  //console.log(ofiN)
  //console.log(despN)
  const getOficinas = async () => {
    const oficinas = await obtenerOficinas();
    setOficinas(oficinas)
  }

  const getDespachos = async (id) => {
    const despachos = await obtenerDespachos(id);
    setDespachos(despachos)
  }

  const actualizarValorStatus = (e) => {
    setStatus(e.target.value)
  }

  const actualizarValorFecha = (e) => {
    setFecha(e.target.value)
  }

  const handlePeriodChange = (val, type) => {
    const dateFormat = 'YYYY-MM-DD';
    if (type == 'start') {
      setPeriodoStart(val, dateFormat);
    } else if (type == 'end') {
      if (moment(val, dateFormat).isAfter(moment(periodoStart, dateFormat))) {
        setPeriodoEnd(val, dateFormat);
        return;
      }
      setPeriodoEnd(moment(periodoStart, dateFormat).add(1, 'day').format(dateFormat));
    }
  }

  const actualizarValorOficina = (e) => {
    setOficina(e.target.value)
  }

  const actualizarValorDespacho = (e) => {
    setDespacho(e.target.value)
  }

  useEffect(()=>{
    getOficinas() 
  },[]);

  /*useEffect(()=>{
    console.log(despacho)
  },[]);*/

  /*useEffect(()=>{
    if (oficina) {
      getDespachos(Number(oficina));
      setDisabledDespacho(false);
    } else {
      setDisabledDespacho(true)
      setDespacho('');
    }
  },[oficina]);*/

  useEffect(()=>{
    if (status || fecha || (periodoStart && periodoEnd)) {
      setDisabledButton(false)
    }else{
      setDisabledButton(true)
    }
  },[status, fecha, periodoStart, periodoEnd])

  // useEffect(()=>{
  //   setDespacho(despN);
  //   setOficina(ofiN);
  // }
  //   ,[despN, ofiN]
  // )

  // const verifyDate = () => {
  //   // Days
  //   periodoEnd
  //   toDate

  //   // Months
  //   const startMonth = parseInt(periodoStart.split('-')[1])
  //   const endMonth = parseInt(periodoEnd.split('-')[1])

  //   // Years
  //   const startYear = parseInt(periodoStart.split('-')[0])
  //   const endYear = parseInt(periodoEnd.split('-')[0])

  //  /*  if (endYear > startYear) {
  //     if (endMonth >)
  //   } */

  //   if (endYear === startYear) {

  //   }

  // }

  const estados = [
    {value: 'todos', label: 'Todos'},
    {value: 'Cotizado', label: 'Cotizado'},
    {value: 'emitida', label: 'Póliza emitida'},
    {value: 'Poliza pagada', label: 'Póliza pagada'},
  ]

  return (
    <div className='modal estado-cotizado'>
      <div>
        <div className='modal__input-container'>
          <div className='input-label'>
            Status:
          </div>
          <div className='select'>
            <select value={status}  onChange={(e) => actualizarValorStatus(e)} >
              <option></option>
              {estados.map(estados => (
                  <option value={estados.value}>{estados.label}</option>
                )
              )}
            </select>
          </div>
        </div>

        <div className='modal__input-container'>
          <div className='input-label'>
            Fecha:
          </div>
          <div className='input'>
            <input 
            type='date' 
            value={fecha} 
            onChange={(e) => actualizarValorFecha(e)} />
          </div>
        </div>

        <div className='modal__input-container'>
          <div className='input-label'>
            Periodo:
          </div>
          <div className='input input--periodo'>
            <input 
            type='date' 
            value={periodoStart} 
            onChange={(e) => handlePeriodChange(e.target.value, 'start')} />
            <span>&nbsp;-&nbsp;</span>
            <input 
            type='date' 
            value={periodoEnd} 
            onChange={(e) => handlePeriodChange(e.target.value, 'end')} />
          </div>
        </div>

        <div className='modal__input-container'>
          <div className='input-label'>
          Oficina:
          </div>
          <div className='select'>
           
          <select value={oficina} disabled onChange={(e) => actualizarValorOficina(e)} >
              <option disabled></option>
              {
                oficinas.map((item, idx) => 
                  <option key={idx} value={item.IDOfna}>
                    {item.NombreCompleto}
                  </option>
                )
              }
            </select>
          </div>
        </div>

        <div className='modal__input-container'>
          <div className='input-label'>
            Despacho:
          </div>
          <div className='select'>
            
          <select value={despacho} disabled onChange={(e) => actualizarValorDespacho(e)} >
              <option>{despacho}</option>
            </select>
          </div>
        </div>
      </div>

      <button 
        style={{
          background:'none',
          color:'#121212',
          borderTop:'1px solid #CCCCCC'
        }}
        className={`modal__button`}
        onClick={() => {
          setIsFiltered(false)
          resetFiltros();
          hideModal()

        } }
        disabled={!isFiltered}
      >
        Reset { console.log(!isFiltered) }
      </button>

      <button 
        className={`modal__button ${disabledButton ? 'modal__button--disabled' : ''}`}
        onClick={() => {
          setIsFiltered(true)
          filtrarResultados(status, fecha, periodoStart, periodoEnd, oficina, despacho)
          hideModal()
        } }
        disabled={disabledButton}
      >
        Aplicar
      </button>

    </div>
  )
}

export default ModalBuscar