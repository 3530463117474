//hooks
import React, {useEffect, useState} from 'react'

//components
import NavBar from '../components/NavBar'
//styles
import {MainContainer, TableContainer, TituloContainer, NavContainter} from '../styles/EstadosCotizadosDetalles.styles'


// Components
import { ListaDeCotizaciones } from './components/ListaDeCotizaciones'
import DetallesDeLaCotizacion from './components/DetallesDeLaCotizacion'
import InformacionDelAgente from './components/InformacionDelAgente'

// Services 
import { obtenerCotizaciones } from './services/mock-obtener-lista-de-cotizaciones.service'
import { obtenerDetallesDeUnaCotizacionPorId } from './services/mock-obtener-detalles-de-una-cotizacion.service'
import { informacionDelAgente } from './services/obtener-informacion-del-agente.service'
import { Link, useNavigate } from 'react-router-dom'
import BackButton from '../components/BackButton'

const EstadosCotizadosDetalles = () => {
  
    // Obtener la listas de cotizaciones del agente / agencia
    const [listaDeCotizaciones, setListaDeCotizaciones] = useState([])
    const [ mostrarDetalles, setMostrarDetalles ] = useState(false)
    const [ informacionCotizacion, setInformacionCotizacion ] = useState([])
    const [ maxRecords, setMaxRecords ] = useState(1)
    const [ filtros, setFiltros ] = useState()
    const valores = window.location.pathname;
    const id = valores.split("/")[3];

    const obtenerListaDeCotizaciones = async () => {
      const nuevaListaDeCotizaciones = await obtenerCotizaciones(id)
      setListaDeCotizaciones(nuevaListaDeCotizaciones.results)
      setMaxRecords(nuevaListaDeCotizaciones.maxRecords)
    }

    useEffect(() => {

      obtenerListaDeCotizaciones()
      obtenerInformacionDelAgente(id)
      //  obtenerDetallesDeLaCotizacion()
    },[])

    // Obtener la lista de cotizaciones del agente / agencia que fue clickeado
    const obtenerDetallesDeLaCotizacion = (idAgente, idCotizacion) => {
      setMostrarDetalles(false)
      mostrarDetallesDeLaCotizacion(idCotizacion)
      // obtenerInformacionDelAgente(idAgente)
    }

    // Obtener detalles de la cotización
    const mostrarDetallesDeLaCotizacion = async (idCotizacion) => {
      const detalles = await obtenerDetallesDeUnaCotizacionPorId(idCotizacion)
        await setInformacionCotizacion(detalles)
        await setMostrarDetalles(true)
    }

    // Obtener información del agente
    const [informacionAgente, setInformacionAgente] = useState([])
    const [mostrarInformacionAgente, setMostrarInformacionAgente] = useState(false)
    const obtenerInformacionDelAgente = async (id) => {
      const nuevaInformacionAgente = 
        await informacionDelAgente(id)
        await setInformacionAgente(nuevaInformacionAgente)
        await setMostrarInformacionAgente(true)
    }

    const funcionFiltrarResultados = async (status, fecha, periodoStart, periodoEnd, oficina, despacho)=>{
      // let listaFiltrada = await obtenerCotizaciones(id);

      let f = {};
      if(status !== undefined && status !== "")
        f.status = status;

      if(fecha !== undefined && fecha !== "")
        f.fecha = fecha

      if((periodoEnd !== undefined && periodoEnd !== "") && (periodoEnd !== undefined && periodoEnd !== "")){
        f.periodoEnd = periodoEnd;
        f.periodoStart = periodoStart; 
      }

      let currentFiltros = {status,fecha,periodoStart,periodoEnd};

      setFiltros(currentFiltros)

      console.log("Filtros", filtros);

      const nuevaListaDeCotizaciones = await obtenerCotizaciones(id, 1, 10, currentFiltros)
      console.log(nuevaListaDeCotizaciones);
      setListaDeCotizaciones(nuevaListaDeCotizaciones.results);
      setMaxRecords(nuevaListaDeCotizaciones.maxRecords);

      // console.log(listaFiltrada)
      // fecha = fecha.split('-').reverse().join('-')

      // if (status && status !== 'todos') {
      //   listaFiltrada = listaFiltrada.filter(cotizacion => cotizacion.status === status)
      // }

      // if (fecha) {
      //   listaFiltrada = listaFiltrada.filter(cotizacion => cotizacion.date === fecha)
      // }

      // if(periodoStart && periodoEnd){
      //  listaFiltrada = listaFiltrada.filter(cotizacion => {
      //       let cotizacionDate = new Date(cotizacion.date.split('/').reverse().join('-'))
      //       cotizacionDate = cotizacionDate.setDate(cotizacionDate.getDate()+1)

      //       let start = new Date(periodoStart)
      //       start = start.setDate(start.getDate()+1)

      //       let end = new Date(periodoEnd)
      //       end = end.setDate(end.getDate()+1)

      //       return cotizacionDate >= start && cotizacionDate <= end
      //  })
      // }

      // setListaDeCotizaciones(listaFiltrada)

    }

    const navigate = useNavigate()

    const handlePress = ()=>{
      navigate(-1)
    }


    const resetFiltros = () => {


      setMostrarDetalles(false)
      obtenerListaDeCotizaciones()

    }

    
    return (
      <>
     <MainContainer>
      <NavContainter >
        <NavBar/>
        <BackButton
        handlePress={handlePress}
        />
      </NavContainter>
      
      {
        mostrarInformacionAgente &&
        <InformacionDelAgente 
          informacionAgente={ informacionAgente }
        />  
      }

     <TableContainer>

        <ListaDeCotizaciones
          contenidoDeLaTabla={ listaDeCotizaciones }
          obtenerDetallesDeLaCotizacion = { obtenerDetallesDeLaCotizacion }
          funcionFiltrarResultados = { funcionFiltrarResultados }
          idVendedor = {id}
          maxRecords = {maxRecords}
          filtros = {filtros}
          resetFiltros={resetFiltros}
        />

       {
          mostrarDetalles &&
          <DetallesDeLaCotizacion 
            contenidoDeLaTabla={informacionCotizacion}
          />  
        } 

      </TableContainer> 
    </MainContainer>
    </>
  )
}

export default EstadosCotizadosDetalles