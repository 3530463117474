import React, { useState, useEffect, useContext } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Components
import BarraCotizaciones from "../components/BarraCotizaciones";
import GraficaUnica from "../components/graficaUnica";
import NavBar from "../components/NavBar";
import { SideFiltro } from "../components/SideFiltro";
// import GraficasDinamicas from '../../indicadores/components/graficasDinamicas'
// Context
import IndicadoresContext from "../../indicadores/context/indicadores.context";
// Helpers
// import { labels } from '../helpers/charContent'
import AdminVentasFiltrosContext from "../context/adminVentas.filtros.context";
// Styles
import {
  MainContainer,
  ContainerNav,
  ContainerIndicadores,
  Container,
  Container2do,
} from "../styles/views/Main";
import "../styles/styles.scss";
// Services
import { obtenerDashVentas } from "../services/obtener-dash-ventas.service";

const MainAdminVentas = () => {
  const [dataEncabezado, setDataEncabezado] = useState([
    {
      title: "Primas emitidas",
      value: 0,
    },
    {
      title: "Primas Pagadas",
      value: 0,
    },
    {
      title: "Primas Negativas",
      value: 0,
    },
  ]);

  const [dataFooter, setDataFooter] = useState([
    {
      title: "Comisiones por pagar",
      value: 0,
    },
    {
      title: "Comisiones Pagadas",
      value: 0,
    },
    {
      title: "Comisiones Negativas",
      value: 0,
    },
  ]);

  const [graphLabels, setLabels] = useState([]);

  const [graphData, setGraphData] = useState([]);

  const resetFiltrosEP = () => {
    dataEncabezado.forEach((e) =>{
      e.value = 0;
    });
    dataFooter.forEach((e) =>{
      e.value = 0;
    });
  }

  const [filtrosEP, setFiltrosEP] = useState({
    fechaDesde: "2022-01-01",
    fechaHasta: "2022-12-01",
    idVendedor: null,
    idOficina: 1,
    idDespacho: null,
  });

  const getDashInfo = async (info) => {
    resetFiltrosEP();
    console.log("info", info);
    setDataEncabezado([
      {
        title: "Primas Cobradas",
        value: info.infoPrimaComisiones[0].PrimaCobrada,
      },
      {
        title: "Primas Pagadas",
        value: info.infoPrimaComisiones[0].PrimaPagada,
      },
      {
        title: "Primas Negativas",
        value: info.infoPrimaComisiones[0].PrimaNegativa,
      },
    ]);

    setDataFooter([
      {
        title: "Comisiones por pagar",
        value: info.infoPrimaComisiones[0].ComisionesPorPagar,
      },
      {
        title: "Comisiones Pagadas",
        value: info.infoPrimaComisiones[0].ComisionesPagadas,
      },
      {
        title: "Comisiones Negativas",
        value: info.infoPrimaComisiones[0].ComisionesNegativas,
      },
    ]);
  };

  const { state } = useContext(IndicadoresContext);
  const [filtroAplicado, setFiltroAplicado] = useState(false);

  const { dispatch: setFiltrosContext } = useContext(AdminVentasFiltrosContext);

  const filtrar = (data) => {
    const aux = {...filtrosEP};
    aux.fechaDesde = data.fechaDesde;
    aux.fechaHasta = data.fechaHasta;
    aux.idVendedor = data.idVendedor;
    aux.idOficina = data.idOficina;
    aux.idDespacho = data.idDespacho;

    setFiltrosContext({
      type: "SET_DESPACHO_ID",
      payload: data.idDespacho,
    });

    setFiltrosContext({
      type: "SET_VENDEDOR_ID",
      payload: data.idVendedor,
    });

    setFiltrosEP(aux);
    getGraficaData(data);
    setFiltroAplicado(true);
  };

  const resetFiltros = () => {
    setFiltroAplicado(false);
  };

  const navigate = useNavigate();

  const formatLabels = (date1, date2, labels) => {

    const d1 = new Date(date1.split('-').join('/'))
    const d2 = new Date(date2.split('-').join('/'))

    if (d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
      
      const days = labels.filter((d, i)=> ( i >= d1.getDate() - 1 ) && ( i <= d2.getDate() - 1 ))
      
      return days
    }

    return labels;
  }


  const formatData = (date1, date2, data) => {

    const d1 = new Date(date1.split('-').join('/'))
    const d2 = new Date(date2.split('-').join('/'))

    if (d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
      
      const aux = data.map((v, i) => {
        return {
          dia: i+1,
          value: v
        }
      })

      const days = aux.filter((d)=> ( d.dia >= d1.getDate() ) && ( d.dia <= d2.getDate() ))

      return days.map((d)=>d.value)
      
    }

    return data;
  }
  

  const getGraficaData = async (filtros) => {
    const info =  await obtenerDashVentas(filtros, 1);
    getDashInfo(info.data);
    console.log("retrieved data: ", info);
    let labels = info.data.labels;
    let primaGenerada = info.data.primaGenerada;
    let primaPagada = info.data.primaPagada;
    let comisionAcumulada = info.data.comisionAcumulada;


    labels = formatLabels(filtros.fechaDesde, filtros.fechaHasta, labels)

    setLabels(labels);
    setGraphData([
      {
        label: ["Primas generadas"],
        data: formatData(filtros.fechaDesde, filtros.fechaHasta, primaGenerada),
        // data: [25, 50, 45, 60, 25],
        backgroundColor: ["#becee9ff"],
        type: "bar",
        order: 1,
      },
      {
        label: ["Primas pagadas"],
        data: formatData(filtros.fechaDesde, filtros.fechaHasta, primaPagada),
        // data: [35, 65, 25, 30, 20],
        backgroundColor: ["#316cbdff"],
        type: "bar",
        order: 2,
      },
      {
        label: ["Comisiones"],
        data: formatData(filtros.fechaDesde, filtros.fechaHasta, comisionAcumulada),
        // data: [35, 65, 25, 30, 20],
        borderColor: "#b41c1c",
        borderWidth: 2,
        type: "line",
        order: 0,
      },
    ]);

    // graphData[0].data = primaGenerada;
    // graphData[1].data = primaPagada;
    // graphData[2].data = comisionAcumulada;

    console.log("DATA: ", graphLabels, graphData);
  };

  return (
    <MainContainer id="admin-ventas">
      <ContainerNav>
        <NavBar />
      </ContainerNav>
      <Container>
        <ContainerIndicadores>
          <SideFiltro
            filtrar={filtrar}
            filtroAplicado={filtroAplicado}
            resetFiltros={resetFiltros}
          />
        </ContainerIndicadores>
        <Container2do>
          <BarraCotizaciones data={dataEncabezado} />
          <GraficaUnica
            dataset={state.indAdmin ? graphData : null}
            labels={state.indAdmin ? graphLabels : null}
          />
          <BarraCotizaciones data={dataFooter} />
          <div className="button-wrapper">
            <button
              className={`${
                !filtroAplicado ? "indicadores-filtro__btn--disabled" : ""
              }`}
              disabled={!filtroAplicado}
              onClick={() => navigate("/admin-ventas/consulta-resultado")}
            >
              VER DETALLES
            </button>
          </div>
        </Container2do>
      </Container>
    </MainContainer>
  );
};

export default MainAdminVentas;
