import React, { useEffect, useState, useContext } from "react";
//components
import {
  MainContainer,
  ContainerFecha,
  ContainerNav,
  ContainerIndicadores,
} from "../../indicadores/styles/Main";
import Fecha from "../components/Fecha";
import BarraCotizaciones from "../components/BarraCotizaciones";
import NavBar from "../components/NavBar";
import SideFiltro from "../components/SideFiltro";
import ModalEstadisticas from "../components/ModalEstadisticas/ModalEstadisticas";
import EncabezadoGraficas from "../components/EncabezadoGraficas/EncabezadoGraficas";
import GraficasEstadisticas from "../components/GraficasEstadisticas/GraficasEstadisticas";
import GraficasDinamicas from "../components/graficasDinamicas";
// Helpers
import { datasets, labels } from "../helpers/charContent";
import { data, data2, options } from "../helpers/charContent";
import { obtenerOficinas } from "../services/obtener-oficinas.service";
import { obtenerDespachos } from "../services/obtener-despachos.service";
import { obtenerVendedores } from "../services/obtener-vendedores.service";
import { obtenerDash } from "../services/obtener-dash.service";
import IndicadoresContext from "../context/indicadores.context";
//styles
const Main = () => {
  const { state, dispatch } = useContext(IndicadoresContext);

  const [filtroAplicado, setFiltroAplicado] = useState(false);

  const [oficina, setOficina] = useState("");
  const [despacho, setDespacho] = useState("");
  const [vendedor, setVendedor] = useState("");

  const [oficinas, setOficinas] = useState([]);
  const [despachos, setDespachos] = useState([]);
  const [vendedores, setVendedores] = useState([]);

  const [filtrosEP, setFiltrosEP] = useState({
    filtros: false,
    fechaIni: false,
    fechaFin: false,
    oficina: false,
    despacho: false,
    vendedor: false,
    indicadores: {},
  });

  const [dataTotales] = useState({
    cotizaciones: 0,
    polizasEmitidas: 0,
    polizasPagadas: 0,
    primaEmitida: 0,
    primaPagada: 0,
  });
  const [datavs] = useState({
    vs1: 0,
    vs2: 0,
  });

  const [graphData] = useState({
    cotizaciones: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    cobrados: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    emitidos: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  });

  const [indicadoresData, setIndicadoresData] = useState({
    ramo: {
      polizas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Pólizas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Pólizas Pagadas",
        },
      ],
      primas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Primas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Primas Pagadas",
        },
      ],
    },
    forma: {
      polizas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Pólizas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Pólizas Pagadas",
        },
      ],
      primas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Primas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Primas Pagadas",
        },
      ],
    },
    tipo: {
      polizas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Pólizas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Pólizas Pagadas",
        },
      ],
      primas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Primas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Primas Pagadas",
        },
      ],
    },
    aseguradora: {
      polizas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Pólizas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Pólizas Pagadas",
        },
      ],
      primas: [
        {
          backgroundColor: "#becee9ff",
          data: [0],
          label: "Primas Emitidas",
        },
        {
          backgroundColor: "#316cbdff",
          data: [0],
          label: "Primas Pagadas",
        },
      ],
    },
  });

  const [indicadoresLabel, setIndicadoresLabel] = useState({
    ramo: [],
    forma: [],
    tipo: [],
    aseguradora: [],
  });

  const [ramoData, setRamoData] = useState([]);
  const [tipoData, setTipoData] = useState([]);
  const [formaData, setFormaData] = useState([]);
  const [aseguradoraData, setAseguradoraData] = useState([]);

  const resetData = () => {
    indicadoresLabel.ramo = [];
    indicadoresData.ramo.polizas[0].data = [];
    indicadoresData.ramo.polizas[1].data = [];
    indicadoresData.ramo.primas[0].data = [];
    indicadoresData.ramo.primas[1].data = [];
    indicadoresLabel.tipo = [];
    indicadoresData.tipo.polizas[0].data = [];
    indicadoresData.tipo.polizas[1].data = [];
    indicadoresData.tipo.primas[0].data = [];
    indicadoresData.tipo.primas[1].data = [];
    indicadoresLabel.forma = [];
    indicadoresData.forma.polizas[0].data = [];
    indicadoresData.forma.polizas[1].data = [];
    indicadoresData.forma.primas[0].data = [];
    indicadoresData.forma.primas[1].data = [];
    indicadoresLabel.aseguradora = [];
    indicadoresData.aseguradora.polizas[0].data = [];
    indicadoresData.aseguradora.polizas[1].data = [];
    indicadoresData.aseguradora.primas[0].data = [];
    indicadoresData.aseguradora.primas[1].data = [];
    setIndicadoresLabel({ ramo: [], forma: [], tipo: [], aseguradora: [] });
  };

  const getDashInfo = async () => {
    // resetData(); //Este reset hace que las graficas se vacien

    if (!filtrosEP.fechaIni || !filtrosEP.fechaFin) {
      const date = new Date();
      const aux = date.toISOString().split("T")[0].split("-");
      const day = aux[2];
      const month = aux[1];
      const year = aux[0];
      filtrosEP.fechaIni = `${year}-${month}-01`;
      filtrosEP.fechaFin = `${year}-${month}-${day}`;
    }

    const info = await obtenerDash(filtrosEP);
    console.log("info", info);
    dataTotales.cotizaciones = info.dataTab[0].Cotizaciones;
    dataTotales.polizasEmitidas = info.dataTab[0].Emitidas;
    dataTotales.polizasPagadas = info.dataTab[0].Cobradas;
    dataTotales.primaEmitida = info.dataTab[0].Prima_Emitida;
    dataTotales.primaPagada = info.dataTab[0].Prima_Cobrada;
    datavs.vs1 = info.dataVS[0].vs1;
    datavs.vs2 = info.dataVS[0].vs2;
    graphData.cotizaciones = Object.values(info.dataGraph[0][0]);
    graphData.emitidos = Object.values(info.dataGraph[1][0]);
    graphData.cobrados = Object.values(info.dataGraph[2][0]);
    if (info.ramo) {
      //console.log("SE ENCUENTRA");
      setRamoData(info.ramo);
      indicadoresLabel.ramo = [];
      indicadoresData.ramo.polizas[0].data = [];
      indicadoresData.ramo.polizas[1].data = [];
      indicadoresData.ramo.primas[0].data = [];
      indicadoresData.ramo.primas[1].data = [];
      info.ramo.forEach((e, i) => {
        indicadoresLabel.ramo[i] = e.SRamoNombre;
        indicadoresData.ramo.polizas[0].data[i] = e.Emitidas;
        indicadoresData.ramo.polizas[1].data[i] = e.Cobradas;
        indicadoresData.ramo.primas[0].data[i] = e.Prima_Emitida;
        indicadoresData.ramo.primas[1].data[i] = e.Prima_Cobrada;
      });
    }

    if (info.tipo) {
      setTipoData(info.tipo);
      indicadoresLabel.tipo = [];
      indicadoresData.tipo.polizas[0].data = [];
      indicadoresData.tipo.polizas[1].data = [];
      indicadoresData.tipo.primas[0].data = [];
      indicadoresData.tipo.primas[1].data = [];
      info.tipo.forEach((e, i) => {
        indicadoresLabel.tipo[i] = e.FPago;
        indicadoresData.tipo.polizas[0].data[i] = e.Emitidas;
        indicadoresData.tipo.polizas[1].data[i] = e.Cobradas;
        indicadoresData.tipo.primas[0].data[i] = e.Prima_Emitida;
        indicadoresData.tipo.primas[1].data[i] = e.Prima_Cobrada;
      });
    }

    if (info.forma) {
      setFormaData(info.forma);
      indicadoresLabel.forma = [];
      indicadoresData.forma.polizas[0].data = [];
      indicadoresData.forma.polizas[1].data = [];
      indicadoresData.forma.primas[0].data = [];
      indicadoresData.forma.primas[1].data = [];
      info.forma.forEach((e, i) => {
        indicadoresLabel.forma[i] = e.TipoDePago;
        indicadoresData.forma.polizas[0].data[i] = e.Emitidas;
        indicadoresData.forma.polizas[1].data[i] = e.Cobradas;
        indicadoresData.forma.primas[0].data[i] = e.Prima_Emitida;
        indicadoresData.forma.primas[1].data[i] = e.Prima_Cobrada;
      });
    }

    if (info.aseguradora) {
      setAseguradoraData(info.aseguradora);
      indicadoresLabel.aseguradora = [];
        indicadoresData.aseguradora.polizas[0].data = [];
        indicadoresData.aseguradora.polizas[1].data = [];
        indicadoresData.aseguradora.primas[0].data = [];
        indicadoresData.aseguradora.primas[1].data = [];
      info.aseguradora.forEach((e, i) => {
        indicadoresLabel.aseguradora[i] = e.CiaNombre;
        indicadoresData.aseguradora.polizas[0].data[i] = e.Emitidas;
        indicadoresData.aseguradora.polizas[1].data[i] = e.Cobradas;
        indicadoresData.aseguradora.primas[0].data[i] = e.Prima_Emitida;
        indicadoresData.aseguradora.primas[1].data[i] = e.Prima_Cobrada;
      });
    }
  };

  useEffect(() => {
    getDashInfo();
    getData();
  }, [filtrosEP]);

  const [showModalEstadisticas, setShowModalEstadisticas] = useState({
    indicador: null,
    show: false,
  });

  const showEstadisticas = (indicador) => {
    let data = [];
    switch (indicador) {
      case "Ramo/Subramo":
        data = ramoData;
        break;
      case "Forma de pago":
        data = formaData;
        break;
      case "Periodo de pago":
        data = tipoData;
        break;
      case "Aseguradora":
        data = aseguradoraData;
        break;
      default:
        break;
    }
    setShowModalEstadisticas({
      indicador,
      show: true,
      data: data,
    });
  };

  const hideEstadisticas = () => {
    setShowModalEstadisticas({
      ...showModalEstadisticas,
      show: false,
    });
  };

  const getData = async () => {
    const oficinas = await obtenerOficinas();
    setOficinas(oficinas);
    const despachos = await obtenerDespachos(0);
    setDespachos(despachos);
  };

  const getVendedores = async () => {
    let vendedores = [];
    if (despachos.length > 0) {
      for (const d of despachos) {
        const v = await obtenerVendedores(d.id);
        vendedores = [...vendedores, ...v];
      }
      setVendedores(vendedores);
    }
  };

  useEffect(() => {
    getVendedores();
  }, [despachos]);

  const filtrar = (data) => {
    const oficina = oficinas.find((of) => of.IDOfna === Number(data.oficina));
    if (oficina) {
      filtrosEP.oficina = oficina.IDOfna;
      setOficina(oficina.NombreCompleto);
    } else {
      filtrosEP.oficina = false;
      setOficina("");
    }
    const despacho = despachos.find((des) => des.id === Number(data.despacho));
    if (despacho) {
      filtrosEP.despacho = despacho.id;
      setDespacho(despacho.nombre);
    } else {
      filtrosEP.despacho = false;
      setDespacho("");
    }
    const vendedor = vendedores.find((ven) => ven.IDVend === Number(data.vendedor));
    if (vendedor) {
      filtrosEP.vendedor = vendedor.IDVend;
      setVendedor(vendedor.NombreCompleto);
    } else {
      filtrosEP.vendedor = false;
      setVendedor("");
    }
    if (filtrosEP.indicadores) {
      if (data.indicador.Aseguradora) {
        filtrosEP.indicadores.aseguradora = true;
      } else {
        filtrosEP.indicadores.aseguradora = false;
      }
      if (data.indicador["Ramo/Subramo"]) {
        filtrosEP.indicadores.ramo = true;
      } else {
        filtrosEP.indicadores.ramo = false;
      }
      if (data.indicador["Periodo de pago"]) {
        filtrosEP.indicadores.tipo = true;
      } else {
        filtrosEP.indicadores.tipo = false;
      }
      if (data.indicador["Tipo de pago"]) {
        filtrosEP.indicadores.forma = true;
      } else {
        filtrosEP.indicadores.forma = false;
      }
    }
    filtrosEP.fechaIni = data.fechaIni;
    filtrosEP.fechaFin = data.fechaFin;
    filtrosEP.filtros = true;
    getDashInfo();
    setFiltroAplicado(true);
  };
  //console.log(filtrosEP);

  const resetFiltros = () => {
    // console.log('RESEEEEEEEEEET')
    setOficina("");
    setDespacho("");
    setVendedor("");
    setFiltroAplicado(false);
    setFiltrosEP({
      filtros: false,
      fechaIni: false,
      fechaFin: false,
      oficina: false,
      despacho: false,
      vendedor: false,
      indicadores: {},
    });

  };


  const handeRandom = () => {
    const dayStart = state.periodoStart.split("-");
    const dayEnd = state.periodoEnd.split("-");
    const totalStart = parseInt(dayStart[1]) * parseInt(dayStart[2]);
    const totalEnd = parseInt(dayEnd[1]) * parseInt(dayEnd[2]);
    if (totalEnd - totalStart > 30) {
      // se deben devolver meses
    } else {
      // sino del 1 al 30
      if (dayEnd < dayStart) {
      } else {
      }
    }
  };

  const isAnyIndicatorSelected = () => {
    const selected = Object.keys(state.indicadores).filter(
      (k) => state.indicadores[k]
    );
    //console.log(selected);
    return selected.length > 0;
  };

  // console.log({indicadoresData})

  return (
    <MainContainer>
      <ContainerNav>
        <NavBar />
      </ContainerNav>
      {/* <ContainerFecha>{!filtroAplicado && <Fecha />}</ContainerFecha> */}
      {(!filtroAplicado || !isAnyIndicatorSelected()) && (
        <BarraCotizaciones data={dataTotales} />
      )}
      <ContainerIndicadores>
        <SideFiltro
          filtroAplicado={filtroAplicado}
          resetFiltros={resetFiltros}
          filtrar={filtrar}
          oficinas={oficinas}
        />
        <div className="chart-layout">
          {(!filtroAplicado || !isAnyIndicatorSelected()) && (
            <GraficasEstadisticas datavs={datavs} graphData={graphData} />
          )}

          {filtroAplicado && isAnyIndicatorSelected() && (
            <>
              <EncabezadoGraficas
                oficina={oficina}
                despacho={despacho}
                vendedor={vendedor}
              />
              {Object.entries(state.indicadores).map(([k, v]) => {
                if (v === true) {
                  if (k === "Ramo/Subramo") {
                    return (
                      <GraficasDinamicas
                        dataset={indicadoresData.ramo}
                        labels={indicadoresLabel.ramo}
                        title={k}
                        showEstadisticas={showEstadisticas}
                      />
                    );
                  }

                  if (k === "Forma de pago") {
                    return (
                      <GraficasDinamicas
                        dataset={indicadoresData.forma}
                        labels={indicadoresLabel.forma}
                        title={k}
                        showEstadisticas={showEstadisticas}
                      />
                    );
                  }

                  if (k === "Periodo de pago") {
                    return (
                      <GraficasDinamicas
                        dataset={indicadoresData.tipo}
                        labels={indicadoresLabel.tipo}
                        title={k}
                        showEstadisticas={showEstadisticas}
                      />
                    );
                  }

                  if (k === "Aseguradora") {
                    return (
                      <GraficasDinamicas
                        dataset={indicadoresData.aseguradora}
                        labels={indicadoresLabel.aseguradora}
                        title={k}
                        showEstadisticas={showEstadisticas}
                      />
                    );
                  }
                }
              })}

              {/* <GraficasDinamicas dataset={datasets} labels={labels} title={"Polizas emitidas vs Polizas cobradas"} showEstadisticas={showEstadisticas} /> */}
            </>
          )}
        </div>
      </ContainerIndicadores>
      {showModalEstadisticas.show && (
        <ModalEstadisticas
          indicador={showModalEstadisticas.indicador}
          datos={showModalEstadisticas.data}
          hideEstadisticas={hideEstadisticas}
        />
      )}
    </MainContainer>
  );
};

export default Main;
