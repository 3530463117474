import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:#FFFFFF;
    gap:1rem;
    margin-top: 1rem;
`;

export const Container = styled.div `
    width:20%;
    background-color:#FDF2BA;
    border-top-left-radius: 16px;
    min-height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Container2 = styled.div `
    width:20%;
    background-color:#FDF2BA;
    min-height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
`;
export const Container3 = styled.div `
    width:20%;
    background-color:#FDF2BA;
    border-top-right-radius: 16px;
    min-height: 112px;
    display: flex;
    flex-direction: column;
    justify-content: center;

`;

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight:700;
    font-size:18px;
    line-height:2rem;
    text-align: center;
    color:#121212
    `;