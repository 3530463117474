import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import {Bar} from 'react-chartjs-2'
// Custom Hooks
import useChartContent from '../../customHooks/useChartContent';
// Helpers
import { handleScroll } from '../../helpers/handleScroll';
// Styles
import '../GraficasEstadisticas/styles.scss'
import BotonCambio from './boton-cambio';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const GraficasDinamicas = ({dataset, labels, title, showEstadisticas}) => {
  // Option first table
  const {options: optC, data: dataState} = useChartContent(dataset.polizas, labels, false)
  const [options, setOptions] = useState()

  const data =  _.cloneDeep(dataState)

  useEffect(() => {
    setOptions(optC)
  }, [optC])

  // Option second table
  const {options: optC2, data: dataState2} = useChartContent(dataset.primas, labels)
  const [options2, setOptions2] = useState()

  const data2 =  _.cloneDeep(dataState2)


  useEffect(() => {
    setOptions2(optC2)
  }, [optC2])

  let graficaNom;

  switch (title) {
    case "Ramo/Subramo":
      graficaNom = "ramoGrafica";
      break;
    case "Forma de pago":
      graficaNom = "formaGrafica";
      break;
    case "Periodo de pago":
      graficaNom = "periodoGrafica";
      break;
    case "Aseguradora":
      graficaNom = "aseguradoraGrafica";
      break;
    default:
      break;
  }

  return (
    <div className='graficas-estadisticas-wrapper'>
      <div className='bg-color-white'>
            <h3>{title}</h3>
          <div className={graficaNom}>
            <div className='graficas-estadisticas'>
                <div className='grafica' >
                    <Bar onWheel={(e) => handleScroll(e, options, setOptions, data)} options={options} data={data} />
                    {labels.length > 5 ? (
                      <>
                        <BotonCambio left={true} setOptions={setOptions} data={data} />
                        <BotonCambio right={true} setOptions={setOptions} data={data} />
                      </>
                    ) : null}
                </div>
                <div className='grafica' >
                    <Bar onWheel={(e) => handleScroll(e, options2, setOptions2, data2)} options={options2} data={data2} />
                    {labels.length > 5 ? (
                      <>
                        <BotonCambio left={true} options={options2} setOptions={setOptions2} data={data2} />
                        <BotonCambio right={true} options={options2} setOptions={setOptions2} data={data2}  />
                      </>
                    ) : null}
                </div>
            </div>
          </div>
          <div className='button-wrapper'>
            <button onClick={()=> showEstadisticas(title, dataset)}>VER ESTADÍSTICAS</button>
          </div>
      </div>
    </div>
  )
};
export default GraficasDinamicas;