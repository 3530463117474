import React from 'react'
import { Link } from 'react-router-dom'
//assets
import flecha from '../assets/flechaIzquierda.svg'
//styles
import {MainContainer, Img, Enlace} from '../styles/components/RegresarStyled'
const Regresar = ({handleAction}) => {
  return (
    <MainContainer>
                <Img src={flecha} alt='back'/>
                {
                  handleAction ? (
                        <span onClick={handleAction} style={{textDecoration: 'underline', color:'#4E4E4E', fontFamily:'Montserrat, sans-serif', fontWeight:'700'}}>Regresar</span>
                  ) : (
                        <Link to='/admin-ventas' style={{textDecoration: 'underline', color:'#4E4E4E', fontFamily:'Montserrat, sans-serif', fontWeight:'700'}}>Regresar</Link>
                    )

                }
    </MainContainer>
  )
}

export default Regresar