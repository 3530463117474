import React from "react";
import IconDocument from "./IconDocument";
import "./styles.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import tableToXML from "../../helpers/tableToXML";
const ModalEstadisticas = ({ hideEstadisticas, indicador, datos }) => {
  console.log(datos);
  console.log(indicador);
  const data = datos;
  let nomIndicador;
  let indicadorNom;
  let graficaNom;
  switch (indicador) {
    case "Ramo/Subramo":
      indicadorNom = "ramo";
      graficaNom = "ramoGrafica";
      datos.forEach((e) => {
        nomIndicador = e.SRamoNombre;
        e.nombre = nomIndicador;
      });
      break;
    case "Forma de pago":
      datos.forEach((e) => {
        indicadorNom = "forma";
        graficaNom = "formaGrafica";
        nomIndicador = e.FPago;
        e.nombre = nomIndicador;
      });
      break;
    case "Periodo de pago":
      datos.forEach((e) => {
        indicadorNom = "periodo";
        graficaNom = "periodoGrafica";
        nomIndicador = e.FPago;
        e.nombre = nomIndicador;
      });
      break;
    case "Aseguradora":
      datos.forEach((e) => {
        indicadorNom = "aseguradora";
        graficaNom = "aseguradoraGrafica";
        nomIndicador = e.CiaNombre;
        e.nombre = nomIndicador;
      });
      break;
    default:
      break;
  }
  const downloadPDF = async () => {
    const pdf = new jsPDF("landscape");
    let saveTable;
    let saveGraph;
    await html2canvas(document.querySelector("." + indicadorNom)).then(
      (canvas) => {
        saveTable = canvas.toDataURL("image/jpeg");
        console.log(saveTable.replace("data:image/jpeg;base64,", ""));
      }
    );
    await html2canvas(document.querySelector("." + graficaNom)).then(
      (canvas) => {
        saveGraph = canvas.toDataURL("image/jpeg");
      }
    );
    pdf.addImage(saveTable, "PNG", 10, 10, 125, 30, "alias1", "SLOW");
    pdf.addImage(saveGraph, "PNG", 140, 10, 150, 35, "alias2", "SLOW");
    pdf.save("table.pdf");
  };

  const downloadXML = async () => {
    let xmlDoc = tableToXML(data, indicador);
    const element = document.createElement("a");
    const blob = new Blob([xmlDoc], { type: "text/plain" });
    element.href = URL.createObjectURL(blob);
    element.download = "data.xml";
    document.body.appendChild(element);
    element.click();
  };

  const moneyFormat = (input) => {
    if(typeof input === 'string'){
      input = input.replace(',', '')
    }
    const result = (+input).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
    return result
  } 

  return (
    <div className="modal-estadisticas-wrapper ">
      <div className="modal-estadisticas">
        <button onClick={() => hideEstadisticas()} className="close-button">
          X
        </button>
        <header className="modal-estadisticas__header">
          <h3>Indicador: {indicador}</h3>
        </header>
        <div className="modal-estadisticas__body">
          <div className={indicadorNom}>
            <table className="modal-estadisticas-table">
              <tr className="modal-estadisticas-table__header">
                <th>{indicador}</th>
                <th>Pólizas emitidas</th>
                <th>Pólizas pagadas</th>
                <th>Prima emitida</th>
                <th>Prima pagada</th>
              </tr>
              {data.map((data, i) => {
                return (
                  <tr
                    className={
                      i % 2 === 0
                        ? "modal-estadisticas-table__body modal-estadisticas-table__body--gray"
                        : "modal-estadisticas-table__body"
                    }
                  >
                    <td>{data.nombre}</td>
                    <td>{data.Emitidas}</td>
                    <td>{data.Cobradas}</td>
                    <td>{moneyFormat(data.Prima_Emitida)}</td>
                    <td>{moneyFormat(data.Prima_Cobrada)}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <footer className="modal-estadisticas__footer">
          <div>
            <h3>Descarga</h3>
          </div>
          <button onClick={() => downloadPDF()}>
            <IconDocument text={"PDF"} />
          </button>
          <button onClick={() => downloadXML()}>
            <IconDocument text={"XML"} />
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalEstadisticas;
