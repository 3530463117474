// React
import React from "react";
// Helpers
import { handleClick } from "../../helpers/handleClick";
// Assets
import FlechaDespliegue from "../../assets/flecha-select";
// Styles
import { BotonStyle } from "../../styles/componets/graficasDinamicas/boton-cambio.styles";

const BotonCambio = ({left, right, options, setOptions, data}) => {
  return(
    <BotonStyle left={left} right={right} onClick={() => handleClick(options, setOptions, data, right, left)} >
      <FlechaDespliegue />
    </BotonStyle>
  )
};
export default BotonCambio;