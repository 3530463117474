// export const obtenerCotizaciones = async () => {
//   const apiUrl = "https://backendsicas.gpmass.mx/api/get-poliza"
//   const body = JSON.stringify({
//     "dataConfig":{
//       "TypeFormat":"JSON",
//       "KeyProcess":"REPORT",
//       "KeyCode":"H03117",
//       "Page":1,
//       "ItemForPage":50,
//       "InfoSort": "DatDocumentos.IDDocto"
//     },
//     "credentials": {
//       "username": "T0do_8ienWoow",
//       "password": "T0doW@ow202O"
//     }
//   })
//   const { Data }  = await 
//     fetch(apiUrl, {
//       method: 'post',
//       body: body,
//       headers: {
//         "Content-type": "Application/json"
//       }
//     })
//     .then( response => response.json() )
//     console.log(Data)
//     return Data
// }


// export const obtenerCotizaciones = () => {
//   return [
//     {
//       id: 1,
//       idCotizacion: 1,
//       name: 'Enrique Gómez',
//       status: 'Pagada',
//       date: '01/01/2021',
//       selected:false
//     },
//     {
//       id: 2,
//       idCotizacion: 2,
//       name: 'Enrique Gómez',
//       status: 'Póliza emitida',
//       date: '01/01/2021',
//       selected:false
//     },
//     {
//       id: 3,
//       idCotizacion: 3,
//       name: 'Enrique Gómez',
//       status: 'Cotizado',
//       date: '01/01/2021',
//       selected:false
//     }
//   ]
// }


// export const obtenerCotizaciones = async (id = 0) => {
//   const apiUrl = `https://backendsicas.gpmass.mx/api/get-cotizaciones-vendedor/${id}`;
//   let resultado = await fetch(apiUrl, {
//     method: "get",
//   })
//     .then((response) => response.json())
//     .then(async ({ data }) => {
//       const cotizaciones = data.cotizaciones;
//       let results = [];

//       for (let i = 0; i < cotizaciones.length; i++) {

//         let estado = '';
//         let fechaCotizacion = '';
//         if (cotizaciones[i].idPoliza != null && cotizaciones[i].idPoliza !== undefined) {
//           let polizaRes = await obtenerPoliza(cotizaciones[i].idPoliza);
//           console.log(polizaRes);
//           estado = (polizaRes !== undefined ? ((polizaRes.StatusPago !== "") === true ? polizaRes.StatusPago : "") : "");

//           const [fecha, hora, uh] = polizaRes.FCaptura.split(' ');
//           console.log(fecha, hora, uh)
//           let tiempo = hora;
//           if(uh === "p."){
//             tiempo = (parseInt(tiempo.slice(0,2)) + 12) + tiempo.slice(2,8);
//             console.log(tiempo);
//           }

//           fechaCotizacion = fecha;

//           switch (estado) {
//             case 'Pagado':
//               estado = 'Poliza pagada'
//               break;
//             case 'Emitida':
//               estado = 'emitida'
//               break;
//             default: estado = 'Cotizado'
//           }
//         }
//         else{
//           estado = 'Cotizado';

//           fechaCotizacion = cotizaciones[i].fecha_cotizacion;

//           let date = new Date(fechaCotizacion);

//           let day = date.getDate();
//           let month = date.getMonth();
//           let year = date.getFullYear();
//           fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + (month < 10 ? "0" + month : month) + "/" + year;
        
//         }
        
        

//         results.push({
//           id: i + 1,
//           idCotizacion: cotizaciones[i].id,
//           name: cotizaciones[i].nombre,
//           status: estado,
//           date: fechaCotizacion,
//           selected: false
//         });

//       }
//       return results;
//     })
//     .catch((err) => console.error("ERROR: ", err.message));

//   // console.log(resultado);
//   return resultado;
// };

export const obtenerCotizaciones = async (id = 0, page = 1, noItems = 10, filtros={}) => {

  const apiUrl = `https://backendsicas.gpmass.mx/api/find-cotizaciones-vendedor`;
  let resultado = await fetch(apiUrl, {
    method: "post",
    body: JSON.stringify({
      idVendedor: id,
      page: page,
      noItems: noItems,
      ...filtros
    }),
    headers: {
      "content-type": "application/json"
    }
  })
    .then((response) => response.json())
    .then(async ({ data, info }) => {
      const cotizaciones = data.cotizaciones;
      let results = [];

      for (let i = 0; i < cotizaciones.length; i++) {

        let estado = '';
        let fechaCotizacion = '';
        if (cotizaciones[i].idPoliza != null && cotizaciones[i].idPoliza !== undefined && cotizaciones[i].idPoliza !== '0') {
          console.log("idPoliza", cotizaciones[i].idPoliza);
          let polizaRes = await obtenerPoliza(cotizaciones[i].idPoliza);
          console.log(polizaRes);
          estado = cotizaciones[i].estado;
          // estado = (polizaRes !== undefined ? ((polizaRes.StatusPago !== "") === true ? polizaRes.StatusPago : "") : "");

          if(polizaRes?.FCaptura != undefined){
            const [fecha, hora, uh] = polizaRes.FCaptura.split(' ');
            console.log(fecha, hora, uh)
            let tiempo = hora;
            if(uh === "p."){
              tiempo = (parseInt(tiempo.slice(0,2)) + 12) + tiempo.slice(2,8);
              console.log(tiempo);
            }
  
            fechaCotizacion = fecha;
          }
          else{
            fechaCotizacion = '';
          }
          

          // switch (estado) {
          //   case 'pagado':
          //     estado = 'Poliza pagada'
          //     break;
          //   case 'emitida':
          //     estado = 'emitida'
          //     break;
          //   default: estado = 'Cotizado'
          // }

          switch (estado) {
            case 'pagado':{
              estado = 'Poliza pagada';
              fechaCotizacion = cotizaciones[i].fecha_pago;
              console.log("ESTADO:", estado, fechaCotizacion);

              let date = new Date(fechaCotizacion);

              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
              break;
            }
            case 'emitido':{
              estado = 'emitida';
              fechaCotizacion = cotizaciones[i].fecha_emision;
              console.log("ESTADO:", estado, fechaCotizacion);

              let date = new Date(fechaCotizacion);

              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
              break;
            }
            default: {
              estado = 'Cotizado';
              fechaCotizacion = cotizaciones[i].fecha_emision;

              console.log("ESTADO:", estado, fechaCotizacion);

              let date = new Date(fechaCotizacion);

              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
            }
          }
        }
        else{

          estado = cotizaciones[i].estado;
          switch (estado) {
            case 'pagado':{
              estado = 'Poliza pagada'
              fechaCotizacion = (cotizaciones[i].fecha_pago).slice(0,10);

              console.log("ESTADO:", estado, fechaCotizacion);

              let date = new Date(fechaCotizacion);

              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
              break;
            }
            case 'emitido':{
              estado = 'emitida'
              fechaCotizacion = (cotizaciones[i].fecha_emision).slice(0,10);
              
              console.log("ESTADO:", estado, fechaCotizacion);

              let date = new Date(fechaCotizacion);
              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
            
              break;
            }
            default: {
              estado = 'Cotizado'
              fechaCotizacion = cotizaciones[i].fecha_cotizacion;

              console.log("ESTADO:", estado, fechaCotizacion);
              
              let date = new Date(fechaCotizacion);
              let day = date.getDate();
              let month = date.getMonth();
              let year = date.getFullYear();
              fechaCotizacion = (day < 10 ? "0" + day : day) + "/" + ((month+1) < 10 ? "0" + (month+1) : (month+1)) + "/" + year;
            }
          }
        }

        results.push({
          id: i + 1,
          idCotizacion: cotizaciones[i].id,
          name: cotizaciones[i].nombre,
          status: estado,
          date: fechaCotizacion,
          selected: false
        });

      }
      return {results, maxRecords: info.maxRecords};
    })
    .catch((err) => console.error("ERROR: ", err.message));

  // console.log(resultado);
  return resultado;
};

export const obtenerPoliza = async (idDocto) => {
  const apiUrl = "https://backendsicas.gpmass.mx/api/find-polizas";
  let resultado = await fetch(apiUrl, {
    method: "post",
    body: JSON.stringify({
      idVista: "poliza_view",
      idDocto: idDocto
    }),
    headers: {
      "Content-type": "Application/json"
    }
  })
    .then((response) => response.json())
    .then(({ data }) => data.length > 0 ? data[0]: undefined)
    .catch((err) => console.error("ERROR: ", err.message));
    // console.log("POLIZA RECIBIDA: ", resultado);
  return resultado;
}