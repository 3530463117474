import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import {GridContainer, Title, Text, Total, Pagination, Paginationp} from '../styles/components/TablaConsultaStyled'
import flecha from '../assets/flechaDerecha.svg'
import axios from 'axios'

const TablaConsulta = ({data, handleClick}) => {

  const [despachos, setDespachos] = useState([])
  const base = 'https://backendsicas.gpmass.mx/api';
  // const base = 'http://localhost:3000/api';

  useEffect(()=> {
      const arr = []
      for(let i =0; i<100;i++){
        arr.push({
          userId: i + 1,
          title: `Despacho ${i+1}`,
          primasCobradas: 100,
          comisionesGeneradas:100,
        })
      }

      //setDespachos(arr);


  },[])


  const traerInformacion = async () => {
    const res = await axios.post(base+'/query-results', 
    {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor,
      periodoStart: data.periodoStart,
      periodoEnd: data.periodoEnd,
      type: 'despacho',
      page: 1,
      noItems:25
    },
    {headers: {
      'content-type': 'application/json'
    }}
    );
    if (res?.status === 200 && res?.data?.data?.length) {
      setDespachos([...res.data.data])
      setTotalItems(res.data.info.MaxRecords)
    }
    console.log('traerDespachos', res);
  };

  useEffect(() => {
    traerInformacion();
  }, [data]);

  const moneyFormat = (input) => {
    if(typeof input === 'string'){
      input = input.replace(',', '')
    }
    const result = (+input).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
    return result
  } 

  const [primasComisiones, setPrimasComisiones] = useState({
    prima: 0,
    comisiones: 0,
  })
  const [despachoSect, setDespachoSect] = useState([])
  const [pages, setPages] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    let prima = 0
    let comisiones = 0
    despachos?.forEach(el => {
      prima += el.primasCobradas
      comisiones += el.comisionesGeneradas
    })
    let dollarUSLocale = Intl.NumberFormat('en-US');
    // prima = prima.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // comisiones = comisiones.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // forma
    setPrimasComisiones({prima: dollarUSLocale.format(prima.toString()), comisiones: dollarUSLocale.format(comisiones.toString())})
  }, [despachos])

  useEffect(() => {
    if (totalItems > 1) {
      let pag = Math.floor(totalItems / 25)
      if (totalItems % 25 > 0) pag += 1
      let res = []
      let arrPag = []
      for (let i = 0; i < pag; i++) {
        let count = [];
        let curr = i*25
        for (let j = curr; j < (i+1)*25 ; j++) {
          if (despachos[j]) count.push(despachos[j])
        }
        res.push(count)
        arrPag.push(i)
      }
      setDespachoSect(res)
      setPages(arrPag)
      setLastPage(pages[arrPag.length-1])
    } else {
      setDespachoSect([despachos])
      setPages([1])
      setLastPage(1)
    }
    
  }, [despachos])
  return (
    <>
    <GridContainer>
        <Title>Despacho</Title>
        <Title>Primas cobradas</Title>
        <Title>Comisiones generadas</Title>
        
        {despachoSect[currentPage]?.map((item, idx) => (
            <>
          
            <Text onClick={() => handleClick(true, item.userId)} par={idx%2 > 0 ? false : true}>{item.title}</Text>
            <Text onClick={() => handleClick(true, item.userId)} par={idx%2 > 0 ? false : true}>{moneyFormat(item.primasCobradas)}</Text>
            <Text onClick={() => handleClick(true, item.userId)} par={idx%2 > 0 ? false : true}>{moneyFormat(item.comisionesGeneradas)}</Text>
            
            </>
        )) }

        <Total >Total</Total>
        <Total>{moneyFormat(primasComisiones.prima)}</Total>
        <Total>{moneyFormat(primasComisiones.comisiones)}</Total>
        
    </GridContainer>
    
    <Pagination style={{margin:'35px 0 20px 0'}}>
          <p>Página {currentPage + 1} de {pages.length}</p>
        </Pagination>
        <Pagination style={{margin:'0 0 35px 0'}}>
          
          {/*pages?.map(el => (
            <Paginationp curr={currentPage === el} onClick={() => setCurrentPage(el)}>{el+1}</Paginationp>
          ))*/}

          <button style={{background: 'none', border:'none', cursor:'pointer'}} onClick={()=>{
            if(currentPage === 0) return
            setCurrentPage(currentPage - 1)
          }}><img style={{    transform: 'rotateY(190deg)'}} src={flecha} alt='flecha'/></button>

          { currentPage !== 0 &&
            <>
              <Paginationp curr={currentPage === 0} onClick={() => setCurrentPage(0)}>{1}</Paginationp> 
              <span>...</span>
            </>
          }
          { currentPage - 1 > 0 &&
            pages?.map(p => {
              if (p > currentPage - 3 && p < currentPage && p !== 0) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => setCurrentPage(p)}>{p + 1}</Paginationp> 
                  </>
                )
              }
            })
          }
          <Paginationp curr={currentPage === currentPage} onClick={() => false}>{currentPage + 1}</Paginationp>
          
          { currentPage < lastPage &&
            pages?.map(p => {
              if (p > currentPage && p < currentPage + 3 && p !== lastPage) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => setCurrentPage(p)}>{p + 1}</Paginationp> 
                  </>
                )
              }
            })
          }

          { currentPage !== pages[pages.length - 1] &&
            <>
              <span>...</span>
              <Paginationp curr={currentPage === pages[pages.length - 1]} onClick={() => {
                if(currentPage < (lastPage-1)){
                  setCurrentPage(pages[pages.length - 1])
                }
                }}>{pages.length}</Paginationp>
            </>
          }
          <button style={{background: 'none', border:'none', cursor:'pointer'}} onClick={()=>{
            
            if(currentPage < (lastPage - 1)){
              setCurrentPage(currentPage + 1)
            }
          }}><img src={flecha} alt='flecha'/></button>

        </Pagination>
    
    </>
  )
}

export default TablaConsulta