export const obtenerDetalles = async (id) => {
 //console.log(id);
  const apiUrl = "https://backendsicas.gpmass.mx/api/getVendedores";
  const body = JSON.stringify({
    idDespacho: id,
  });
  let detalles = [];
  await fetch(apiUrl, {
    method: "post",
    body: body,
    headers: {
      "Content-type": "Application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {

      let vendedores = data.data;
      let response = [];
      for(let i = 0; i< vendedores.length; i++){
        let telefonoSeleccionado = "";
        if(vendedores[i].telefono == null){
          for(let u = 0; u < 6; u++){
  
            let telefono = "";
            if(u === 0){
              telefono = "telefono";
            }
            else{
              telefono = "Telefono"+u;
            }
            // console.log("VENDEDORES: ", telefono, vendedores[i][telefono]);
    
            if(vendedores[i][telefono] !== undefined && vendedores[i][telefono] !== null && vendedores[i][telefono] !== 'null'){
              telefonoSeleccionado = vendedores[i][telefono];
              vendedores[i]['telefono'] = telefonoSeleccionado;
              // console.log("SELECCIONADO: ", telefonoSeleccionado, vendedores[i].id);
              break;
            }
          }
        }

        response.push({
          ...vendedores[i],
          telefono: telefonoSeleccionado
        });
      }

      detalles = response;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  return detalles;
};