import React, { createContext, useReducer } from "react";

const LoadingContext = createContext();

const initialState = {
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};

const LoadingContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const data = { state, dispatch };

  return (
    <LoadingContext.Provider value={data}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
export { LoadingContextProvider };
