import { useContext, useState } from "react";
import LoadingContext from "../../../../general/context/loading";
import flecha from "../../../assets/flechaAbajo.svg";
import { obtenerRecibos } from "../../services/obtener-recibos.service";
import { obtenerURLPoliza } from "../../services/obtener-url-poliza.service";

import "./style.scss";
const DetalleEstadoPagada = ({ response }) => {
  const { dispatch } = useContext(LoadingContext)
  const [showDeatils, setShowDetails] = useState(false);

  const handleShowDetails = () => {
    setShowDetails(!showDeatils);
  };

  const moneyFormat = (input) => {
    const result = (+input).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
    console.log(result)
    return result
  }

  const downloadCSV = async () => {
    const recibos = await obtenerRecibos(response.idPoliza);
    const items = recibos;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]);
    const csv = [
      header.join(","),
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(",")
      ),
    ].join("\r\n");
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let url = URL.createObjectURL(blob);
    let pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', 'recibos.csv');
    pom.click();
  };
  const getURLPoliza = async () => {
    dispatch({type: 'LOADING', payload: true})
    let url = await obtenerURLPoliza(response.idPoliza);
    window.open(url, '_blank', 'noopener,noreferrer');
    dispatch({type: 'LOADING', payload: false})
  }
  return (
    <>
      <div className="table-details">
        <div key={response.id}>
          <div className="table-details__header">
            <div className="row">
              <div className="row__column row__column--header-left row__column--bold">
                {response.nombre}
              </div>
              <div className="row__column row__column--header-right row__column--bold">
                #{response.folio}
              </div>
            </div>
          </div>

          {
            /********** Detalles personales ***********/
            response.detallesPersonales.map((subItem, index) => {
              return (
                <div key={index}>
                  <div className="table-details__title">
                    Detalles personales
                  </div>
                  <div className="table-details__content">
                    <div className="row">
                      <div className="row__column row__column--three-column row__column--bold">
                        Nombre
                      </div>
                      <div className="row__column row__column--three-column row__column--bold">
                      Teléfono
                      </div>
                      <div className="row__column row__column--three-column row__column--bold">
                        Fecha de nacimiento
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--three-column">
                        {subItem.nombre}
                      </div>
                      <div className="row__column row__column--three-column">
                        {subItem.telefono}
                      </div>
                      <div className="row__column row__column--three-column">
                        {subItem.fechaDeNacimiento}
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--three-column row__column--bold">
                        CP
                      </div>
                      <div className="row__column row__column--three-column row__column--bold">
                      Género
                      </div>
                      <div className="row__column row__column--three-column" />
                    </div>

                    <div className="row">
                      <div className="row__column row__column--three-column">
                        {subItem.cp}
                      </div>
                      <div className="row__column row__column--three-column">
                        {subItem.genero}
                      </div>
                      <div className="row__column row__column--three-column" />
                    </div>
                  </div>
                </div>
              );
            })
          }

          {
            /********** Detalles del auto ***********/
            response.detallesDelAuto.map((subItem, index) => {
              return (
                <div key={index}>
                  <div className="table-details__title">Detalles del auto</div>
                  <div className="table-details__content">
                    <div className="row">
                      <div className="row__column row__column--four-column row__column--bold">
                        Tipo
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        Marca
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        Sub Marca
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        Modelo
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--four-column">
                        {subItem.tipo}
                      </div>
                      <div className="row__column row__column--four-column">
                        {subItem.marca}
                      </div>
                      <div className="row__column row__column--four-column">
                        {subItem.subMarca}
                      </div>
                      <div className="row__column row__column--four-column">
                        {subItem.modelo}
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--one-column row__column--bold">
                        Descripción
                      </div>
                    </div>
                    <div className="row">
                      <div className="row__column row__column--one-column">
                        {subItem.descripcion}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }

          {
            /********** Detalles de cotizacion ***********/
            response.detallesDeCotizacion.map((subItem, index) => {
              return (
                <div key={index}>
                  <div className="table-details__title">
                    <h3>Detalles de cotización</h3>
                    <a target="_blank" href={subItem.url_cotizacion}>Ver cotización en línea</a>
                  </div>
                  <div className="table-details__content">
                    <div className="row">
                      <div className="row__column row__column--four-column row__column--bold">
                        Prima Neta
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        Recargo
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        Expedición
                      </div>
                      <div className="row__column row__column--four-column row__column--bold">
                        IVA
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--four-column">
                        {moneyFormat(subItem.primaNeta)}
                      </div>
                      <div className="row__column row__column--four-column">
                        {moneyFormat(subItem.recargo)}
                      </div>
                      <div className="row__column row__column--four-column">
                        {moneyFormat(subItem.expedicion)}
                      </div>
                      <div className="row__column row__column--four-column">
                        {moneyFormat(subItem.iva)}
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--two-column row__column--bold">
                        Aseguradora
                      </div>
                      <div className="row__column row__column--two-column row__column--bold">
                        Fecha de emisión
                      </div>
                    </div>
                    <div className="row">
                      <div className="row__column row__column--two-column">
                        {subItem.aseguradora}
                      </div>
                      <div className="row__column row__column--two-column">
                      {(response.fecha).substr(0, 10)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="row__column row__column--center row__column--bg-blue">
                        <a href="#" onClick={() => getURLPoliza()}>Ver póliza en línea</a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }

          {
            /********** Recibo ***********/
            response.recibo.map((subItem, index) => {
              return (
                <div key={index}>
                  <div className="table-details__title">
                    <h3>RECIBO</h3>
                    <a href="#" onClick={() => downloadCSV()}>
                      Descargar recibos
                    </a>
                  </div>
                  <div className="table-details__content">
                    <div className="row">
                      <div className="row__column row__column--three-column row__column--bold">
                        Periodo de Pago
                      </div>
                      <div className="row__column row__column--three-column row__column--bold">
                        Monto de Pago
                      </div>
                      <div className="row__column row__column--three-column row__column--bold">
                        Fecha de pago
                      </div>
                    </div>

                    <div className="row">
                      <div className="row__column row__column--three-column">
                        {subItem.periodoDePago}
                      </div>
                      <div className="row__column row__column--three-column">
                        {subItem.montoDePago.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                      </div>
                      <div className="row__column row__column--three-column">
                        {(response.fecha).substr(0, 10)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }

          {
            /********** Desglose de recibos ***********/
            response.desgloseDeRecibos.lenght !== 0 && (
              <>
                <div
                  className="table-details__title"
                  onClick={handleShowDetails}
                >
                  <span>Desglose de recibos</span>{" "}
                  <span>
                    <img src={flecha} alt="flecha" style={{transform: showDeatils ? 'rotate(180deg)' : 'initial', transition: 'all ease .5s'}} />
                  </span>
                </div>
                <div
                  className="table-details__content"
                  style={{ display: showDeatils ? "block" : "none" }}
                >
                  <div className="row">
                    <div className="row__column row__column--four-column row__column--bold">
                      Fecha
                    </div>
                    <div className="row__column row__column--four-column row__column--bold">
                      Pago
                    </div>
                    <div className="row__column row__column--four-column row__column--bold">
                      Monto
                    </div>
                    <div className="row__column row__column--four-column row__column--bold">
                      Status
                    </div>
                  </div>
                  {response.desgloseDeRecibos.map((subItem, index) => {
                    return (
                      <div key={index} className="row">
                        <div className="row__column row__column--four-column">
                          {subItem.fechaDePago}
                        </div>
                        <div className="row__column row__column--four-column">
                          {subItem.pago}
                        </div>
                        <div className="row__column row__column--four-column">
                          {subItem.montoDePago.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                        </div>
                        <div className="row__column row__column--four-column">
                          {subItem.status}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )
          }

          {
            /********** Estado ***********/
            <div className="table-details__status">
              Estado: {response.estado === 'Poliza pagada' ? 'Póliza pagada' : response.estado}
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default DetalleEstadoPagada;
