// React
import React, {useState, useEffect} from 'react'
// Chart js
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  } from 'chart.js'
import { Chart } from 'react-chartjs-2'
// Components
import BotonCambio from '../../../indicadores/components/graficasDinamicas/boton-cambio';
// Custom Hooks
import useChartContent from '../../../indicadores/customHooks/useChartContent';
// Helpers
import { handleScroll } from '../../../indicadores/helpers/handleScroll';
// Styles
import '../../../indicadores/components/GraficasEstadisticas/styles.scss'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
  );
const GraficaUnica = ({dataset, labels}) => {
   
  // Option first table
  const {options: optC, data} = useChartContent(dataset, labels)
  const [options, setOptions] = useState()

  useEffect(() => {
    setOptions(optC)
  }, [optC])

  return(
    <div className='graficas-estadisticas-wrapper'>
      <div className='bg-color-white'>
        <div className='graficas-estadisticas'>
          <div className='grafica-unica' >
            <Chart onWheel={(e) => handleScroll(e, options, setOptions, data)} options={options} data={data} />
            {labels?.length > 5 ? (
              <>
                <BotonCambio left={true} options={options} setOptions={setOptions} data={data} />
                <BotonCambio right={true} options={options} setOptions={setOptions} data={data} />
              </>
            ) : null}
          </div>
        </div>
        
      </div>
    </div>
  )
};
export default GraficaUnica;