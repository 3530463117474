import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    padding: 1rem;
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    `;

export const Container = styled.div`
    display:flex;
    justify-content: space-between;
`;

export const Text = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    line-height:18px;
    font-weight: 400;
    color:#4E4E4E
`;

export const Line = styled.div`
    width:100%;
    height: 1px;
    background-color: #666666;
    margin: 1rem 0
`;

export const Aplicar = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size:0.75rem;
    line-height:1.1rem;
    color:#0582D8;
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
`;