import React from 'react'
//assets
import success from '../../assets/success.svg'
import x from '../../assets/x.svg'
//styles
import {MainContainer,Title, Success, ModalContainer, X} from '../../styles/components/modals/AceptadoStyled'

const Aceptado = ({hide}) => {
  return (
    <ModalContainer>
    <MainContainer open>
        <X onClick={hide}>
        <img src={x}/>
        </X>
        <Title>Autorización exitosa</Title>
        <Success src={success}/>
    </MainContainer>
    </ModalContainer>
  )
}

export default Aceptado