import styled from "styled-components";

export const MainContainer = styled.div `
    width:100%;
    display: flex;
    gap:1rem;
`;

export const Texto = styled.p `
     font-family: 'Montserrat', sans-serif;
     font-weight:400;
     line-height:1.5rem;
     color:#4E4E4E;
     font-size: 1rem;
`;

export const Img = styled.img `
    width:0.5rem
`;

