import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { Header } from '../../general/components/Header';

const Leads = () => {
  if (!JSON.parse(localStorage.getItem("USER"))) {
    return <Navigate to="/login" replace />;
  } else {
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  }
}

export default Leads;
