import React from "react";
//components
import { Buttom } from "../components/Buttom";
//styles
import {
  MainContainer,
  LogoContainer,
  LogoStyle,
  LapStyle,
  ContenedorBienvenido,
  Titulo,
  ContainerButton,
} from "../styles/Bienvenido.styles";
//assets
import logo from "../../general/assets/logo.svg";
import lap from "../../general/assets/Woow_Lap.svg";

// import Asociados from '../../leads/components/asociados'
//components

const Bienvenido = () => {
  const user = JSON.parse(localStorage.getItem('USER'))
  console.log(user)
  return (
    <MainContainer>
      <LogoContainer>
        <LogoStyle alt="logo" src={logo} />
        <LapStyle alt="lap" src={lap} />
      </LogoContainer>

      <ContenedorBienvenido>
        <Titulo>¡Bienvenido, {`${user}`}!</Titulo>
        <ContainerButton>
          <Buttom ruta="/leads" buttonName="LEADS" />

          <Buttom
            ruta="/indicadores" //'/indicadores'
            buttonName="INDICADORES"
          />

          <Buttom ruta="/admin-ventas" buttonName="ADMINISTRACIÓN DE VENTAS" />
        </ContainerButton>
      </ContenedorBienvenido>
    </MainContainer>
  );
};

export default Bienvenido;
