import React, {useContext, useEffect, useState} from 'react'
//style
import {MainContainer, Container, Text, Linea, Aplicar, AplicarTxt} from '../styles/components/ModalOrdenarStyled'
import flechaArriba from '../assets/ModalOrdenar/flechaArriba.svg'
import flechaAbajo from '../assets/ModalOrdenar/flechaAbajo.svg'
import autorizado from '../assets/ModalOrdenar/autorizado.svg'
import pendiente from '../assets/ModalOrdenar/pendiente.svg'
import './ModalOrdenar.scss'

import AdminVentasFiltrosContext from '../context/adminVentas.filtros.context'

const ModalOrdenar = ({hideModal}) => {

  const { state:filtrosContext, dispatch:setFiltrosContext } = useContext(AdminVentasFiltrosContext)
  const { despacho,consecutivo,estatus  } = filtrosContext

  const [showEstatus, setShowEstatus] = useState(false)
  const [state, setState] = useState({
    consecutivo,
    estatus,
  })

  useEffect(()=>{
    if (despacho) {
      setShowEstatus(true)
    } else {
      setShowEstatus(false)
    }
  },[despacho])

  const handleAplicar = () => {
    setFiltrosContext({type: 'SET_CONSECUTIVO', payload: state.consecutivo})
    setFiltrosContext({type: 'SET_ESTATUS', payload: state.estatus})
    hideModal()
  }



  const setOrdenar = (e) => {
    setState({
      ...state,
      [e.target.name] : e.target.checked
    })
  }

  useEffect(()=>{
    if (state.estatus) {
      setState({
        ...state,
        consecutivo:false,
      })
    }
  },[state.estatus])

  useEffect(()=>{
    if (state.consecutivo) {
      setState({
        ...state,
        estatus:false,
      })
    }
  },[state.consecutivo])



  return (
    <MainContainer>
        <Container>
            <Text><label htmlFor="consecutivo">Consecutivo</label></Text>
            <input id='consecutivo' name='consecutivo'  checked={state.consecutivo} onChange={(e) => setOrdenar(e)} type='checkbox'/>
            
        </Container>
        <Linea></Linea>
        {showEstatus && 
        <>
          <Container>
              <Text><label htmlFor="estatus">Estatus</label></Text>
              <input id='estatus' name='estatus' checked={state.estatus} onChange={(e) => setOrdenar(e)} type='checkbox'/>
          </Container>
          <Linea></Linea>
        </>
        }
        <Aplicar onClick={handleAplicar}>
            <AplicarTxt>Aplicar</AplicarTxt>
        </Aplicar>
    </MainContainer>
  )
}

export default ModalOrdenar