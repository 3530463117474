import React, {useContext, useEffect, useState} from 'react'
//style
import {MainContainer, Container, Text, Linea, Aplicar, AplicarTxt} from '../../../styles/components/ModalOrdenarStyled'
import flechaArriba from '../../../assets/ModalOrdenar/flechaArriba.svg'
import flechaAbajo from '../../../assets/ModalOrdenar/flechaAbajo.svg'
import autorizado from '../../../assets/ModalOrdenar/autorizado.svg'
import pendiente from '../../../assets/ModalOrdenar/pendiente.svg'


import AdminVentasFiltrosContext from '../../../context/adminVentas.filtros.context'

const ModalOrdenar = ({hideModal}) => {

  const { dispatch:setFiltrosContext } = useContext(AdminVentasFiltrosContext)

  const setAutorizado = () => {
    setFiltrosContext({type: 'SET_AUTORIZADO', payload: true})
    setFiltrosContext({type: 'SET_PENDIENTE', payload: false})
  }

  const setPendiente = () => {
    setFiltrosContext({type: 'SET_PENDIENTE', payload: true})
    setFiltrosContext({type: 'SET_AUTORIZADO', payload: false})
  }


  return (
    <MainContainer>
        <Container onClick={()=>{
          hideModal()
          setAutorizado()
        }} style={{cursor:'pointer'}}>
            <Text>Autorizado</Text>
           <img src={autorizado}/>
        </Container>
        <Linea></Linea>
        <Container onClick={()=>{
          hideModal()
          setPendiente()
        }} style={{cursor:'pointer'}}>
            <Text>Pendiente</Text>
            <img src={pendiente}/>
        </Container>
        <Linea></Linea>

    </MainContainer>
  )
}

export default ModalOrdenar