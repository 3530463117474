import styled from "styled-components";

export const MainContainer = styled.div`
    width:80%;
    min-height:calc(100vh - 5rem);
    margin: 0 auto;
    background-color: #F8F8F8;
    padding: 1rem;
`;

export const ContainerTop = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center
`;

export const ContainerDescargar = styled.div`
        display:flex;
        gap:1rem;
        margin-top: 1rem;

`;

export const Parrafo = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size:0.8rem;
    font-weight: 700;
    line-height:40px;
    color:#4E4E4E
`;

export const Img = styled.img`
    width:1.5rem;
    cursor: pointer;
`;

export const Containertop2 = styled.div`
    width:100%;
    display:flex;
    justify-content: space-between;
`;