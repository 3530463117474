import styled from "styled-components";

export const MainContainer = styled.div`
    width:80%;
    height:calc(100vh - 5rem);
    margin: 0 auto;
    background-color: #FFFFFF;
`;

export const TableContainer = styled.div`
    background-color:#F8F8F8;
    width:100%;
    height:auto;
    border-radius: 1rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`;

export const Button = styled.button `
    border-radius:8px;
    font-family: 'Montserrat', sans-serif;
    border-color:#EDC729;
    text-align:center;
    color:#121212;
    font-size: 1.1rem,;
    line-height:1.5rem;
    font-weight: 700;
    padding:16px;
    width:15%;
    background-color:#EDC729;
    margin-top:16px;
    margin-bottom:16px;
    cursor:pointer

`