import styled from "styled-components";

export const ModalLoadingS = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-loading-container {
    background-color: white;
    height: 20vh;
    width: 40vw;
    border-radius: 1rem;
    padding: 2rem;
    h2 {
      color: #EDC729;
    }
  }

`