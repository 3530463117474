import styled from "styled-components";

export const NavContainter = styled.div`

    margin-top: 2rem;
`;

export const TituloContainer = styled.div`
   
    
    margin-top: 2rem;
   
`;


export const MainContainer = styled.div`
    width:80%;
    height:calc(100vh - 5rem);
    margin: 0 auto;
    background-color: #FFFFFF;
`;

export const TableContainer = styled.div`
    background-color:#F8F8F8;
    width:100%;
    height:auto;
    border-radius: 1rem;
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
`;