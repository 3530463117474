import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
//componets
import FechaPeriodo from "../components/FechaPeriodo";
import Regresar from "../components/Regresar";
import jsPDF from "jspdf";
import "jspdf-autotable";
//styles
import {
  MainContainer,
  ContainerTop,
  ContainerDescargar,
  Parrafo,
  Img,
  Containertop2,
} from "../styles/views/ConsultaResultadoStyled";
//assts
import dowloadPdf from "../assets/dowloadPdf.svg";
import dowloadXls from "../assets/dowloadXls.svg";
import desapchosToXML from "../helpers/despachosToXML";
import Ordenarpor from "../components/Ordenarpor";
import TablaConsulta from "../components/TablaConsulta";
import TablaAsociadoSelect from "../components/TablaAsociadoSelect";
import Aceptado from "../components/modals/Aceptado";
import Consecutivo from "../components/ModalOrdenar/consecutivo/Ordenarpor";
import Estatus from "../components/ModalOrdenar/estatus/Ordenarpor";

import AdminVentasFiltrosContext from "../context/adminVentas.filtros.context";
import Asociado from "../components/Asociado";
import IconDocument from "../../indicadores/components/ModalEstadisticas/IconDocument";
import axios from "axios";
import {obtenerRecibos} from '../services/obtener-polizas.service';

const ConsultaResultado = () => {
  const navigate = useNavigate();
  const { state: filtrosContext, dispatch: setFiltrosContext } = useContext(
    AdminVentasFiltrosContext
  );

  const {
    periodoStart,
    periodoEnd,
    oficina,
    idOficina,
    despacho,
    idDespacho,
    vendedor,
    idVendedor,
    consecutivo,
    estatus,
  } = filtrosContext;

  useEffect(() => {
    if (!oficina) {
      navigate("/admin-ventas");
    }
  }, []);

  const [nombreFiltro, setNombreFiltro] = useState("");
  const [idAsociado, setIdAsociado] = useState(undefined);
  const [tablaAsociado, setTablaAsociado] = useState(false);
  const [asociado, setAsociado] = useState(false);

  const actualizarTablaAsociado = (status, id) => {
    setIdAsociado(id);
    console.log('setIdAsociado(id);', id);
    setTablaAsociado(status);
  };

  /*
  useEffect(()=>{
    if (despacho) {
      setNombreFiltro(despacho)
      setTablaAsociado(true)
    } else {
      setNombreFiltro(oficina)
      setTablaAsociado(false)
    }
  },[despacho])
*/

  useEffect(() => {
    if (oficina) {
      setNombreFiltro(oficina);
    }

    if (despacho) {
      setNombreFiltro(despacho);
      setTablaAsociado(true);
    }

    if (vendedor) {
      setNombreFiltro(vendedor);
      setAsociado(true);
    }
  }, []);

  const regresar = () => {
    if (asociado) {
      setAsociado(false);
      return;
    }

    if (tablaAsociado) {
      setTablaAsociado(false);
      setIdAsociado(undefined);
      return;
    }

    navigate("/admin-ventas");
  };

  useEffect(() => {
    if (tablaAsociado && !despacho) {
      setFiltrosContext({ type: "SET_DESPACHO", payload: "Despacho 1" });
    }
  }, [tablaAsociado]);

  const obtenerDatos = async () => {
    const data = await fetch("https://jsonplaceholder.typicode.com/albums");
    const despachosData = await data.json();
    setDespachos(despachosData);
  };

  const [despachos, setDespachos] = useState([]);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const downloadPDF = async () => {

    const unit = "pt";
    const size = "A4";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);

    const title = "Reporte";
    const headers = [
      [
        "Oficina",
        "Despacho",
        "Grupo",
        "Vendedor",
        "Tipo de Documento",
        "Documento",
        "Inciso",
        "Agente",
        "Forma de Pago",
        "Moneda",
        "Sub Ramo",
        "Ejecutivo",
        "Renovacion",
        "Fecha de Antigüedad",
        "Desde",
        "Hasta",
        "Estatus",
        "Motivo de Estatus",
        "Fecha de Estatus",
        "Prima Neta",
        "Descuento",
        "%Descuento",
        "Extra Prima",
        "%Extra Prima",
        "Recargos",
        "%Recargos",
        "Derechos",
        "Sub Total",
        "IVA",
        "% de IVA",
        "Prima Total",
        "Comision Neta",
        "%Comision Neta",
        "Comision Promotor",
        "%Comision Promotor",
        "Concepto",
        "Plan",
        "Marca",
        "Tipo",
        "Transmision",
        "Puertas",
        "Modelo",
        "Placas",
        "Serie",
        "Motor",
        "Estado Circula",
        "Color",
        "Servicio",
        "Uso del Vehiculo"
      ]
    ];

    let dat = {
      idVendedor: idVendedor === undefined ? null : idVendedor,
      idOficina: idOficina === undefined ? null : idOficina,
      idDespacho: idDespacho === undefined ? null : idDespacho,
      fechaDesde: periodoStart,
      fechaHasta: periodoEnd
    }
    let info = await obtenerRecibos(dat);

    const data = info.map((e) => [
      e.Oficina,
      e.Despacho,
      e.Grupo,
      e.Vendedor,
      e.tipo_documento,
      e.Documento,
      e.Inciso,
      e.Agente,
      e.forma_pago,
      e.Moneda,
      e.sub_ramo,
      e.Ejecutivo,
      e.Renovacion,
      e.fecha_antiguedad,
      e.Desde,
      e.Hasta,
      e.Estatus,
      e.motivo_estatus,
      e.fecha_estatus,
      e.prima_neta,
      e.Descuento,
      e.por_descuento,
      e.extra_prima,
      e.por_extra_prima,
      e.Recargos,
      e.por_recargos,
      e.Derechos,
      e.sub_total,
      e.IVA,
      e.por_IVA,
      e.prima_total,
      e.comision_neta,
      e.por_comision_neta,
      e.comision_promotor,
      e.por_comision_promotor,
      e.Concepto,
      e.Plan,
      e.Marca,
      e.Tipo,
      e.Transmision,
      e.Puertas,
      e.Modelo,
      e.Placas,
      e.Serie,
      e.Motor,
      e.estado_circula,
      e.Color,
      e.Servicio,
      e.uso_vehiculo
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
      headStyles: { fillColor: "#EDC729", textColor: "#000000" },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("reporte.pdf");
  };

  const downloadXML = async () => {
    let data = {
      idVendedor: idVendedor === undefined ? null : idVendedor,
      idOficina: idOficina === undefined ? null : idOficina,
      idDespacho: idDespacho === undefined ? null : idDespacho,
      fechaDesde: periodoStart,
      fechaHasta: periodoEnd
    }
    let info = await obtenerRecibos(data);
    if(info.length > 0) {
      let xmlDoc = desapchosToXML(info);
      const element = document.createElement("a");
      const blob = new Blob([xmlDoc], { type: "text/plain" });
      element.href = URL.createObjectURL(blob);
      element.download = "data.xml";
      document.body.appendChild(element);
      element.click();
    }
  };

  return (
    <MainContainer>
      <ContainerTop>
        <Regresar handleAction={regresar} />
        <FechaPeriodo
          periodoStart={periodoStart}
          periodoEnd={periodoEnd}
          oficina={nombreFiltro}
        />
      </ContainerTop>
      <Containertop2>
        <ContainerDescargar>
          <Parrafo>Descargar</Parrafo>
          <button style={{background:'none', border:'none', cursor:'pointer'}} onClick={() => downloadXML()}>
            <IconDocument  text="XML"  />
          </button>
        </ContainerDescargar>

        <div style={{ display: "flex", justifyContent: "end", width: "50%" }}>
          <div style={{ marginRight: "30px", width: "45%" }}>
            <Ordenarpor
              texto={
                consecutivo
                  ? "Consecutivo"
                  : estatus
                  ? "Estatus"
                  : "Ordenar por..."
              }
            />
          </div>
          {consecutivo && (
            <div style={{ marginRight: "10px", width: "45%" }}>
              <Consecutivo />
            </div>
          )}
          {estatus && (
            <div style={{ marginRight: "10px", width: "45%" }}>
              <Estatus />
            </div>
          )}
        </div>
      </Containertop2>

      {asociado ? (
        <Asociado data = {
          {
            idOficina, 
            idDespacho: parseInt(idDespacho), 
            idVendedor,
            periodoStart,
            periodoEnd,
          }
        }
        />
      ) : tablaAsociado ? (
        <TablaAsociadoSelect despachos={despachos} 
          data={
            {
              idOficina, 
              idDespacho,  
              idVendedor,
              periodoStart,
              periodoEnd,
            }
          }
        />
      ) : (
        <TablaConsulta 
        data={
          {
            idOficina, 
            idDespacho, 
            idVendedor,
            periodoStart,
            periodoEnd,
          }
        } 
        handleClick={actualizarTablaAsociado} />
      )}
    </MainContainer>
  );
};

export default ConsultaResultado;
