import flecha from '../../../assets/flechaDerecha.svg'
import filtro from '../../../assets/filtro.svg'
import { TableName, ContainerImgTable, TableHead, Tr, Table, TdImpar, TdPar, SearchContainer, Pagination, Paginationp } from '../../../styles/Tabla1.styles'
import React, { useEffect, useState } from 'react'
import ModalBuscar from '../BuscadorModal/index'
import { obtenerCotizaciones } from '../../services/mock-obtener-lista-de-cotizaciones.service'

import './style.scss'
export const ListaDeCotizaciones = ({ contenidoDeLaTabla, obtenerDetallesDeLaCotizacion, funcionFiltrarResultados, idVendedor, maxRecords, filtros, resetFiltros }) => {

  /*useEffect(()=>{
    //console.log(contenidoDeLaTabla)
    const aux = []

    for(let i = 0; i<5000; i++){ 
      aux.push({
        date: `${i+1}/08/2022`,
        id: 4,
        idCotizacion: "1005294",
        name: "pedro lopez rojas",
        selected: false,
        status: "Cotizado",
      })
    }

    contenidoDeLaTabla = aux
  },[])*/

  const [hide, setHide] = useState(false)
  const [leadsSect, setLeadsSect] = useState([])
  const [pages, setPages] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [maxItemPage, setMaxItemPage] = useState(10)
  const mostrarModal = () => {
    setHide(!hide)
  }

  const [isFiltered, setIsFiltered] = useState(false);

  const estados = [
    { value: 'Cotizado', label: 'Cotizado' },
    { value: 'emitida', label: 'Póliza emitida' },
    { value: 'Poliza pagada', label: 'Póliza pagada' },
  ]
  //console.log('contenidoDeLaTabla', contenidoDeLaTabla)
  const printStatus = (input) => {
    const aux = estados.find(el => el.value === input)

    const result = aux.label

    return result;
  }

  const cambioPagina = async (idVendedor, page = 1, noItem = 10) => {
    let cotizaciones = [];
    console.log("FILTROS PAGINA", filtros);
    if(leadsSect[page - 1].length < 1){
      cotizaciones = await obtenerCotizaciones(idVendedor, page, noItem, filtros);
      leadsSect[page - 1] = cotizaciones.results;
      setLeadsSect(leadsSect);
      setCurrentPage(page-1);
    }
    else{
      setCurrentPage(page-1)
    }
    console.log("CURRENT PAGE", currentPage, "SELECTED PAGE", page);
  }

  useEffect(() => {
    if (maxRecords > 1) {
      let pag = Math.floor(maxRecords / 10)
      if (maxRecords % 10 > 0) pag += 1
      let res = []
      let arrPag = []
      for (let i = 0; i < pag; i++) {
        let count = [];
        let curr = i * 10
        for (let j = curr; j < (i + 1) * 10; j++) {
          if (contenidoDeLaTabla[j]) count.push(contenidoDeLaTabla[j])
        }
        res.push(count)
        arrPag.push(i)
      }
     
      if(currentPage != 0){
        res[currentPage] = leadsSect[currentPage]
      }

      setLeadsSect(res)
      setPages(arrPag)
      console.log("LEADS", leadsSect[currentPage]);
      console.log("MAX RECORDS1", maxRecords);
    } else {
      setLeadsSect([contenidoDeLaTabla])
    }
  }, [contenidoDeLaTabla])

  useEffect(() => {
    console.log("MAX RECORDS2", maxRecords);
    cargarPaginas();
  }, [maxRecords])

  const cargarPaginas = () => {
    if (maxRecords > 1) {
      let pag = Math.floor(maxRecords / 10)
      if (maxRecords % 10 > 0) pag += 1
      let res = []
      let arrPag = []
      for (let i = 0; i < pag; i++) {
        let count = [];
        let curr = i * 10
        for (let j = curr; j < (i + 1) * 10; j++) {
          if (contenidoDeLaTabla[j]) count.push(contenidoDeLaTabla[j])
        }
        res.push(count)
        arrPag.push(i)
      }
      setPages(arrPag)
      setCurrentPage(0)
    } else {
      setPages([1])
    }
  }

  return (
    <>
      <div className='table'>

        <div className='table__header'>
          <div className='row'>
            <div className='row__column row__column--bold row__column--grey-header'>
              Nombre
            </div>
            <div className='row__column row__column--grey-header'>
              Status
            </div>
            <div className='row__column row__column--grey-header'>
              Fecha
            </div>
            <div className='row__column row__column--grey-header row__column--position-relative' >
              <span onClick={mostrarModal} ><img src={filtro} alt='filtro' /></span>
              {hide ? <ModalBuscar setIsFiltered={setIsFiltered} isFiltered={isFiltered} resetFiltros={resetFiltros} filtrarResultados={funcionFiltrarResultados} hideModal={mostrarModal} /> : null}
            </div>

          </div>
        </div>

        <div className='table__body'>
          {
            leadsSect[currentPage]?.map((item) => {
              return (
                <div key={item.id}
                  className={
                    'row ' +
                    (item.selected ? 'row--selected' : '')
                  }
                  onClick={() => {

                    //Muestra el lead seleccionado
                    setLeadsSect(leadsSect.map(((page)=>{
                      if (page === leadsSect[currentPage]) {
                        return page.map((lead)=> {
                          if (lead === item) {
                            return {
                              ...lead,
                              selected: true
                            }
                          }
                          return {
                            ...lead,
                            selected: false
                          }
                        })
                      }
                      return page
                    })))
                    
                    //Muestra los detalles del lead
                    obtenerDetallesDeLaCotizacion(item.id, item.idCotizacion)
                  }}
                >
                  <div className='row__column row__column--grey-header'>
                    {item.name}
                  </div>
                  <div className='row__column row__column--grey-header'>
                    {printStatus(item.status)}
                  </div>
                  <div className='row__column row__column--grey-header'>
                    {item.date}
                  </div>
                  <div className='row__column row__column--grey-header'>
                    <img src={flecha} alt='flecha' />
                  </div>
                </div>
              )
            })
          }
        </div>

        <Pagination style={{ margin: '35px 0 20px 0' }}>
          <p>Página {currentPage + 1} de {pages.length}</p>
        </Pagination>
        <Pagination style={{ margin: '0 0 35px 0' }}>

          {/*pages?.map(el => (
            <Paginationp curr={currentPage === el} onClick={() => setCurrentPage(el)}>{el+1}</Paginationp>
          ))*/}

          <button style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => {
            if (currentPage === 0) return
            // setCurrentPage(currentPage - 1)
            cambioPagina(idVendedor, currentPage, maxItemPage)
          }}><img style={{ transform: 'rotateY(190deg)' }} src={flecha} alt='flecha' /></button>

          {currentPage !== 0 &&
            <>
              <Paginationp curr={currentPage === 0} onClick={() => {
                // setCurrentPage(0)
                cambioPagina(idVendedor, 1, maxItemPage)
              }}>{1}</Paginationp>
              <span>...</span>
            </>
          }
          {currentPage - 1 > 0 &&
            pages?.map(p => {
              if (p > currentPage - 3 && p < currentPage && p !== 0) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => {
                      // setCurrentPage(p)
                      cambioPagina(idVendedor, p + 1, maxItemPage)
                    }}>{p + 1}</Paginationp>
                  </>
                )
              }
            })
          }
          <Paginationp curr={currentPage === currentPage} onClick={() => false}>{currentPage + 1}</Paginationp>

          {currentPage < pages[pages.length - 1] &&
            pages?.map(p => {
              if (p > currentPage && p < currentPage + 3 && p !== pages[pages.length - 1]) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => {
                      cambioPagina(idVendedor, p + 1, maxItemPage)
                    }}>{p + 1}</Paginationp>
                  </>
                )
              }
            })
          }

          {currentPage !== pages[pages.length - 1] &&
            <>
              <span>...</span>
              <Paginationp curr={currentPage === pages[pages.length - 1]} onClick={() => {
                // setCurrentPage(pages[pages.length - 1])
                cambioPagina(idVendedor, pages[pages.length-1]+1, maxItemPage)
              }}>{pages.length}</Paginationp>
            </>
          }
          <button style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => {
            if (currentPage === pages[pages.length - 1]) return
            // setCurrentPage(currentPage + 1)
            cambioPagina(idVendedor, currentPage + 1, maxItemPage)
          }}><img src={flecha} alt='flecha' /></button>

        </Pagination>

      </div>
    </>
  )
}
