import styled from "styled-components";

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    margin-top: 1rem;
    align-items:center;
    height:100%;
    .select-guion{
        background-color:#EDC729;
        height:100%;
        width:100%;
        display:flex;
        justify-content:center;
        align-items:center;
       padding: 0 1rem
    }
`;

export const Title = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#4E4E4E;
    height:100%;
    padding:1rem;
    background-color:#EDC729;
   
    
`;

export const Text = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#838383;
    padding:1rem;
    transition: all ease-in-out .1s;
    :hover{
        font-weight: 700;
        font-size: 1rem;
    //line-height:24px;
    }
`;

export const Ok = styled.div`
    border-radius: 16px;
    background-color: #FDE46F;
    padding:1rem;
    box-shadow:10px 10px 5px -9px rgba(0,0,0,0.75);
    text-align: center;
    cursor: pointer;
    height:60%;
    padding:1rem;
    display:flex;
    justify-content:center;
    align-items:center;
    width:100%
`;

export const OkText = styled.p`
    font-family: 'Montserrat', sans-serif;
    line-height:20px;
    font-size:0.81rem;
    font-weight: 700;
    color:#131313
`;

export const Seleccionar = styled.div`
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    min-height:100%;
    min-width:100%;
 
   
`;

export const Check = styled.input`
    width:10%;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    border-radius: 50%;
`;

export const Autorizado = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 700;
    line-height:18.2px;
    color:#121212;
    padding:1rem;
`;

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    
`
export const Paginationp = styled.p`
    cursor: pointer;
    font-weight: ${props => props.curr ? 'bold' : 'normal'};
`

export const Total = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 0.87rem;
    font-weight: 600;
    line-height:20px;
    color:#121212;
    padding:1rem;
`;