const tableToXML = (body, name) => {
  let xmlFinal =
    '<?xml version="1.0" encoding="utf-8"?><?mso-application progid="Excel.Sheet"?><Workbook xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:x2="urn:schemas-microsoft-com:office:excel2" xmlns:html="http://www.w3.org/TR/REC-html40" xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882">' +
    '\n<x:ExcelWorkbook>' +
    '\n<x:ActiveSheet>0</x:ActiveSheet>' +
    '\n</x:ExcelWorkbook>' +
    '\n<Styles>' +
    '\n<Style ss:ID="Default" ss:Name="Normal">' +
        '\n<ss:Alignment ss:Vertical="Bottom" />' +
        '\n<ss:Font ss:Color="#000000" ss:FontName="Arial" ss:Size="10" />' +
    '\n</Style>' +
    '\n<Style ss:ID="S21">' +
        '\n<ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />' +
        '\n<ss:Borders>' +
        '\n<ss:Border ss:Position="Left" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Top" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Right" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Bottom" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n</ss:Borders>' +
        '\n<ss:Font ss:Color="#4E4E4E" ss:FontName="Montserrat" ss:Size="11" />' +
        '\n<ss:Interior ss:Color="#EDC729" ss:Pattern="Solid" />' +
    '\n</Style>' +
    '\n<Style ss:ID="S22">' +
        '\n<ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />' +
        '\n<ss:Borders>' +
        '\n<ss:Border ss:Position="Left" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Top" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Right" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n</ss:Borders>' +
        '\n<ss:Font ss:Color="#838383" ss:FontName="Montserrat" ss:Size="11" />' +
        '\n<ss:Interior ss:Color="#EDEDED" ss:Pattern="Solid" />' +
    '\n</Style>' +
    '\n<Style ss:ID="S23">' +
        '\n<ss:Alignment ss:Horizontal="Center" ss:Vertical="Bottom" />' +
        '\n<ss:Borders>' +
        '\n<ss:Border ss:Position="Left" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Top" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n<ss:Border ss:Position="Right" ss:Color="#EDEDED" ss:LineStyle="Continuous" ss:Weight="1" />' +
        '\n</ss:Borders>' +
        '\n<ss:Font ss:Color="#838383" ss:FontName="Montserrat" ss:Size="11" />' +
        '\n<ss:Interior ss:Color="#FFFFFF" ss:Pattern="Solid" />' +
    '\n</Style>' +
    '\n</Styles>' +
    '\n<Worksheet ss:Name="Hoja 1">' +
    '\n<ss:Names />' +
    '\n<ss:Table ss:DefaultRowHeight="15.75" ss:DefaultColumnWidth="66" ss:ExpandedRowCount="4" ss:ExpandedColumnCount="5">' +
    '\n<Row ss:Index="1">' +
        '\n<Cell ss:StyleID="S21"><Data ss:Type="String">'+ name +'</Data>' +
        '\n</Cell>' +
        '\n<Cell ss:StyleID="S21"><Data ss:Type="String">Pólizas emitidas</Data>' +
        '\n</Cell>' +
        '\n<Cell ss:StyleID="S21"><Data ss:Type="String">Pólizas pagadas</Data>' +
        '\n</Cell>' +
        '\n<Cell ss:StyleID="S21"><Data ss:Type="String">Prima emitida</Data>' +
        '\n</Cell>' +
        '\n<Cell ss:StyleID="S21"><Data ss:Type="String">Prima pagada</Data>' +
        '\n</Cell>' +
    '\n</Row>';
    let tableRows = '';
    body.forEach((e, i) => {
        tableRows += `\n<Row ss:Index="${i+2}">`;
        tableRows += `\n<Cell ss:StyleID="S22"><Data ss:Type="String">${e.nombre}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S22"><Data ss:Type="String">${e.Emitidas}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S22"><Data ss:Type="String">${e.Cobradas}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S22"><Data ss:Type="String">${e.Prima_Emitida}</Data></Cell>`;
        tableRows += `\n<Cell ss:StyleID="S22"><Data ss:Type="String">${e.Prima_Cobrada}</Data></Cell>`;
        tableRows += `\n</Row>`;
    });
    xmlFinal += tableRows;
    let xmlFooter =
    '\n</ss:Table>' +
    '\n<WorksheetOptions xmlns="urn:schemas-microsoft-com:office:excel">' +
     '\n<PageSetup />' +
     '\n<Print>' +
      '\n<PaperSizeIndex>9</PaperSizeIndex>' +
      '\n<HorizontalResolution>600</HorizontalResolution>' +
      '\n<VerticalResolution>600</VerticalResolution>' +
     '\n</Print>' +
    '\n</WorksheetOptions>' +
    '\n</Worksheet>' +
    '\n</Workbook>';
    xmlFinal += xmlFooter;
    return xmlFinal;
}

module.exports = tableToXML