import React from 'react'
import { Button } from '../styles/EstadoNuevo.styles'
const BackButton = ({handlePress}) => {
  return (
    <Button
    onClick={handlePress}
    >Atrás</Button>
  )
}

export default BackButton