import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment';
// Components
import SelectIndicadores from './SelectIndicadores'
// Context
import IndicadoresContext from '../context/indicadores.context';
// Assets
import FlechaDespliegue from '../assets/flecha-select'
// Services
import { obtenerDespachos } from '../services/obtener-despachos.service';
import { obtenerVendedores } from '../services/obtener-vendedores.service';
//style
import {MainContainer,Container, Title, PeriodContainer, Calendar, Selection, Button} from '../styles/componets/SideFiltroStyled'
import '../styles/style.scss'

const SideFiltro = ({filtrar, filtroAplicado, resetFiltros, oficinas}) => {
  const [showIndicadores, setShowIndicadores] = useState(false)

  const [indicador, setIndicador] = useState({"Ramo/Subramo": false, "Forma de pago": false, "Periodo de pago": false, "Aseguradora": false,})
  const [obtener, setObtener] = useState({oficina : "default", vendedor : "default", despacho : "default", fechaIni : false, fechaFin : false})

  const [periodoStart, setPeriodoStart ] = useState('')
  const [periodoEnd, setPeriodoEnd ] = useState('')

  const [disabledButton, setDisabledButton] = useState(true)

  const { dispatch } = useContext(IndicadoresContext)

  const handlePeriodChange = (val, type) => {
    const dateFormat = 'YYYY-MM-DD';
    if (type === 'start') {
      setPeriodoStart(val, dateFormat);
      setObtener({
        ...obtener,fechaIni:val
      });
    } else if (type === 'end') {
      if (moment(val, dateFormat).isAfter(moment(periodoStart, dateFormat))) {
        setPeriodoEnd(val, dateFormat);

        setObtener({
          ...obtener,fechaFin:val
        });
        return;
      }
      setPeriodoEnd(moment(periodoStart, dateFormat).add(1, 'day').format(dateFormat));
    }
  }
  const resetFecha = () => {
    const date = new Date();
    const aux = (date.toISOString().split('T')[0]).split('-')
    const day = aux[2]
    const month =aux[1]
    const year= aux[0]
    setPeriodoStart(`${year}-${month}-01`)
    setPeriodoEnd(`${year}-${month}-${day}`)
    dispatch({type: 'PERIODO-START', payload: `${year}-${month}-01`})
    dispatch({type: 'PERIODO-END', payload: `${year}-${month}-${day}`})
    setObtener({oficina : "default", vendedor : "default", despacho : "default", fechaIni : `${year}-${month}-01`, fechaFin : `${year}-${month}-${day}`})
  }
  useEffect(resetFecha,[])
  
  const handleObtener = (e) => {
    setObtener({...obtener, [e.target.name] : e.target.value})
  }

  const handleIndicador = (e) =>{
    setIndicador({...indicador, [e.target.name] : e.target.checked})
  }
 
  const handleFiltrar = () => {
    dispatch({type:"CONSULTAR"})
    filtrar(obtener)
  }

  useEffect(()=> {
    obtener.indicador = indicador;
  }, [indicador])

  /// ------------------------------------------------
  const handleResetAll = () => {
    setIndicador({"Ramo/Subramo": false, "Forma de pago": false, "Periodo de pago": false, "Aseguradora": false})
    setObtener({
      ...obtener, 
      oficina: 'default'
    })
    resetFecha();
    resetFiltros()
    //console.log(periodoStart)
    //console.log('obtener',obtener)
    //resetFecha(); //No hace falta el reset ya que se restea la fecha en las lineas de arriba
    dispatch({type:"RESET-INDICADORES"})
    dispatch({type:"CONSULTAR"}) // esto es para que los labels de la grafica se actualice
  }
 
  useEffect(()=>{
    const isIndicadorSelected = (Object.keys(indicador).filter(i => indicador[i])).length > 0
    if (((periodoStart && periodoEnd) && obtener.oficina !== 'default') || isIndicadorSelected) {
      setDisabledButton(false)
    }else{
      setDisabledButton(true)
    }
  },[obtener, indicador, periodoStart, periodoEnd])

  const handleGrafica = ()=>{
    dispatch({type:"INDICADORES-GRAFICAS",payload:indicador})
  }

  const [disabledDespacho, setDisabledDespacho] = useState(true)
  const [disabledVendedor, setDisabledVendedor] = useState(true)

  const [despachos, setDespachos] = useState([])
  const [vendedores, setVendedores] = useState([])

  const getDespachos = async (id) => {
    const despachos = await obtenerDespachos(id);
    setDespachos(despachos)
  }

  const getVendedores = async (id) => {
    const vendedores = await obtenerVendedores(id);
    setVendedores(vendedores)
  }

  useEffect(()=>{
    if (obtener.oficina !== 'default') {
      getDespachos(Number(obtener.oficina))
      setDisabledDespacho(false)
    } else {
      setDisabledDespacho(true)
      setObtener({
        ...obtener,
        despacho: 'default'
      })
    }
  },[obtener.oficina])

  useEffect(()=>{
    if (obtener.despacho !== 'default') {
      getVendedores(Number(obtener.despacho))
      setDisabledVendedor(false)
    } else {
      setDisabledVendedor(true)
      setObtener({
        ...obtener,
        vendedor: 'default'
      })
    }
  },[obtener.despacho])

  useEffect(() => {
    dispatch({type:"PERIODO-START",payload:periodoStart})
    
  
  }, [periodoStart])

  useEffect(() => {
   
    dispatch({type:"PERIODO-END",payload:periodoEnd})
  
  }, [periodoEnd])

  

  return (
    <MainContainer>
        <Container>
            <div className='indicadores-filtro__encabezado'>
            <Title>Periodo</Title> {filtroAplicado && <span onClick={()=>handleResetAll()} className='reset-all'>RESET ALL</span>}
            </div>
            <PeriodContainer>
                <Calendar
                type="date"
                value={periodoStart} 
                onChange={(e) => handlePeriodChange(e.target.value, 'start')} 
                name="trip-start"
                />
                <Calendar 
                type="date"
                value={periodoEnd} 
                onChange={(e) => handlePeriodChange(e.target.value, 'end')} 
                name="trip-end"
             
                />
            </PeriodContainer>
            </Container>

            <Container>
                <Title>Oficina</Title>
                <Selection value={obtener.oficina} name="oficina"  onChange={(e) => handleObtener(e)}>
                    <option value="default">Seleccionar oficina</option>
                    {oficinas.map(ofi => (
                        <option value={ofi.IDOfna}>{ofi.NombreCompleto}</option>
                      )
                    )}
                </Selection>
            </Container>

            <Container>
                <Title>Despacho</Title>
                <Selection disabled={disabledDespacho} value={obtener.despacho} name="despacho" onChange={(e) => handleObtener(e)}>
                    <option value="default">Selecciona despacho</option>
                    {despachos.map(des => (
                        <option value={des.id}>{des.nombre}</option>
                      )
                    )}
                </Selection>
            </Container>

            <Container>
                <Title>Vendedor</Title>
                <Selection disabled={disabledVendedor} value={obtener.vendedor} name="vendedor" onChange={(e) => handleObtener(e)}>
                    <option value="default">Selecciona vendedor</option>
                    {vendedores.map(ven => (
                        <option value={ven.IDVend}>{ven.NombreCompleto}</option>
                      )
                    )}
                </Selection>
            </Container>

            <Container selected={showIndicadores} >
                <Title>Indicadores</Title>
                <div 
                  
                  className='indicadores-show'
                  >
                    <p onClick={() => setShowIndicadores(!showIndicadores)}>
                        Selecciona indicadores
                        <FlechaDespliegue></FlechaDespliegue>
                    </p>
                    {showIndicadores ? (
                      <SelectIndicadores
                      onClick={handleGrafica}
                      indicador={indicador}
                      onChange={handleIndicador}
                      />
                    ) : null}
                </div>
            </Container>

            <Button
              className={`${disabledButton ? 'indicadores-filtro__btn--disabled' : ''}`}
              disabled={disabledButton}
              onClick={()=> handleFiltrar()}
            >Consultar</Button>
        
    </MainContainer>
  )
}

export default SideFiltro