import React, { createContext, useReducer } from "react";

const UsernameContext = createContext();

const initialState = {
  username: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET-USERNAME':
      return {
        ...state,
        username: action.payload
      }
    default:
      return state;
  }
};

const UsernameContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const data = { state, dispatch };

  return (
    <UsernameContext.Provider value={data}>
      {children}
    </UsernameContext.Provider>
  );
};

export default UsernameContext;
export { UsernameContextProvider };
