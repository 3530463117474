// export const obtenerRecibos = async (id) => {
//   //console.log(id);
//   const apiUrl = "https://backendsicas.gpmass.mx/api/get-poliza";
//   const body = JSON.stringify({
//     data.dataConfig: {
//       TypeFormat: "JSON",
//       KeyProcess: "REPORT",
//       KeyCode: "H03846_Cob",
//       Page: 1,
//       ItemForPage: 50,
//       InfoSort: "VDatDocumentos.Documento",
//       ConditionsAdd: "Documento jajaja;0;0;" + id + ";;Documento",
//     },
//     credentials: {
//       username: "T0do_8ienWoow",
//       password: "T0doW@ow202O",
//     },
//   });
//   let detalles = [];
//   await fetch(apiUrl, {
//     method: "post",
//     body: body,
//     headers: {
//       "Content-type": "Application/json",
//     },
//   })
//     .then((response) => response.json())
//     .then((data.data) => {
//       detalles = data.data.data.data;
//     })
//     .catch((err) => console.error("ERROR: ", err.message));
//   return detalles;
// };


export const obtenerRecibos = async (id) => {
  const apiUrl = "https://backendsicas.gpmass.mx/api/get-archivo-cobranza";
  const body = JSON.stringify({
    "idPoliza": id
  });
  let results = await fetch(apiUrl, {
    method: "post",
    body: body,
    headers: {
      "Content-type": "Application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      let detalles = [];

      for (let i = 0; i < data.data.length; i++) {
        detalles.push({
          oficina: data.data[i].Oficina,
          despacho: data.data[i].Despacho,
          grupo: data.data[i].Grupo,
          vendedor: data.data[i].Vendedor,
          Documento: data.data[i].Documento,
          Serie: data.data[i].Serie,
          Estatus: data.data[i].StatusRecibo,
          Motivo: data.data[i].Motivo,
          Neta: data.data[i].Neta,
          PrimaNeta: data.data[i].PrimaNeta,
          FechaPago: data.data[i].FechaPago,
          TipoDocumento: data.data[i].TipoDocumento,
          ImportePago: data.data[i].ImportePago,
          NumLiquidacion: data.data[i].NumeroLiquidacion,
          FechaLiquidacion: data.data[i].FechaLiquidacion,
          PorComisNeta: data.data[i].ComisionNetaVendedor,
          ComisionNeta: data.data[i].ComisionVendedor
        })
      }

      return detalles;
    })
    .catch((err) => console.error("ERROR: ", err.message));
  console.log(results);
  return results;
}

// for(let i = 0; i < data.data.length; i++){
      //   detalles.push({
      //     oficina: data.data[i].OfnaNombre,
      //     despacho: data.data[i].DespNombre,
      //     grupo: data.data[i].Grupo,
      //     vendedor: data.data[i].VendNombre,
      //     TipoDocumento: data.data[i].TipoDocto_TXT,
      //     Documento: data.data[i].Documento,
      //     Inciso: data.data[i].Inciso,
      //     Agente: data.data[i].AgenteNombre,
      //     FormaPago: data.data[i].FPago,
      //     Moneda: data.data[i].Moneda,
      //     SubRamo: data.data[i].SRamoNombre,
      //     Ejecutivo: data.data[i].EjecutNombre,
      //     Vendedor: data.data[i].VendNombre,
      //     Renovacion: data.data[i].Renovacion,
      //     FechaAntiguedad: data.data[i].FAntiguedad,
      //     Desde: data.data[i].FDesde,
      //     Hasta: data.data[i].FHasta,
      //     Estatus: data.data[i].Estatus,
      //     MotivoEstatus: data.data[i].MotStatus,
      //     FecEstatus: data.data[i].Fstatus,
      //     Descuento: data.data[i].Descuento,
      //     PorDescuento: data.data[i].PorDesc,
      //     ExtraPrima: data.data[i].ExtraPrima,
      //     PorExtraPrima: data.data[i].PorExtraP,
      //     Recargos: data.data[i].Recargos,
      //     PorRecargo: data.data[i].PorRecargos,
      //     Derechos: data.data[i].Derechos,
      //     SubTotal: data.data[i].STotal,
      //     IVA: data.data[i].Impuesto1,
      //     PorIVA: data.data[i].PorImp1,
      //     PrimaTotal: data.data[i].PrimaTotal,
      //     ComisionNeta: data.data[i].Comision0,
      //     PorComisNeta: data.data[i].PorCom0,
      //     ComPromotor: data.data[i].Comision1,
      //     PorComPromotor: data.data[i].PorCom1,
      //     Concepto: "",
      //     Plan: data.data[i].Plan,
      //     Marca: data.data[i].Marca,
      //     Tipo: data.data[i].Tipo,
      //     Transmision: "",
      //     Puertas: "",
      //     Modelo: data.data[i].Modelo,
      //     Placas: "",
      //     Serie: "",
      //     Motor: "",
      //     EstadoCircula: "",
      //     Color: "",
      //     Servicio: "",
      //     UsoVehiculo: (data.data[i].Concepto).split("/"),
      //   })
      // }