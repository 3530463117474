import styled from "styled-components";

export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
    background-color:rgba(0, 0, 0, 0.4);
    justify-content: center;
    align-items:center;
    position: absolute;
    left:0;
    top:0;
    width:100vw;
    height: 100vh;
    position:fixed;
    z-index:1;
`;

export const MainContainer = styled.dialog`
    width:26rem;
    padding:2rem;
    margin: 0 auto;
    border:0;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem
    `;

export const Title = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size:1.5rem;
    line-height:32px;
    text-align: center;
    color:#121212
`;

export const Subtitle = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height:20px
`;

export const Button = styled.button`
    width:50%;
    border-radius: 16px;
    background-color: #EDC729;
    text-align:center;
    font-family: 'Montserrat', sans-serif;
    font-size:0.81rem;
    font-weight: 700;
    line-height:40px;
    padding:1rem;
    border:0
`;