import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import lap from "../../general/assets/Woow_Lap.svg";
import hiddenEye from "../assets/OjoCerrado.svg";
import openEye from "../assets/openEye.svg";
import { login } from "../services/login.service";
import md5 from "md5";
// import UsernameContext from "../../general/context/username";

const Login = () => {
  // const { dispatch } = useContext(UsernameContext);
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const logon = async (e) => {
    e.preventDefault();
    const resp = await login({ user: user, pass: md5(pass) });
    // resp contiene 3 valores:
    // code - codigo http
    // error - true o false
    // message - Si el usuario y contraseña son correctas mostrará el nombre del usuario, de lo contrario mostrará el mensaje de error
    if (resp.code === 200) {
      // Datos correctos
      console.log(resp.message);
      // dispatch({ type: "SET-USERNAME", payload: resp.message });
      localStorage.setItem("USER", JSON.stringify(resp.message));
      navigate("/");
    } else if (resp.code === 500) {
      // Datos incorrectos
      setErrorMessage(resp.message);
      console.log(resp.message);
    } else {
      // Error del servidor
      setErrorMessage(resp.message);
      console.log(resp.message);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <main className="login-main ">
        <div className="lap-container">
          <img src={lap} alt="laptop" />
        </div>
        <div className="form-container">
          <h2 className="header-title">Inicia Sesión</h2>
          <form className="form" onSubmit={logon}>
            <div className="input">
              <input
                placeholder="Username"
                type="text"
                id="username"
                value={user}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
              />
            </div>
            <div className="input">
              <input
                placeholder="Contraseña"
                type={showPassword ? "text" : "password"}
                id="password"
                value={pass}
                onChange={(e) => {
                  setPass(e.target.value);
                }}
              />
              <button
                onClick={handleShowPassword}
                id="showPasswordBtn"
                type="button"
              >
                <img src={showPassword ? openEye : hiddenEye} alt="close eye" />
              </button>
            </div>
            {errorMessage?.length > 0 ? (
              <p style={{ color: "red" }}>{errorMessage}</p>
            ) : null}
            <button
              type="submit"
              /*onClick={() => logon()}*/ className="button"
            >
              Iniciar Sesión
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default Login;
