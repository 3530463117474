export const obtenerDashVentas = async (filtros) => {
    let dash = [];
    const apiUrl = "https://backendsicas.gpmass.mx/api/get-dash-ventas";
    let results = await fetch(apiUrl, {
      method: "post",
      body: JSON.stringify(filtros),
      headers: {
        "Content-type": "Application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => console.error("ERROR: ", err.message));
    return results;
  };
  