import styled from "styled-components";

export const MainContainer = styled.div`
    width:40%;
    display:flex;
    gap:16px;
    background-color: #FFFFFF;
    justify-self: flex-end;
    align-items:flex-end;
    margin: 1rem 0
`;

export const ContainerFecha = styled.div `
    
    width:50%;
    gap:16px;

`;

export const Title = styled.h2 `
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    line-height:18px;
    font-weight: 700;
    color:#4E4E4E
`;

export const Selection = styled.select `
    font-family: 'Montserrat', sans-serif;
    font-size: 0.875rem;
    line-height:18px;
    font-weight: 400;   
    color:#838383;
    border-radius: 8px;
    padding:1rem;
    background-color: #F8F8F8;
    border: 0;
    width:70%
`;