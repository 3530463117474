import styled from "styled-components";

export const MainContainer = styled.div`
    width:100%;
    height:calc(100vh - 5rem);
    display:flex;
    flex-direction:row;
    //align-items:center;
    //justify-content:center
`;

export const LogoContainer = styled.div`
    width:60%;
    
    background:linear-gradient(90deg, #EBCA00 0%, #FFAD01 100%);
    display:flex;
    flex-direction: column;
    position:relative;
    justify-content:center;
    align-items: center;
    
`;

export const LogoStyle = styled.img`
    width:6rem;
   
    padding: 1rem;
    align-self: flex-start;
    justify-self: flex-start;
    position:absolute;
    top:0;
    
   
`;

export const LapStyle = styled.img`
    width:35rem;
    //align-self: center;
    justify-self: flex-start;

    
`;

export const ContenedorBienvenido = styled.div`
    width:40%;
    display:flex;
    flex-direction:column;
    //align-items: center;
    //justify-content:center
`;

export const Titulo = styled.h2`
    font-size:2.2rem;
    //align-self:center;
    text-align:center;
    color:#121212;
    margin: 4rem 0 ;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif; 

`;

export const ContainerButton = styled.div`
  margin-bottom:1rem;
  display:flex;
  flex-direction: column;
  gap:1rem ;
  align-items: center;
  margin-top: 2rem;

`;





