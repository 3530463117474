import React from 'react'
import { Link } from 'react-router-dom'
import {ButtonName} from '../../general/styles/buttonName'
export const Buttom = ({buttonName, ruta}) => {
  return (
    <div>
      <Link style={{textDecoration: 'none'}}
      to = {ruta}
      >
      <ButtonName>
        {buttonName}
      </ButtonName>
      </Link>
        
    </div>
  )
}
