export const handleScroll = (e, options, setOptions, data) => {
  if (options.scales.x) {
    if (e.deltaY > 0) {
      setOptions({
        ...options,
        scales: {
          ...options.scales,
          x: {
            min: options.scales.x.max < data.labels.length - 1 ? options.scales.x.min + 1 : options.scales.x.min,
            max: options.scales.x.max < data.labels.length - 1 ? options.scales.x.max + 1 : options.scales.x.max
          }
        }
      })
    } 
    if (e.deltaY < 0) {
      setOptions({
        ...options,
        scales: {
          ...options.scales,
          x: {
            min: options.scales.x.min === 0 ? 0 : options.scales.x.min - 1,
            max: options.scales.x.min === 0 ? options.scales.x.max : options.scales.x.max - 1
          }
        }
      })
    } 
  }

}