import React, {useContext, useEffect, useState} from 'react'
//style
import {MainContainer, Container, Text, Linea, Aplicar, AplicarTxt} from '../../../styles/components/ModalOrdenarStyled'
import flechaArriba from '../../../assets/ModalOrdenar/flechaArriba.svg'
import flechaAbajo from '../../../assets/ModalOrdenar/flechaAbajo.svg'
import autorizado from '../../../assets/ModalOrdenar/autorizado.svg'
import pendiente from '../../../assets/ModalOrdenar/pendiente.svg'


import AdminVentasFiltrosContext from '../../../context/adminVentas.filtros.context'

const ModalOrdenar = ({hideModal}) => {

  const { dispatch:setFiltrosContext } = useContext(AdminVentasFiltrosContext)
  


  const setAscendente = () => {
    setFiltrosContext({type: 'SET_ASCENDENTE', payload: true})
    setFiltrosContext({type: 'SET_DESCENDENTE', payload: false})
  }

  const setDescendente = () => {
    setFiltrosContext({type: 'SET_DESCENDENTE', payload: true})
    setFiltrosContext({type: 'SET_ASCENDENTE', payload: false})
  }




  return (
    <MainContainer>
        <Container onClick={()=>{
          hideModal()
          setAscendente()
        }} style={{cursor:'pointer'}}>
            <Text>Ascendente</Text>
           <img src={flechaArriba}/>
        </Container>
        <Linea></Linea>

        <Container onClick={()=>{
          hideModal()
          setDescendente()
        }} style={{cursor:'pointer'}}>
            <Text>Descendente</Text>
            <img src={flechaAbajo}/>
        </Container>
        <Linea></Linea>

    </MainContainer>
  )
}

export default ModalOrdenar