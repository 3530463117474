/* eslint-disable default-case */
import React, { useState,useEffect } from 'react'
//style
import {GridContainer, Title, Text, Ok, OkText, Seleccionar, Check, Autorizado, Pagination, Paginationp, Total} from '../styles/components/TablaAsociadoSelectStyles'
import './TablaAsociadoSelect.scss'
import AceptaAutorizacion from './modals/AceptaAutorizacion'
import Aceptado from './modals/Aceptado'
import flecha from '../assets/flechaDerecha.svg'
import axios from 'axios'

const TablaAsociadoSelect = ({data}) => {

  const [despachos, setDespachos] = useState([])
  const [polizas, setPolizas] = useState([])
  const [recibos, setRecibos] = useState([])
  const [todoAutorizado, setTodoAutorizado] = useState(false);
  const base = 'https://backendsicas.gpmass.mx/api';
  // const base = 'http://localhost:3000/api';

  const [modalData, setModalData] = useState({
    idOficina: data.idOficina,
    idDespacho: data.idDespacho === undefined ? null : data.idDespacho,
    idVendedor: data.idVendedor === undefined ? null : data.idVendedor,
    idPoliza: null,
    idRecibo: null,
    fechaHasta: data.periodoEnd,
    fechaDesde: data.periodoStart,
  });

  const handleModalData = (id, type) => {
    switch (type) {
      case "addRecibo":
        modalData.idRecibo = id;
        modalData.idPoliza = null;
        modalData.idVendedor = null;
        break;
      case "addPoliza":
        modalData.idPoliza = id;
        modalData.idRecibo = null;
        modalData.idVendedor = null;
        break;
      case "addVendedor":
        modalData.idVendedor = id;
        modalData.idRecibo = null;
        modalData.idPoliza = null;
        break;
      default:
        modalData.idRecibo = null;
        modalData.idPoliza = null;
        modalData.idVendedor = null;
        break;
    }
  };

  //#region

  const verificarDespacho = async (page) => {
    const res = await axios.post(base+'/query-results', 
    {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor,
      periodoStart: data.periodoStart,
      periodoEnd: data.periodoEnd,
      type: 'verDespacho',
      page: page === undefined ? 1 : page,
      noItems: 25
    },
    {headers: {
      'content-type': 'application/json'
    }});
    console.log(res)
    if (res?.status === 200 && res?.data?.data[0]?.status === 1) {
      setTodoAutorizado(true);
    }
    console.log('Todo Autorizado', res);
  }; 

  const traerInformacion = async (page) => {
    await verificarDespacho();
    const res = await axios.post(base+'/query-results', 
    {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor,
      periodoStart: data.periodoStart,
      periodoEnd: data.periodoEnd,
      type: 'vend',
      page: page === undefined ? 1 : page,
      noItems: 25
    },
    {headers: {
      'content-type': 'application/json'
    }}
    );
    if (res?.status === 200 && res?.data?.data?.length) {
      setDespachos(res.data.data.map(item => ({...item, checked: false})));
      setTotalItems(res.data.info.MaxRecords)
    }
    else{
      setDespachos([]);
    }
    console.log('traerInformacion', res, "page:", page);
    setTotalItems(0)
  };

  useEffect(() => {
    traerInformacion();
  }, [data]);


  const traerInformacionPolizas = async () => {
    const res = await axios.post(base+'/query-results', 
    {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor,
      periodoStart: data.periodoStart,
      periodoEnd: data.periodoEnd,
      type: 'docto'
    },
    {headers: {
      'content-type': 'application/json'
    }}
    );
    if (res?.status === 200 && res?.data?.data?.length) {
      const aux = res.data.data.map(item => ({...item, checked: false}))

        console.log('res.data.data',res.data.data.map(item => ({...item, checked: false,
        autorizado: false})));
        console.log('polizas:', polizas);
        console.log('auuux:', aux);
      setPolizas(aux);
    }
  };

  const traerInformacionRecibos = async () => {
    const res = await axios.post(base+'/query-results', 
    {
      idOficina: data.idOficina,
      idDespacho: data.idDespacho,
      idVendedor: data.idVendedor ,
      periodoStart: data.periodoStart,
      periodoEnd: data.periodoEnd,
      type: 'poliza'
    },
    {headers: {
      'content-type': 'application/json'
    }}
    );
    if (res?.status === 200 && res?.data?.data?.length) {
      const aux = res.data.data.map(item => ({...item, checked: false}))
        console.log('RECIBOS:', aux);
      setRecibos(aux);
    }
  };
  
  useEffect(() => {
    traerInformacionPolizas();
    traerInformacionRecibos();
  }, []);

  const getByPage = async(page) => {
    traerInformacion(page);
    calculaSumatoria();
  }

  // useEffect(()=> {
  //   const arr = []
  //   for(let i =0; i<250;i++){
  //     arr.push({
  //       userId: i + 1,
  //       title: `Asociado ${i+1}`,
  //       primasCobradas: 0,
  //       comisionesGeneradas:0,
  //       checked: false,
  //       autorizado: false
  //     })
  //   }

  //   //setDespachos(arr);

  // },[])

  // useEffect(()=>{
  //   if (despachos.length > 0 && polizas.length === 0) {
  //     const arr = []
  //     despachos.map((des) => {
  //       for(let i=0;i<5;i++){
  //         arr.push({
  //           vendedorId: des.userId,
  //           numPoliza: i + 1,
  //           primasCobradas: 100,
  //           comisionesGeneradas:100,
  //           checked: false,
  //           autorizado: false
  //         })
  //       }
  //     })
  //     //setPolizas(arr)
  //   }
  // },[despachos])

  // useEffect(()=>{
  //   if (polizas.length > 0 && recibos.length == 0) {
  //     const arr = []
  //     polizas.map((pol) => {
  //       for(let i=0;i<2;i++){
  //         arr.push({
  //           reciboId: i+1,
  //           vendedorId: pol.vendedorId,
  //           numPoliza: pol.numPoliza,
  //           primasCobradas: 100,
  //           comisionesGeneradas:45,
  //           checked: false,
  //           autorizado: false
  //         })
  //       }
  //     })
  //     //setRecibos(arr)
  //   }
  // },[polizas])

  // useEffect(()=>{
  //   if (recibos.length > 0) {
      
  //     const arr = polizas.map(pol => {
  //       const recibosPoliza = recibos.filter(re => re.vendedorId === pol.vendedorId && re.numPoliza === pol.numPoliza)
  //       let primasCobradas = 0;
  //       let comisionesGeneradas = 0;
  //       recibosPoliza.forEach(re => {
  //         primasCobradas += re.primasCobradas
  //         comisionesGeneradas += re.comisionesGeneradas
  //       })
  //       return {
  //         ...pol,
  //         primasCobradas,
  //         comisionesGeneradas
  //       }
  //     })
  
  //     //setPolizas(arr);
  //   }

  // },[recibos])


  // useEffect(()=>{
  //   if (polizas.length > 0) {
      
  //     const arr = despachos.map(des => {
  //       const polizasAgente = polizas.filter(pol => pol.vendedorId === des.userId)
  //       let primasCobradas = 0;
  //       let comisionesGeneradas = 0;
  //       polizasAgente.forEach(pol => {
  //         primasCobradas += pol.primasCobradas
  //         comisionesGeneradas += pol.comisionesGeneradas
  //       })
  //       return {
  //         ...des,
  //         primasCobradas,
  //         comisionesGeneradas
  //       }
  //     })
  
  //     //setDespachos(arr);
  //   }

  //   /*
  //   const arr = despachos.map(des => {
  //     const polizasAgente = polizas.filter(pol => pol.vendedorId === des.userId)
  //     let primasCobradas = 0;
  //     let comisionesGeneradas = 0;
  //     polizasAgente.forEach(pol => {
  //       primasCobradas += pol.primasCobradas
  //       comisionesGeneradas += pol.comisionesGeneradas
  //     })
  //     return {
  //       ...des,
  //       primasCobradas,
  //       comisionesGeneradas
  //     }
  //   })

  //   setDespachos(arr);*/
  // },[polizas])

  const [desplegable, setDesplegable] = useState([])
  const [desplegableRecibos, setDesplegableRecibos] = useState([])
  const [ok, setOk] = useState(false)
  const [index, setIndex] = useState(0)
  const [show, setShow] = useState(false)
  const [showExito, setShowExito] = useState(false)

  const [despachoSect, setDespachoSect] = useState([])
  const [pages, setPages] = useState([])
  const [lastPage, setLastPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  const hideModalExito = () => {
    setShowExito(false)
  }

  const [primasComisiones, setPrimasComisiones] = useState({
    prima: 0,
    comisiones: 0,
  })

  useEffect(() => {
    const desp =  despachos?.map(()=> false)
     setOk(desp)
 
   }, [despachos])
    
  useEffect(() => {
    if (despachos.length > 0 && polizas.length === 0) {
      const desp =  despachos?.map(()=> false)
      setDesplegable(desp)
    }

  }, [despachos])

  useEffect(() => {
    if (polizas.length > 0 && recibos.length === 0) {
      const pol =  polizas?.map(()=> false)
      setDesplegableRecibos(pol)
    }

  }, [polizas])

  useEffect(()=>{
    console.log(recibos)
    console.log(polizas)
    //console.log(desplegableRecibos)
  },[desplegableRecibos])

  const showRecibos = (i) => {
    console.log('click', i)
    const aux = [...desplegableRecibos]
    aux[i] = !aux[i]
    setDesplegableRecibos(aux)
  }
  
  const handleClick =(idx, idVendor)=>{
    let desp = [...desplegable]
    desp = desp.map(d => false)
    desp[idx] = !desp[idx]
    setDesplegable(desp)
    
  }

  const ShowModal = (idx) =>{
      setShow(!show)
      setIndex(idx)
  }

  useEffect(() => {
    let prima = 0
    let comisiones = 0
    despachos?.forEach(el => {
      prima += el.primasCobradas
      comisiones += el.comisionesGeneradas

      console.log("CALCULO", prima, comisiones);
    })
    let dollarUSLocale = Intl.NumberFormat('en-US');
    // prima = prima.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // comisiones = comisiones.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // forma
    setPrimasComisiones({prima: dollarUSLocale.format(prima.toString()), comisiones: dollarUSLocale.format(comisiones.toString())})
  }, [despachos])

  const calculaSumatoria = () => {
    let prima = 0
    let comisiones = 0
    despachos?.forEach(el => {
      prima += el.primasCobradas
      comisiones += el.comisionesGeneradas
    })
    let dollarUSLocale = Intl.NumberFormat('en-US');
    // prima = prima.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // comisiones = comisiones.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // forma
    setPrimasComisiones({prima: dollarUSLocale.format(prima.toString()), comisiones: dollarUSLocale.format(comisiones.toString())})
  }

  useEffect(() => {
    if (totalItems > 1) {
      let pag = Math.floor(totalItems / 25)
      if (totalItems % 25 > 0) pag += 1
      let res = []
      let arrPag = []
      for (let i = 0; i < pag; i++) {
        let count = [];
        let curr = i*25
        for (let j = curr; j < (i+1)*25 ; j++) {
          if (despachos[j]) count.push(despachos[j])
        }
        res.push(count)
        arrPag.push(i)
      }
      setDespachoSect(res)
      setPages(arrPag)
      setLastPage(arrPag[arrPag.length - 1])
    } else {
      setDespachoSect([despachos])
      setPages([1])
      setLastPage(1)
    }

    console.log(lastPage);
    console.log("PAGINAS", pages);
    
  }, [despachos])

  const [handleSelectAll, setHandleSelectAll] = useState(false)

  useEffect(()=>{
 
    if (despachos.length > 0) {
      if (handleSelectAll) {
        setRecibos(recibos.map(re => {
          return {
            ...re,
            checked:true
          }
        }))
        setPolizas(polizas.map(pol => {
          return {
            ...pol,
            checked:true
          }
        }))
        setDespachos(despachos.map(des => {
          return {
            ...des,
            checked:true
          }
        }))

        return
      }

      setRecibos(recibos.map(re => {
        return {
          ...re,
          checked:false
        }
      }))

      setPolizas(polizas.map(pol => {
        return {
          ...pol,
          checked:false
        }
      }))
  
      setDespachos(despachos.map(des => {
        return {
          ...des,
          checked:false
        }
      }))
    }

  },[handleSelectAll])

  const [modalAutorizarTodo, setModalAutorizarTodo] = useState(false)
  const [showAutorizarRecibo,setShowAutorizarRecibo] = useState(false)
  const [showAutorizarPoliza, setShowAutorizarPoliza] = useState(false)
  const [polizaId, setPolizaId] = useState(0)
  const [vendedorId, setVendedorId] = useState(0)

  const handlePressRecibo = (id) => {
    setRecibos(recibos.map(re => {
      if (re.reciboId === id) {
        return {
          ...re,
          autorizado: true
        }
      }
      return {...re}
    }))

    setShowExito(true)

    setShowAutorizarRecibo(false)
  }

  const handlePressPoliza = () => {
    setRecibos(recibos.map(re => {
      if (re.numPoliza === polizaId) {
        return {
          ...re,
          autorizado: true
        }
      }
      return {...re}
    }))

    setPolizas(polizas.map(pol => {
      if (pol.numPoliza === polizaId) {
        return {
          ...pol,
          autorizado: true
        }
      }
      return {...pol}
    }))

    setShowExito(true)

    setShowAutorizarPoliza(false)
  }

  const handlePressVendedor = () => {
      setRecibos(recibos.map(re => {
        if (re.vendedorId === vendedorId) {
          return {
            ...re,
            autorizado: true
          }
        }
        return {...re}
      }))
  
      setPolizas(polizas.map(pol => {
        if (pol.vendedorId === vendedorId) {
          return {
            ...pol,
            autorizado: true
          }
        }
        return {...pol}
      }))

      setDespachos(despachos.map(des => {
        if(des.userId === vendedorId){
          return {
            ...des,
          autorizado: true
          }
        }
        return{...des}
      }))
  
      setShowExito(true)

      setShow(false)
    }

  const handlePressTodo = () => {
    setTodoAutorizado(true)
    setRecibos(recibos.map(re => {
      if (re.checked) {
        return {
          ...re,
          autorizado: true
        }
      }
      return {...re}
    }))
    setPolizas(polizas.map(pol => {
      if (pol.checked) {
        return {
          ...pol,
          autorizado:true
        }
      }
      return {...pol}
    }))
    setDespachos(despachos.map(des => {
      if(des.checked){
        return {
          ...des,
        autorizado: true
        }
      }
      return{...des}
    }))

    setShowExito(true)
    setModalAutorizarTodo(false)

  }

  const autorizarTodo = () => {
    if (handleSelectAll) {
      handleModalData();
      setModalAutorizarTodo(true)
    }
  }

  const moneyFormat = (input) => {
    if(typeof input === 'string'){
      input = input.replace(',', '')
    }
    const result = (+input).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
    return result
  } 
  //#endregion
  return (
    
    <div id='tabla-asociado'>

        <GridContainer>
        <Title>Folio</Title>
        <Title>Asociado</Title>
        <Title>Primas cobradas</Title>
        <Title>Comisiones generadas</Title>
        <Seleccionar className='select-guion'>
            <Title>Seleccionar todo</Title>
            { todoAutorizado ? <Text></Text> : <Check type='checkbox' onClick={()=> {
              setHandleSelectAll(!handleSelectAll)
            }}/>}
        </Seleccionar>
        <div className='select-guion'>
        {todoAutorizado ? <Autorizado>Autorizado</Autorizado> :<Ok className={!handleSelectAll ? 'btn-ok' : ''} onClick={()=>{
            autorizarTodo()
          }}><OkText>OK</OkText></Ok>}
        </div>
       
        {despachoSect[currentPage]?.map((item, idx) => (
            <>
          
            <Text className={`${desplegable[idx] ? 'asociado-selected' : ''} ${idx%2 === 0 ? 'par' : 'row-nopar'}`} onClick={()=>handleClick(idx, item.userId)}>{item.userId}</Text>
            <Text className={`${desplegable[idx] ? 'asociado-selected' : ''} ${idx%2 === 0 ? 'par' : 'row-nopar'}`} onClick={()=>handleClick(idx, item.userId)} >{item.title}</Text>
            <Text className={`${desplegable[idx] ? 'asociado-selected' : ''} ${idx%2 === 0 ? 'par' : 'row-nopar'}`} onClick={()=>handleClick(idx, item.userId)}>{moneyFormat(item.primasCobradas)} </Text>
            <Text className={`${desplegable[idx] ? 'asociado-selected' : ''} ${idx%2 === 0 ? 'par' : 'row-nopar'}`} onClick={()=>handleClick(idx, item.userId)}>{moneyFormat(item.comisionesGeneradas)}</Text>
            <Seleccionar className={idx%2 === 0 ? 'par' : 'row-nopar'}>
            { item.autorizado ? <Text></Text> : <Check type='checkbox' onChange={
              ()=> {
                setRecibos(recibos.map(re => {
                  if (re.vendedorId === item.userId) {
                    return {
                      ...re,
                      checked: !item.checked
                    }
                  }
                  return {...re}
                }))

                setPolizas(polizas.map(pol => {
                  if(pol.vendedorId === item.userId){
                    return {
                      ...pol,
                      checked: !item.checked
                    }
                  }
                  return {...pol}
                }))
                setDespachos(despachos.map(des => {
                  if (des.userId === item.userId) {
                    return {
                      ...des,
                      checked: !item.checked
                    }
                  }
                  return {...des}
                }))
              }
            } checked={item.checked} /> }
            </Seleccionar>
            <div className={`select-guion ${idx%2 === 0 ? 'par' : 'row-nopar'}`}>
            {item.autorizado ? <Autorizado>Autorizado</Autorizado> :<Ok className={item.checked ? '' : 'btn-ok'} onClick={()=>{
              if(item.checked)
              ShowModal(item.userId)
              setVendedorId(item.userId)
              handleModalData(item.userId, 'addVendedor');
              }}><OkText>OK</OkText></Ok> }
            </div>

            {/* Desplegable */}
            {desplegable[idx] ? 
              (polizas.filter(pol => pol.vendedorId === item.userId))
              .map((pol, i) => (
                <>
                  <Text onClick={()=> showRecibos(i)} className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}>Póliza</Text>
                  <Text onClick={()=> showRecibos(i)} className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}># {pol.numPoliza}</Text>
                  <Text onClick={()=> showRecibos(i)} className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}>{moneyFormat(pol.primasCobradas)}</Text>
                  <Text onClick={()=> showRecibos(i)} className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}>{moneyFormat(pol.comisionesGeneradas)}</Text>
                  <Seleccionar className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}>
                  { pol.autorizado ? <Text></Text> : <Check type='checkbox' onChange={
                    (e)=>{
                      setRecibos(recibos.map(re => {
                        if (re.vendedorId === pol.vendedorId && re.numPoliza === pol.numPoliza) {
                          return {
                            ...re,
                            checked: !pol.checked
                          }
                        }
                        return {...re}
                      }))
                      
                      setPolizas(polizas.map(el => {
                        if(el.numPoliza === pol.numPoliza && el.vendedorId === pol.vendedorId){
                          return {
                            ...el,
                            checked: !pol.checked
                          }
                        }
                        return {...el}
                      }))
                    }
                  } checked={pol.checked} /> }
                  </Seleccionar>
                  <div className={`select-guion ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}>
                    {pol.autorizado ? <Autorizado>Autorizado</Autorizado> :<Ok className={pol.checked ? '' : 'btn-ok'} onClick={()=>{
                      if(!pol.checked) return
                      setShowAutorizarPoliza(true)
                      setPolizaId(pol.numPoliza);
                      handleModalData(pol.numPoliza, 'addPoliza');
                    }}><OkText>OK</OkText></Ok> }
                  </div>

                  {desplegableRecibos[i] && recibos.filter(re => re.vendedorId === pol.vendedorId && re.numPoliza === pol.numPoliza).map((recibo, j) => (
                    <>
                      <Text className={`recibo ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}>Recibo</Text>
                      <Text className={`recibo ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}># {recibo.reciboId}</Text>
                      <Text className={`recibo ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}>{moneyFormat(recibo.primasCobradas)}</Text>
                      <Text className={`recibo ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}>{moneyFormat(recibo.comisionesGeneradas)}</Text>
                      
                      <Seleccionar className={(i+idx)%2 === 0 ? 'row-nopar' : 'par'}>
                      { recibo.autorizado ? <Text></Text> : <Check type='checkbox' checked={recibo.checked} onChange={(e) => {
                        setRecibos(recibos.map(re => {
                          if (re.reciboId === recibo.reciboId && re.vendedorId === recibo.vendedorId && re.numPoliza === recibo.numPoliza) {
                            return {
                              ...re,
                              checked: !re.checked
                            }
                          }
                          return {...re}
                        }))
                      }} /> }
                      </Seleccionar>

                      <div className={`select-guion ${(i+idx)%2 === 0 ? 'row-nopar' : 'par'}`}>
                        {recibo.autorizado ? <Autorizado>Autorizado</Autorizado> :<Ok className={recibo.checked ? '' : 'btn-ok'} 
                          onClick={() => {
                          if(!recibo.checked) return
                          setShowAutorizarRecibo(true)
                          handleModalData(recibo.reciboId, 'addRecibo');
                        }}><OkText>OK</OkText></Ok> }
                      </div>
                    </>
                  ))}
                </>
              ))

            : null}

            </>
        )) }

        <Total ></Total>
        <Total >Total</Total>
        <Total>{moneyFormat(primasComisiones.prima)}</Total>
        <Total>{moneyFormat(primasComisiones.comisiones)}</Total>

   
        
    </GridContainer>

    {showAutorizarRecibo ? <AceptaAutorizacion
     handlePress = {handlePressRecibo}
     data={modalData}
     ShowModal={setShowAutorizarRecibo}
     /> : null} 

    {showAutorizarPoliza ? <AceptaAutorizacion
      handlePress = {handlePressPoliza}
      data={modalData}
      ShowModal={setShowAutorizarPoliza}
      /> : null} 

     {show ? <AceptaAutorizacion
     handlePress = {handlePressVendedor}
     data={modalData}
     ShowModal={setShow}
     /> : null} 

    {modalAutorizarTodo ? <AceptaAutorizacion
        handlePress = {handlePressTodo}
        data={modalData}
        ShowModal={setModalAutorizarTodo}
    /> : null}  

     {
      showExito && <Aceptado hide={hideModalExito}/>
     }

<Pagination style={{margin:'35px 0 20px 0'}}>
          <p>Página {currentPage + 1} de {pages.length}</p>
</Pagination>
<Pagination style={{margin:'0 0 35px 0'}}>
          
          {/*pages?.map(el => (
            <Paginationp curr={currentPage === el} onClick={() => setCurrentPage(el)}>{el+1}</Paginationp>
          ))*/}

          <button style={{background: 'none', border:'none', cursor:'pointer'}} onClick={()=>{
            if(currentPage === 0) return
            setCurrentPage(currentPage - 1)
            getByPage((currentPage+1)-1)
          }}><img style={{    transform: 'rotateY(190deg)'}} src={flecha} alt='flecha'/></button>

          { currentPage !== 0 &&
            <>
              <Paginationp curr={currentPage === 0} onClick={() => {
                setCurrentPage(0)
                getByPage(1)
                }}>{1}</Paginationp> 
              <span>...</span>
            </>
          }
          { currentPage - 1 > 0 &&
            pages?.map(p => {
              if (p > currentPage - 3 && p < currentPage && p !== 0) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => {
                      if(currentPage < lastPage){
                        setCurrentPage(p)
                        getByPage(p+1)
                      }
                      }}>{p + 1}</Paginationp> 
                  </>
                )
              }
            })
          }

          {currentPage === lastPage && 
            <Paginationp curr={currentPage === currentPage} onClick={() => false}>{currentPage}</Paginationp>
          }

          {currentPage < lastPage && 
            <Paginationp curr={currentPage === currentPage} onClick={() => false}>{currentPage + 1}</Paginationp>
          }
          
          { currentPage < lastPage &&
            pages?.map(p => {
              if (p > currentPage && p < currentPage + 3 && p !== pages[pages.length - 1]) {
                return (
                  <>
                    <Paginationp curr={false} onClick={() => {
                      if(currentPage < lastPage){
                        setCurrentPage(p)
                        getByPage(p+1)
                      }
                    }}>{p + 1}</Paginationp> 
                  </>
                )
              }
            })
          }

          { currentPage !== pages[pages.length - 1] &&
            <>
              <span>...</span>
              <Paginationp curr={currentPage === pages[pages.length - 1]} onClick={() => {
                if(currentPage == lastPage){
                  return false;
                }
                else{
                  setCurrentPage((pages[pages.length - 1]) - 1)
                  getByPage(lastPage)
                }
              }}>{pages.length}</Paginationp>
            </>
          }
          <button style={{background: 'none', border:'none', cursor:'pointer'}} onClick={()=>{
            if(currentPage < (lastPage-1)){
              setCurrentPage(currentPage + 1)
              getByPage((currentPage+1)+1)
            }
          }}><img src={flecha} alt='flecha'/></button>

</Pagination>
    </div>
  )
}

export default TablaAsociadoSelect