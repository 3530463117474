import React, { useEffect,useState,useContext } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import './styles.scss'

import IndicadoresContext from "../../context/indicadores.context";
import BotonCambio from '../graficasDinamicas/boton-cambio';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

const GraficasEstadisticas = ({datavs, graphData}) => {
    const { state:filtros } = useContext(IndicadoresContext);
    const { periodoStart,  periodoEnd, consultar} = filtros

    const [labels, setLabels] = useState([]);

    const obtenerDiasEntreDosFechas = (d1, d2) => {
        const date1 = d1.getTime()
        const date2 = d2.getTime()

        const dates = []

        let i = date1
        while (i <= date2) {
            dates.push(new Date(i).toLocaleDateString('es-MX'))
            const aux = new Date(i)
            aux.setDate(aux.getDate() + 1)
            i = aux.getTime()
        }
        return dates
    }

    const obtenerMesesEntreDosFechas = (d1,d2) => {
        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto','Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        
        let m1 = d1.getMonth()
        let m2 = d2.getMonth()
        let y1 = d1.getFullYear()
        let y2 = d2.getFullYear()
      
        if (y1 !== y2) {
          if (y1 < y2) {
            m2 += (y2 - y1) * 12
          } else {
            m1 += (y1 - y2) * 12
          }
        }
      
        const mesesResult = []
        for(let i = m1; i <= m2; i++){
          mesesResult.push(`${meses[i % 12]} ${Math.floor(i / 12) + y1}`)
        }
      
        return mesesResult
    }

    const [checkDates, setCheckDates] = useState(false)

    useEffect(()=>{
        if(periodoEnd === '' && periodoStart === ''){
            setCheckDates(!checkDates)
            return
        }

        const date1 = new Date((periodoStart).split('-').join('/'))
        const date2 = new Date((periodoEnd).split('-').join('/'))

        if (date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
            const dias = obtenerDiasEntreDosFechas(date1, date2)
            setLabels(dias)
            return
        }

        const meses = obtenerMesesEntreDosFechas(date1, date2)
        setLabels(meses)

    },[consultar, checkDates])

    const [options, setOption] = useState({
        responsive: true,
        maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        precision: 0,
                        callback: function(value, index, values) {
                            return `${value}`;
                        }
                    }
                }
            },

            plugins: {
                legend: {
                position: 'top',
                },
                title: {
                display: true,
                //text: 'Cotizaciones vs pólizas emitidas',
                },
                
            },
        })

    const [options2, setOption2] = useState({
        responsive: true,
        maintainAspectRatio: false,
            scales: {
                y: {
                    ticks: {
                        precision: 0,
                        callback: function(value, index, values) {
                            return `${value}`;
                        }
                    }
                }
            },

            plugins: {
                legend: {
                position: 'top',
                },
                title: {
                display: true,
                //text: 'Cotizaciones vs pólizas emitidas',
                },
                
            },
        })

    useEffect(()=>{
        if (labels?.length > 4) {
            setOption({
              ...options,
              scales: {
                ...options.scales,
                x: {
                  min: 0,
                  max: 3
                }
              },
              plugins: {
                ...options.plugins,
                title: {
                  ...options.plugins.title,
                  // text: title
                }
              }
            })
          } else {
            setOption({
              ...options,
              plugins: {
                ...options.plugins,
                title: {
                  ...options.plugins.title,
                  // text: title
                }
              }
            })
          }
    },[])


    useEffect(()=>{
        if (labels?.length > 4) {
            setOption2({
              ...options2,
              scales: {
                ...options2.scales,
                x: {
                  min: 0,
                  max: 3
                }
              },
              plugins: {
                ...options2.plugins,
                title: {
                  ...options2.plugins.title,
                  // text: title
                }
              }
            })
          } else {
            setOption2({
              ...options2,
              plugins: {
                ...options2.plugins,
                title: {
                  ...options2.plugins.title,
                  // text: title
                }
              }
            })
          }
    },[])


    const format = (data) => {

      const date1 = new Date((periodoStart).split('-').join('/'))
      const date2 = new Date((periodoEnd).split('-').join('/'))

      if (date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()) {
        //dias
        const aux = data.map((v, i) => {
          return {
            dia: i + 1,
            value: v
          }
        })

        const days = aux.filter((d) => (d.dia >= date1.getDate()) && (d.dia <= date2.getDate()))
        return days.map((d) => d.value)
      }

      //meses
      let m1 = date1.getMonth()
      let m2 = date2.getMonth()
      let y1 = date1.getFullYear()
      let y2 = date2.getFullYear()

      if (y1 !== y2) {
        if (y1 < y2) {
          m2 += (y2 - y1) * 12
        } else {
          m1 += (y1 - y2) * 12
        }
      }

      
      const months = data.filter((v, i) => (i >= m1) && (i <= m2))

      return months
    }


    const data = {
        labels,
        datasets: [
            {
            label: 'Cotizaciones',
            data: format(graphData.cotizaciones),
            backgroundColor: ["#becee9ff" ],
            },
            {
            label: 'Pólizas emitidas',
            data: format(graphData.emitidos),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
        };
    
    const data2 = {
        labels,
        datasets: [
            {
            label: 'Pólizas emitidas',
            data: format(graphData.emitidos),
            backgroundColor: ["#becee9ff"],
            },
            {
            label: 'Pólizas cobradas',
            data: format(graphData.cobrados),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
        };

        // useEffect(()=>{
        //     {
        //         const arr1 = data.datasets[0].data
        //         const total1 = arr1.reduce((a,b) => a +b)
        //         const arr2 = data.datasets[1].data
        //         const total2 = arr2.reduce((a,b) => a +b)

        //         const porcentaje = (total2 * 100) / total1

        //         setPorcentaje1(porcentaje.toFixed(1))
        //     }

        //     {
        //         const arr1 = data2.datasets[0].data
        //         const total1 = arr1.reduce((a,b) => a +b)
        //         const arr2 = data2.datasets[1].data
        //         const total2 = arr2.reduce((a,b) => a +b)

        //         const porcentaje = (total2 * 100) / total1

        //         setPorcentaje2(porcentaje.toFixed(1))
        //     }
        // },[])

    return (
    <div className='graficas-estadisticas-wrapper'>
    <div className='bg-color-white'>
        <div className='graficas-estadisticas'>
            <div className='grafica'>
                <div className='grafica__header'>
                    <h3>Cotizaciones vs pólizas emitidas</h3>
                    <div>
                        {datavs.vs1}%
                    </div>
                </div>
                <Bar options={options} data={data} />
                {labels?.length > 4 ? (
                    <>
                        <BotonCambio left={true} options={options} setOptions={setOption} data={data} />
                        <BotonCambio right={true} options={options} setOptions={setOption} data={data} />
                    </>
                ) : null}
            </div>
            <div className='grafica'>
                <div className='grafica__header'>
                    <h3>pólizas emitidas vs pólizas cobradas</h3>
                    <div>
                    {datavs.vs2}%
                    </div>
                </div>
                <Bar options={options2} data={data2} />
                {labels?.length > 4 ? (
                    <>
                        <BotonCambio left={true} options={options2} setOptions={setOption2} data={data2} />
                        <BotonCambio right={true} options={options2} setOptions={setOption2} data={data2} />
                    </>
                ) : null}
                
            </div>
        </div>
    </div>
    </div>
    )
}

export default GraficasEstadisticas