import React from 'react';
import './style.scss'
const index = ({informacionAgente}) => {
  return (
    <div className='informacion-agente'>
      {
        informacionAgente.map((item) => {
          return(
          <>
            <div className='left-container'>
              <h4 className='left-container__title'>
                {item.titulo}
              </h4>
              <h4 className='left-container__name'>
                { item.nombre } { item.primerApellido }
              </h4>
            </div>
            <div className='right-container'>
              <div className='line line--bold'>
                { item.nombreCompleto }
              </div>
              <div className='line'>
                Tel&eacute;fono: { item.telefono }
              </div>
              <div className='line'>
                Fecha de nacimiento: { item.fechaDeNacimiento }
              </div>
            </div>
          </>
          )
        })
      }
    </div>
  );
};

export default index;