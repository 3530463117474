import React from 'react'
//styles
import { MainContainer, Container, Text, Line, Aplicar } from '../styles/componets/SelectIndicadoresStyled'
const SelectIndicadores = ({indicador, onChange, onClick}) => {
  return (
    <MainContainer>
        <Container>
            <Text>Ramo/Subramo</Text>
            <input checked={indicador["Ramo/Subramo"]}  name="Ramo/Subramo" type='checkbox' value={indicador}  onChange={(e) => onChange(e)}/>
        </Container>
        <Line></Line>
        <Container>
            <Text>Forma de Pago</Text>
            <input checked={indicador["Forma de pago"]}  name="Forma de pago" type='checkbox' value={indicador}  onChange={(e) => onChange(e)}/>
        </Container>
        <Line></Line>

        <Container>
            <Text>Periodo de Pago</Text>
            <input checked={indicador["Periodo de pago"]} name="Periodo de pago" type='checkbox' value={indicador}  onChange={(e) => onChange(e)}/>
        </Container>
        <Line></Line>

        <Container>
            <Text>Aseguradora</Text>
            <input checked={indicador["Aseguradora"]} name="Aseguradora" type='checkbox' value={indicador}  onChange={(e) => onChange(e)}/>
        </Container>
        <Line></Line>

       


        <Aplicar onClick={onClick}  >Aplicar</Aplicar>
    </MainContainer>
  )
}

export default SelectIndicadores