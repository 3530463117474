import styled from "styled-components";

export const MainContainer = styled.div`
    width:15%;
    border-radius: 16px;
    background-color:#FFFFFF;
    box-shadow:10px 10px 5px -7px rgba(0,0,0,0.68);
    display:flex;
    gap:2rem;
    justify-content:center;
    align-items:center;
    padding:1rem

`;

export const Img = styled.img`
    width:1rem;
`;

export const Texto = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
    line-height:20px;
    font-weight: 600;
`;