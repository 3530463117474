export const obtenerOficinas = async () => {
    let oficinas = [];
    const apiUrl = "https://backendsicas.gpmass.mx/api/get-oficinas/0";
    await fetch(apiUrl, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        oficinas = data.data;
      })
      .catch((err) => console.error("ERROR: ", err.message));
    return oficinas;
  };