import React, { createContext, useReducer } from "react";

const IndicadoresContext = createContext();

const initialState = {
  periodoStart: "",
  periodoEnd: "",
  indicadores:{},
  indAdmin: false,
  consultar: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'PERIODO-START':
      return {
        ...state,
        periodoStart: action.payload
      }
      case 'PERIODO-END':
      return {
        ...state,
        periodoEnd: action.payload
      }
    case 'INDICADORES-GRAFICAS':
        return {
          ...state,
          indicadores: action.payload

        }
    case 'SIDE-ADMIN':
      return {
        ...state,
        indAdmin: true
      }
    case 'SIDE-ADMIN-FALSE':
      return {
        ...state,
        indAdmin: false
      }

    case 'RESET-INDICADORES':
      return{
        ...state,
        indicadores:{}
      }
    case 'CONSULTAR':
        return{
          ...state,
          consultar:!state.consultar
        }
    default:
      return state;
  }

  
};

const IndicadoresContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const data = { state, dispatch };

  return (
    <IndicadoresContext.Provider value={data}>
      {children}
    </IndicadoresContext.Provider>
  );
};

export default IndicadoresContext;
export { IndicadoresContextProvider };
