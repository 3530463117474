import styled from "styled-components";

export const MainContainer = styled.div`
    width:80%;
    height:calc(100vh - 5rem);
    margin: 0 auto;
    background-color: #FFFFFF;
    //padding:1rem
`;

export const ContainerFecha = styled.div`
    display:flex;
    justify-content:flex-end
`;

export const ContainerNav = styled.div`
    margin: 2rem 0;
`;

export const ContainerIndicadores = styled.div `
    background-color:#F8F8F8 ;
    padding:1rem;
    display: flex;
    .chart-layout {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
`;