import './style.scss'
const DetalleEstadoCotizacion = ({response}) => {
  console.log('entro a detalle cotizacion');
  return (
    <>
      <div className='table-details'>
              <div key={response.id}>
                <div className='table-details__header'>
                  <div className='row'>
                    <div className='row__column row__column--header-left row__column--bold'>
                      { response.nombre }
                    </div>
                    <div className='row__column row__column--header-right row__column--bold'>
                      #{ response.folio }
                    </div>
                  </div>
                </div>

                {
                  /********** Detalles personales ***********/
                  response.detallesPersonales.map((subresponse, index) => {
                    return(
                      <div key={index}>
                        <div className='table-details__title'>
                          Detalles personales
                        </div>
                        <div className='table-details__content'>
                          <div className='row'>
                            <div className='row__column row__column--three-column row__column--bold'>
                              Nombre
                            </div>
                            <div className='row__column row__column--three-column row__column--bold'>
                            Teléfono
                            </div>
                            <div className='row__column row__column--three-column row__column--bold'>
                              Fecha de nacimiento
                            </div>
                          </div>

                          <div className='row'>
                            <div className='row__column row__column--three-column'>
                              { subresponse.nombre }
                            </div>
                            <div className='row__column row__column--three-column'>
                              { subresponse.telefono }
                            </div>
                            <div className='row__column row__column--three-column'>
                              { subresponse.fechaDeNacimiento }
                            </div>
                          </div>

                          <div className='row'>
                            <div className='row__column row__column--three-column row__column--bold'>
                              CP
                            </div>
                            <div className='row__column row__column--three-column row__column--bold'>
                            Género
                            </div>
                            <div className='row__column row__column--three-column' />
                          </div>

                          <div className='row'>
                            <div className='row__column row__column--three-column'>
                              { subresponse.cp }
                            </div>
                            <div className='row__column row__column--three-column'>
                              { subresponse.genero }
                            </div>
                            <div className='row__column row__column--three-column' />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

                {
                  /********** Detalles del auto ***********/
                  response.detallesDelAuto.map((subresponse, index) => {
                    return(
                      <div key={index}>
                      <div className='table-details__title'>
                        Detalles del auto
                      </div>
                      <div className='table-details__content'>
                        <div className='row'>
                          <div className='row__column row__column--four-column row__column--bold'>
                            Tipo
                          </div>
                          <div className='row__column row__column--four-column row__column--bold'>
                            Marca
                          </div>
                          <div className='row__column row__column--four-column row__column--bold'>
                            Sub Marca
                          </div>
                          <div className='row__column row__column--four-column row__column--bold'>
                            Modelo
                          </div>
                        </div>

                        <div className='row'>
                          <div className='row__column row__column--four-column'>
                            {subresponse.tipo}
                          </div>
                          <div className='row__column row__column--four-column'>
                            {subresponse.marca}
                          </div>
                          <div className='row__column row__column--four-column'>
                            {subresponse.subMarca}
                          </div>
                          <div className='row__column row__column--four-column'>
                            {subresponse.modelo}
                          </div>
                        </div>

                        <div className='row'>
                          <div className='row__column row__column--one-column row__column--bold'>
                            Descripción
                          </div>
                        </div>
                        <div className='row'>
                          <div className='row__column row__column--one-column'>
                            {subresponse.descripcion}
                          </div>
                        </div>

                      </div>
                      </div>
                    )
                  })
                }

                {
                  /********** Detalles de cotizacion ***********/
                  response.detallesDeCotizacion.map((subresponse, index) => {
                    return (
                      <div key={index} s>
                        <div className='table-details__title'>
                          <h3>Detalles de cotización</h3> 
                          <a target="_blank" href={'' + subresponse.url_cotizacion}>Ver cotización en línea</a>
                        </div>
              
                        {/* <div className='table-details__content'>
                          <div className='row'>
                            <div className='row__column row__column--four-column row__column--bold'>
                              Prima Neta
                            </div>
                            <div className='row__column row__column--four-column row__column--bold'>
                              Recargo
                            </div>
                            <div className='row__column row__column--four-column row__column--bold'>
                              Expedición
                            </div>
                            <div className='row__column row__column--four-column row__column--bold'>
                              IVA
                            </div>
                          </div>

                          <div className='row'>
                            <div className='row__column row__column--four-column'>
                              {subresponse.primaNeta}
                            </div>
                            <div className='row__column row__column--four-column'>
                              {subresponse.recargo}
                            </div>
                            <div className='row__column row__column--four-column'>
                              {subresponse.expedicion}
                            </div>
                            <div className='row__column row__column--four-column'>
                              {subresponse.iva}
                            </div>
                          </div>

                          <div className='row'>
                            <div className='row__column row__column--two-column row__column--bold'>
                              Aseguradora
                            </div>
                            <div className='row__column row__column--two-column row__column--bold'>
                              Fecha de emisión
                            </div>
                          </div>
                          <div className='row'>
                            <div className='row__column row__column--two-column'>
                              {subresponse.aseguradora}
                            </div>
                            <div className='row__column row__column--two-column'>
                              {subresponse.fechaDeEmision}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    )
                  })
                }

                

                {
                  /********** Estado ***********/
                  <div className='table-details__status'>
                    Estado:  { response.estado }
                  </div>
                }
              </div>
      </div>
    </>
  );
};

export default DetalleEstadoCotizacion;