import React, { useState,useEffect } from 'react'
import lupa from '../../../assets/lupa.svg'
import Select from 'react-select'

import './styles.scss'
const Buscador = ({data, setFiltered,by, setIsFiltered}) => {

  const [options, setOptions] = useState([])

  useEffect(()=>{
    let container = [] 
    for (let i=0; i<data.length; i++){
      container.push({label:data[i][by],value:data[i]['id']})
    }
    setOptions(container)
    //console.log("🚀 ~ file: index.js ~ line 16 ~ useEffect ~ container", data)
  },[data] )

  const selectDespacho=(event) => {
    setIsFiltered(true)
    const res=data.find((e)=>e.id==event.value)
    setFiltered([res])
    return {
  }
  }

  return (
    <div className='buscador' style={{paddingTop: "10px",paddingBottom: "95px"}}>
      <Select options={options} onChange={selectDespacho} />
        {/* <input  type="search" placeholder="Buscar ..."/> */}
        <i className="fa fa-search" style={{paddingTop:"23px"}}></i>
    </div>
  )
}

export default Buscador