import React, { useState,useEffect,useContext } from 'react'
//assets
import flecha from '../../../assets/flechaAbajo.svg'

import flechaArriba from '../../../assets/ModalOrdenar/flechaArriba.svg'
import flechaAbajo from '../../../assets/ModalOrdenar/flechaAbajo.svg'

//styles
import {MainContainer, Img, Texto} from '../../../styles/components/OrdenarporStyled'
import ModalOrdenar from './Consecutivo'

import AdminVentasFiltrosContext from '../../../context/adminVentas.filtros.context'

const Ordenarpor = () => {
  const [showModalOrdenar, setShowModalOrdenar] = useState(false)

  const { state:filtrosContext } = useContext(AdminVentasFiltrosContext)

  const { ascendente, descendente } = filtrosContext

  const handleShowModalOrdenar= () => {
    setShowModalOrdenar(!showModalOrdenar)
  }

  const handleHideModalOrdenar= () => {
    setShowModalOrdenar(false)
  } 

  useEffect(()=>{
    
    document.addEventListener('click', (e) => {

      let el = e.target

      for(let i = 0; i<100;i++){
        if(el.parentElement.classList.contains('modal-consecutivo')){
          i = 100+1
        }

        console.log(i)
        if(el.id === 'root'){
          i = 100+1
          handleHideModalOrdenar()
        }

        el = el.parentElement
        
      }

    })
    
  },[])


  return (
    <div className='modal-consecutivo'>
      <MainContainer style={{width: '100%', cursor:'pointer'}} onClick={handleShowModalOrdenar}>
          <Texto>
            {
            ascendente &&
              <>
                <span style={{marginRight:'15px', fontFamily: `'Montserrat', sans-serif`, fontWeight:'600', color:'#4E4E4E'}}>Ascendente</span>
                <img src={flechaArriba}/>
              </>
            }
            {
            descendente &&
              <>
                <span style={{marginRight:'15px', fontFamily: `'Montserrat', sans-serif`, fontWeight:'600', color:'#4E4E4E'}}>Descendente</span>
                <img src={flechaAbajo}/>
              </>
            }
          </Texto>
          <Img src={flecha}/>
      </MainContainer>
      <div style={{position: 'relative', height:'10px'}}>
          {showModalOrdenar && <ModalOrdenar  hideModal={handleHideModalOrdenar}/>}
      </div>
    </div>
  )
}

export default Ordenarpor