import styled from "styled-components";

export const ModalContainer = styled.div`
display: flex;
flex-direction: column;
    background-color:rgba(0, 0, 0, 0.4);
    justify-content:center;
    align-items: center;
    position: absolute;
    left:0;
    top:0;
    width:100vw;
    height: 100vh;
    position:fixed;
    z-index:1;
`;

export const MainContainer = styled.dialog`
       width: 26rem;
        padding:2rem;
        justify-content:center;
        align-items:center;
        margin: 0 auto;
        border: 0;   
`;

export const Title = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size:1.5rem;
    line-height:32px;
    text-align: center;
    color:#121212
`;

export const Success = styled.img`
    width:14.5rem;

`;



export const X = styled.div`
    display: flex;
    justify-content: flex-end;
`;
