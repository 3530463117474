import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    background-color:#FFFFFF;
    padding:1rem;
`;

export const Text1 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height:1.8rem;
    margin-left:1rem;
   color: #8F8F8F
`;

export const TextSub = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height:1.8rem;
    margin-left:1rem;
   color: #8F8F8F
`;



export const Text2 = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 400;
    line-height:2rem;
    color:#8F8F8F;
    margin-right: 1rem;
`;

export const Nombre = styled.p`
font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    line-height:2rem;
    color:#8F8F8F;
    margin-right: 1rem;
`;


