import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ModalLoadingS } from "../../styles/modals/modalLoading.styles";

const ModalLoading = () => {
  const [spreads, setSpreads] = useState("")
  useEffect(() => {
    setTimeout(() => {
      if (spreads.length === 3) setSpreads("")
      if (spreads.length < 3) setSpreads(prev => prev + ".")
    }, 500);
  }, [spreads])
  return(
    <ModalLoadingS>
      <div className="modal-loading-container">
        <h2>Espere un momento, por favor</h2>
        <h3>Estamos trabajando en ello{spreads}</h3>
      </div>
    </ModalLoadingS>
  )
};
export default ModalLoading;